import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AddData, GetData } from "../../api/GeneralFile";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

import { v4 as uuidv4 } from "uuid";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import { useTranslation } from "react-i18next";

const AddNewSkitiiUser = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [typeOfBusiness, setTypeOfBusiness] = useState("");
  const [password, setPassword] = useState("");
  const addDetails = () => {
    if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}/.test(password)) {
      toast.error(
        "Password must contain at least one digit, one lowercase letter, one uppercase letter, and be at least 8 characters long.",
      );
      return;
    }
    const mobileNumberRegex = /^[1-9]\d{9}$/;
    const isValid = mobileNumberRegex.test(phone);
    if (!isValid) {
      toast("Please enter a valid 10-digit mobile number.");
      return;
    }
    setIsLoading(true);
    AddData(`/skitiiai/save`, {
      name,
      email,
      phone,
      typeOfBusiness,
      CompanyName: companyName,
      password,
    })
      .then((response) => {
        if (!response.data.success) {
          toast(response.data.message);
          setIsLoading(false);
          return;
        }
        toast(response.data.message);
        props.setDisplayAddNew(false);
        props?.getAllUsers();
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err, "error");
        toast(err.data?.message);
        setIsLoading(false);
      });
  };

  return (
    <div>
      <Typography variant="h4">Add Skitii User</Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: 550,
          gap: 10,
          marginTop: 15,
        }}
      >
        <TextField
          fullWidth
          label="Name"
          type="text"
          InputProps={{ inputProps: { min: 0, max: 99999 } }}
          value={name}
          onChange={(event) => {
            setName(event.target.value);
          }}
        />
        <TextField
          fullWidth
          label="Email"
          type="email"
          InputProps={{ inputProps: { min: 0, max: 99999 } }}
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        />
        <TextField
          fullWidth
          label="Password"
          type="text"
          InputProps={{ inputProps: { min: 0, max: 99999 } }}
          value={password}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
        />
        <TextField
          fullWidth
          label="Phone"
          type="text"
          InputProps={{ inputProps: { min: 0, max: 99999 } }}
          value={phone}
          onChange={(event) => {
            setPhone(event.target.value);
          }}
        />
        <TextField
          fullWidth
          label="Company Name"
          type="text"
          InputProps={{ inputProps: { min: 0, max: 99999 } }}
          value={companyName}
          onChange={(event) => {
            setCompanyName(event.target.value);
          }}
        />
        <FormControl fullWidth>
          <InputLabel id="Type of Business-select">Type of Business</InputLabel>
          <Select
            labelId="Type of Business-select-label"
            id="Type of Business-select"
            label="Type of Business"
            value={typeOfBusiness}
            onChange={(event) => {
              setTypeOfBusiness(event.target.value);
            }}
          >
            <MenuItem value="individual">Individual</MenuItem>
            <MenuItem value="privateLimited">Private Limited</MenuItem>
            <MenuItem value="proprietor">Proprietor</MenuItem>
            <MenuItem value="llp">LLP</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
        <Button
          variant="contained"
          disabled={isLoading}
          onClick={() => addDetails()}
        >
          Save
        </Button>
        <Button
          variant="contained"
          disabled={isLoading}
          onClick={() => props.setDisplayAddNew(false)}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AddNewSkitiiUser;
