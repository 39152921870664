import React, { useEffect, useState } from "react";
import SkitiiAILogo from "../IndividualReportFiles/SkitiiAILogo.png";
const UserReportSummaryIndivisual = (props) => {
  const genderdata = {
    Male: 0,
    Female: 0,
  };

  props?.userData?.length > 0 &&
    props?.userData?.map((user) => {
      if (user.Gender === "Male") genderdata["Male"]++;
      else genderdata["Female"]++;
    });

  const getFilteredData = () => {
    // Get emotions from the first viewer's data
    const emotions = Object.keys(props?.data?.viewer?.[0]?.SummaryData[0]);
    const filteredData = {};
    const maxLength = Math.max(
      ...emotions.map((emotion) =>
        Math.max(
          ...props?.data?.viewer.map(
            (viewerData) => viewerData.SummaryData[0][emotion].length,
          ),
        ),
      ),
    );
    // Iterate through emotions to calculate averages
    emotions.forEach((emotion) => {
      // Aggregate data for all viewers for the current emotion
      const aggregatedData = Array.from({ length: maxLength }, (_, index) => {
        const total = props?.data?.viewer.reduce(
          (sum, viewerData) =>
            sum + (viewerData.SummaryData[0][emotion][index] || 0),
          0,
        );
        const count = props?.data?.viewer.filter(
          (viewerData) => viewerData.SummaryData[0][emotion][index] !== null,
        ).length;
        return count > 0 ? total / count : 0; // Calculate average
      });

      filteredData[emotion] = aggregatedData;
    });

    return filteredData;
  };

  const chartdata = Object.keys(getFilteredData()).map((emotion) => ({
    name: emotion,
    data: getFilteredData()[emotion],
  }));

  const countHighValues = (data) => {
    return data.map((emotion) => {
      const count = emotion.data.filter((value) => value > 0.5).length;
      return { name: emotion.name, count };
    });
  };
  const result = countHighValues(chartdata);
  const getFilteredGazeData = () => {
    return (
      props?.viewerdata?.attentionData[0]
        //   ?.slice(0, currentTime)
        .map((item) => ({
          name: item?.emotion,
          data: item?.gaze_direction === "Center" ? 1 : 0,
        }))
    );
  };

  // Prepare data for the "Gaze Direction" chart
  const series = [
    {
      name: "Attention",
      data: getFilteredGazeData().map((item) => item.data), // Filtered data
    },
  ];

  console.log(series, series[0]?.data);
  const zeroPercentCount = series[0]?.data.filter(
    (value) => value === 0,
  ).length;
  const FocusPercentCount = series[0]?.data.filter(
    (value) => value === 1,
  ).length;

  const [closeEmotion, setCloseEmotion] = useState([]);
  const Feeling96 = [
    { emotion: "Happy", x: 0.8, y: 0.7 },
    { emotion: "Joyful", x: 0.9, y: 0.8 },
    { emotion: "Excited", x: 0.7, y: 0.9 },
    { emotion: "Content", x: 0.6, y: 0.4 },
    { emotion: "Proud", x: 0.7, y: 0.6 },
    { emotion: "Grateful", x: 0.6, y: 0.5 },
    { emotion: "Surprise", x: 0.5, y: 0.7 },
    { emotion: "Love", x: 0.9, y: 0.6 },
    { emotion: "Amused", x: 0.7, y: 0.6 },
    { emotion: "Elated", x: 0.8, y: 0.8 },
    { emotion: "Optimistic", x: 0.7, y: 0.5 },
    { emotion: "Hopeful", x: 0.7, y: 0.4 },
    { emotion: "Cheerful", x: 0.7, y: 0.6 },
    { emotion: "Confident", x: 0.6, y: 0.5 },
    { emotion: "Affectionate", x: 0.8, y: 0.5 },
    { emotion: "Calm", x: 0.5, y: 0.2 },
    { emotion: "Peaceful", x: 0.6, y: 0.3 },
    { emotion: "Relaxed", x: 0.7, y: 0.3 },
    { emotion: "Satisfied", x: 0.6, y: 0.4 },
    { emotion: "Tender", x: 0.6, y: 0.3 },
    { emotion: "Contented", x: 0.7, y: 0.3 },
    { emotion: "Pleased", x: 0.6, y: 0.3 },
    { emotion: "Quiet", x: 0.5, y: 0.2 },
    { emotion: "Neutral", x: 0.5, y: 0.5 },
    { emotion: "Bored", x: -0.2, y: 0.3 },
    { emotion: "Anxious", x: -0.5, y: 0.7 },
    { emotion: "Sad", x: -0.7, y: 0.4 },
    { emotion: "Disappointed", x: -0.6, y: 0.5 },
    { emotion: "Irritated", x: -0.6, y: 0.6 },
    { emotion: "Lonely", x: -0.6, y: 0.5 },
    { emotion: "Tired", x: -0.2, y: 0.3 },
    { emotion: "Confused", x: -0.4, y: 0.5 },
    { emotion: "Nervous", x: -0.4, y: 0.6 },
    { emotion: "Frustrated", x: -0.6, y: 0.7 },
    { emotion: "Guilty", x: -0.7, y: 0.5 },
    { emotion: "Ashamed", x: -0.8, y: 0.5 },
    { emotion: "Hopeless", x: -0.8, y: 0.6 },
    { emotion: "Fear", x: -0.7, y: 0.8 },
    { emotion: "Horrified", x: -0.9, y: 0.9 },
    { emotion: "Disgust", x: -0.8, y: 0.7 },
    { emotion: "Embarrassed", x: -0.7, y: 0.6 },
    { emotion: "Angry", x: -0.8, y: 0.8 },
    { emotion: "Enraged", x: -0.9, y: 0.9 },
    { emotion: "Vengeful", x: -0.9, y: 0.8 },
    { emotion: "Miserable", x: -0.9, y: 0.7 },
    { emotion: "Resentful", x: -0.8, y: 0.7 },
    { emotion: "Depressed", x: -0.9, y: 0.5 },
    { emotion: "Terrified", x: -0.9, y: 0.9 },
    // { emotion: "Horrified", x: -0.9, y: 0.8 },
    { emotion: "HelpLess", x: -0.8, y: 0.5 },
    { emotion: "Worried", x: -0.6, y: 0.6 },
    { emotion: "Grief-stricken", x: -0.8, y: 0.5 },
    { emotion: "Shocked", x: -0.5, y: 0.7 },
    // { emotion: "Shocked", x: -0.6, y: 0.7 },
    { emotion: "Regretful", x: -0.6, y: 0.5 },
    { emotion: "Frightened", x: -0.7, y: 0.7 },
    { emotion: "Insecure", x: -0.5, y: 0.4 },
    //  {emotion: "Hopeless",x: -0.7,y: 0.6},
    // { emotion: "Depressed", x: -0.8, y: 0.5 },
    { emotion: "Uncertain", x: -0.4, y: 0.5 },
    // { emotion: "Jealous", x: -0.6, y: 0.6 },
    { emotion: "Uncomfortable", x: -0.5, y: 0.6 },
    { emotion: "Unhappy", x: -0.7, y: 0.6 },
    { emotion: "Agitated", x: -0.7, y: 0.8 },
    { emotion: "Displeased", x: -0.5, y: 0.5 },
    { emotion: "Vulnerable", x: -0.6, y: 0.5 },
    { emotion: "Pessimistic", x: -0.6, y: 0.4 },
    { emotion: "Horrible", x: -0.9, y: 0.8 },
    { emotion: "Bewildered", x: -0.6, y: 0.6 },
    // { emotion: "Angry", x: -0.8, y: 0.9 },
    // { emotion: "Disappointed", x: -0.6, y: 0.6 },
    { emotion: "Defeated", x: -0.7, y: 0.5 },
    { emotion: "Indifferent", x: -0.4, y: 0.4 },
    { emotion: "Hurt", x: -0.7, y: 0.5 },
    { emotion: "Afraid", x: -0.7, y: 0.6 },
    { emotion: "Apprehensive", x: -0.5, y: 0.5 },
    { emotion: "Grief", x: -0.8, y: 0.7 },
    { emotion: "Overwhelmed", x: -0.7, y: 0.8 },
    { emotion: "Tense", x: -0.5, y: 0.6 },
    { emotion: "Resentment", x: -0.7, y: 0.6 },
    // Lonely -0.6 0.5
    { emotion: "Rejected", x: -0.7, y: 0.6 },
    // Hopeless -0.9 0.7
    { emotion: "Shameful", x: -0.8, y: 0.6 },
    { emotion: "Disheartened", x: -0.7, y: 0.5 },
    { emotion: "Deflated", x: -0.6, y: 0.5 },
    { emotion: "Stress", x: -0.7, y: 0.8 },
    { emotion: "Worry", x: -0.6, y: 0.7 },
    { emotion: "Concern", x: -0.2, y: 0.5 },
    { emotion: "Anxiety", x: -0.8, y: 0.9 },
    { emotion: "Upset", x: -0.7, y: 0.7 },
    // { emotion: "Agitated", x: -0.6, y: 0.8 },
    { emotion: "Humiliated", x: -0.9, y: 0.7 },
    { emotion: "Overconfident", x: 0.5, y: 0.6 },
    // { emotion: "Depressed", x: -0.9, y: 0.3 },
    { emotion: "Bothered", x: -0.5, y: 0.6 },
    // { emotion: "Jealous", x: -0.7, y: 0.7 },
    { emotion: "Timid", x: -0.4, y: 0.4 },
    { emotion: "Chilled", x: 0.6, y: 0.2 },
    // { emotion: "Excited", x: 0.8, y: 0.9 },
  ];

  const getXValue = (emotionName) => {
    const found = Feeling96.find((feeling) => feeling.emotion === emotionName);
    return found ? found.x : null; // Return null if emotion is not found
  };
  const getYValue = (emotionName) => {
    const found = Feeling96.find((feeling) => feeling.emotion === emotionName);
    return found ? found.y : null; // Return null if emotion is not found
  };
  const calculateValenceArousal = (data) => {
    console.log(data);
    return props?.data.Tag === "Camera"
      ? data.map((entry, index) => {
          const { emotion_confidences } = entry;
          console.log(emotion_confidences);
          const valence =
            emotion_confidences &&
            Object.keys(emotion_confidences).reduce((sum, emotion) => {
              console.log(
                sum,
                emotion_confidences[emotion],
                emotion,
                Feeling96[emotion]?.x,
              );
              return (
                sum + emotion_confidences[emotion] * (getXValue(emotion) || 0)
              );
            }, 0);
          console.log(valence);
          const arousal =
            emotion_confidences &&
            Object.keys(emotion_confidences).reduce((sum, emotion) => {
              return (
                sum + emotion_confidences[emotion] * (getYValue(emotion) || 0)
              );
            }, 0);

          return {
            ...entry,
            valence: valence ? valence : 0,
            arousal: arousal ? arousal : 0,
          };
        })
      : data.slice(0, -1).map((entry, index) => {
          const { emotion_confidences } = entry;
          console.log(emotion_confidences);
          const valence =
            emotion_confidences &&
            Object.keys(emotion_confidences).reduce((sum, emotion) => {
              console.log(
                sum,
                emotion_confidences[emotion],
                emotion,
                Feeling96[emotion]?.x,
              );
              return (
                sum + emotion_confidences[emotion] * (getXValue(emotion) || 0)
              );
            }, 0);
          console.log(valence);
          const arousal =
            emotion_confidences &&
            Object.keys(emotion_confidences).reduce((sum, emotion) => {
              return (
                sum + emotion_confidences[emotion] * (getYValue(emotion) || 0)
              );
            }, 0);

          return {
            ...entry,
            valence: valence ? valence : 0,
            arousal: arousal ? arousal : 0,
          };
        });
  };

  useEffect(() => {
    const result =
      props?.viewerdata?.attentionData[0] &&
      calculateValenceArousal(props?.viewerdata?.attentionData[0]);
    const getEmotion = (V_computed, A_computed, oldEmotion, faceData) => {
      const distances = Feeling96.map(({ emotion, x, y }) => {
        const distance = Math.sqrt(
          Math.pow(V_computed - x, 2) + Math.pow(A_computed - y, 2),
        );
        return {
          emotion,
          distance,
          oldEmotion,
          faceData,
          V_computed,
          A_computed,
        };
      });

      console.log("Distances:", distances);

      // Example: Find the closest emotion
      const closestEmotion = distances.reduce((prev, curr) =>
        prev.distance < curr.distance ? prev : curr,
      );

      console.log("Closest Emotion:", closestEmotion);

      setCloseEmotion((prev) => [...prev, closestEmotion]);
      // return closestEmotion;
    };
    console.log(result);
    result?.map((item, index) =>
      // Emotion: {item?.emotion}, Valence: {item?.valence?.toFixed(3)},
      // Arousal: {item?.arousal?.toFixed(3)}
      // Close Emotion:{" "}
      getEmotion(
        item?.valence?.toFixed(3),
        item?.arousal?.toFixed(3),
        item?.emotion,
        item?.num_faces,
      ),
    );
  }, []);

  console.log(closeEmotion);
  const formattedData = closeEmotion.map((emotion, index) => {
    return {
      emotion,
      formattedEmotion:
        emotion.faceData === 0 ? `No Face` : `${emotion.emotion}`,
    };
  });
  console.log(formattedData);
  const emotionCount = formattedData.reduce((acc, entry) => {
    const emotion = entry.formattedEmotion;
    if (acc[emotion]) {
      acc[emotion]++;
    } else {
      acc[emotion] = 1;
    }
    return acc;
  }, {});
  console.log(emotionCount);
  const topThreeEmotions = Object.entries(emotionCount)
    .filter(([emotion, count]) => emotion !== "No Face")
    .sort((a, b) => b[1] - a[1])
    .slice(0, 3)
    .map(([emotion, count]) => ({ emotion, count }));

  const worryCount = emotionCount["Worry"] || 0; // Default to 0 if not found
  const nervousCount = emotionCount["Nervous"] || 0; // Default to 0 if not found

  // Step 3: Calculate the total count of all emotions
  const totalCount = Object.values(emotionCount).reduce(
    (sum, count) => sum + count,
    0,
  );
  const totalPercentage = (
    ((worryCount + nervousCount) / totalCount) *
    100
  ).toFixed(2);
  const quadrantCounts = {
    "High Power/Control": 0,
    Obstructive: 0,
    Conductive: 0,
    "Low Power/Control": 0,
  };

  closeEmotion?.length > 0 &&
    closeEmotion?.forEach(
      ({ V_computed: valence, A_computed: arousal, faceData }) => {
        if (faceData === 0) {
          return;
        }

        if (
          (parseFloat(valence) > 0 && parseFloat(arousal) > 0) ||
          parseFloat(valence) === 0.0 ||
          parseFloat(arousal) === 0.0
        )
          quadrantCounts["High Power/Control"]++;
        else if (parseFloat(valence) > 0 && parseFloat(arousal) < 0)
          quadrantCounts["Obstructive"]++;
        else if (parseFloat(valence) < 0 && parseFloat(arousal) > 0)
          quadrantCounts["Conductive"]++;
        else if (parseFloat(valence) < 0 && parseFloat(arousal) < 0)
          quadrantCounts["Low Power/Control"]++;
      },
    );

  // Prepare chart data
  const data = Object.values(quadrantCounts);
  const labels = Object.keys(quadrantCounts); // ["++", "+-", "-+", "--"]
  const total = data.reduce((acc, val) => acc + val, 0);

  // Calculate percentages
  const percentages = data.map((value) =>
    total > 0 ? (value / total) * 100 : 0,
  );
  const labelPercentage = labels.map((label, index) => ({
    label,
    percentage: percentages[index],
  }));

  // Find the object with the maximum percentage
  const maxPercentage = labelPercentage.reduce((max, current) => {
    return current.percentage > max.percentage ? current : max;
  });

  return (
    <div
      // className="flex justify-center items-center flex-col"justify-center items-center
      className="flex flex-col  px-12 py-5"
      id="certificate"
    >
      <div className="justify-between mb-16 mt-10 items-center flex text-5xl font-bold text-primary2">
        <h1></h1>
        <h1>Emotion AI</h1>
        <img src={SkitiiAILogo} className="w-72 h-40" />
      </div>
      <div className="justify-between p-5  mt-8 items-center flex text-xl  text-black">
        <div>
          {/* <span className="font-bold">Company Name: </span>
          {props?.user?.CompanyName}{" "} */}
        </div>{" "}
        <div>
          {/* <span className="font-bold">Total User: </span>
          {props?.data?.viewer?.length}{" "} */}
        </div>
      </div>
      <div className="justify-between p-5 mb-8 mt-2 items-center flex text-xl  text-black">
        <div></div>
        <div>
          {/* <span className="font-bold">Date of video: </span>
          {formateDate(props?.data?.viewer[0]?.Date)} to{" "}
          {formateDate(
            props?.data?.viewer[props?.data?.viewer?.length - 1]?.Date,
          )}{" "} */}
        </div>
      </div>
      <div className="">
        <h1 className="text-4xl  ml-24   font-bold text-black mt-10 mb-4">
          User Report Summary
        </h1>

        <div className="mb-6 mt-10 ml-24 ">
          <p className="text-3xl text-black">
            {/* <span className="font-semibold">Total Users Analyzed:</span>{" "}
            {props?.data?.viewer?.length} */}
          </p>
          <p className="text-3xl text-black">
            {/* <span className="font-semibold">Gender Distribution:</span>{" "}
            {genderdata.Female}/{genderdata.Male}(Female/Male) */}
          </p>
        </div>

        {/* <div className="mb-6">
        <h2 className="text-xl font-semibold text-black">
          {`Time Spent in Different Emotions (>0.5)(Seconds):`}
        </h2>
        <ul className="list-disc pl-5">
          {result &&
            result.map((item) => (
              <li key={item?.name} className="text-3xl text-black">
                <span className="font-semibold capitalize">{item?.name}:</span>{" "}
                {item?.count}
              </li>
            ))}
        </ul>
      </div> */}

        <div className="mb-6">
          <h2 className="mt-20 ml-24  text-3xl font-semibold text-black">
            Focus & Attention:
          </h2>
          <ul className="list-disc pl-5 ml-24 mt-10 ">
            <li className="text-3xl text-black">
              <span className="text-3xl font-semibold">
                Total Time Users Lost Focus:
              </span>{" "}
              {zeroPercentCount} seconds
            </li>
            <li className="text-3xl text-black">
              <span className="text-3xl font-semibold">
                Average Focus Percentage:
              </span>{" "}
              {((FocusPercentCount / series[0]?.data.length) * 100)?.toFixed(2)}
              %
            </li>
            <li className="text-3xl text-black">
              <span className="text-3xl font-semibold">
                Most Common Sentiments:
              </span>{" "}
              {topThreeEmotions &&
                topThreeEmotions
                  .map((emotion) => `${emotion.emotion}`)
                  .join(", ")}
            </li>
            <li className="text-3xl text-black">
              User mostly felt
              <span className="text-3xl font-semibold">
                {" "}
                {maxPercentage?.label}{" "}
              </span>{" "}
              for{" "}
              <span className="text-3xl font-semibold">
                {maxPercentage?.percentage?.toFixed(2)}%
              </span>{" "}
              of the Time.
            </li>
            <li className="text-3xl text-black">
              The users displayed Nervous and Worry emotions for{" "}
              <span className="text-3xl font-semibold">
                {worryCount + nervousCount}
              </span>{" "}
              secs and{" "}
              <span className="text-3xl font-semibold">
                {totalPercentage}%{" "}
              </span>{" "}
              of total conversataion
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default UserReportSummaryIndivisual;
