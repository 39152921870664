import React, { useEffect, useState } from "react";
import { DeleteData, GetData } from "../../api/GeneralFile";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkitiiVideoList from "./SkitiiVideoList";
import UpdateUser from "./UpdateUser";
import AddNewSkitiiUser from "./AddNewSkitiiUser";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";

const SkitiiAIUser = () => {
  const [displayAddNew, setDisplayAddNew] = useState(false);
  const [displayUpdate, setDisplayUpdate] = useState(false);
  const [displayView, setDisplayView] = useState(false);
  const [subscriptionSettingsToBeUpdated, setSubscriptionSettingsToBeUpdated] =
    useState({});
  const { t } = useTranslation();
  const [allUsers, setAllUsers] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsLimit, setRowsLimit] = useState(10);
  const [searchBy, setSearchBy] = useState("Name");
  const [totalPage, setTotalPage] = useState(0);
  const [videoList, setVideoList] = useState();
  const [videoListDisplay, setVideoListDisplay] = useState(false);

  const getAllUsers = () => {
    GetData(
      `/skitiiai/get?search=${searchInput}&pageNumber=${pageNumber}&rowsLimit=${rowsLimit}&searchBy=${searchBy}`,
    )
      .then((response) => {
        console.log(response, "response");
        setAllUsers(response?.data.results.paginatedUsers);
        setTotalPage(response.data.results.totalPage);
      })
      .catch((err) => {
        console.error(err, "errpr");
      });
  };

  useEffect(() => {
    getAllUsers();
  }, [pageNumber, rowsLimit, displayAddNew, displayUpdate]);
  const [inputPageNumber, setInputPageNumber] = useState("");

  const handlePageInputChange = (event) => {
    // const value = parseInt(event.target.value, 10);
    // if (!isNaN(value)) {
    //   setInputPageNumber(value);
    // }
    let value = parseInt(event.target.value, 10);

    // Ensure the value is a number and clamp it between 1 and totalPage
    if (!isNaN(value)) {
      value = Math.max(1, Math.min(value, totalPage)); // Clamp between 1 and totalPage
      setInputPageNumber(value);
    } else {
      setInputPageNumber(""); // Clear the input if not a valid number
    }
  };

  const handleGoButtonClick = () => {
    if (inputPageNumber === "") {
      return toast.error("Please enter a valid page number");
    }
    if (inputPageNumber >= 1 && inputPageNumber <= totalPage) {
      setPageNumber(inputPageNumber);
    } else {
      return toast.error("Please Enter a Valid PageNumber");
    }
  };

  const deleteHandler = (id) => {
    Swal.fire({
      title: "Confirm removal of this user?",
      showDenyButton: true,
      confirmButtonText: "Yes remove it",
      denyButtonText: `No don't remove it`,
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteData(`/skitiiai/delete/${id}`)
          .then((response) => {
            toast(response.data.message);
            getAllUsers();
          })
          .catch((error) => {
            console.error(error.response.data.message);
          });
      }
    });
  };

  return (
    <div style={{ marginTop: "15px" }}>
      {/* {displayAddNew && <AddNew displayAddNew={displayAddNew} setDisplayAddNew={setDisplayAddNew} />}
  {displayUpdate && <Update subscriptionSettings={subscriptionSettingsToBeUpdated} displayUpdate={displayUpdate} setDisplayUpdate={setDisplayUpdate} />}
  {displayView&& <View subscriptionSettings={subscriptionSettingsToBeUpdated} displayView={displayView} setDisplayView={setDisplayView} />} */}
      {videoListDisplay && (
        <SkitiiVideoList
          setVideoListDisplay={setVideoListDisplay}
          videoList={videoList}
          getAllUsers={getAllUsers}
        />
      )}
      {displayUpdate && (
        <UpdateUser
          setDisplayUpdate={setDisplayUpdate}
          videoList={videoList}
          getAllUsers={getAllUsers}
        />
      )}
      {displayAddNew && (
        <AddNewSkitiiUser
          setDisplayAddNew={setDisplayAddNew}
          videoList={videoList}
        />
      )}
      {!displayAddNew &&
        !videoListDisplay &&
        !displayUpdate &&
        !displayView && (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", gap: "5px" }}>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => setDisplayAddNew(true)}
                >
                  Add new
                </Button>
              </div>

              <div style={{ display: "flex", gap: "10px" }}>
                <FormControl>
                  <InputLabel id="select-label">{t("Searchby")}</InputLabel>
                  <Select
                    labelId="select-label"
                    id="select"
                    label="Search by"
                    size="small"
                    value={searchBy}
                    onChange={(event) => setSearchBy(event.target.value)}
                  >
                    <MenuItem value="Name">{t("Name")}</MenuItem>
                    <MenuItem value="Email">{t("Email")}</MenuItem>
                    <MenuItem value="Phone">Phone No</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  size="small"
                  label="Search here"
                  inputProps={{
                    autoComplete: "new-password",
                  }}
                  value={searchInput}
                  onChange={(event) => setSearchInput(event.target.value)}
                />
                <Button variant="contained" size="small" onClick={getAllUsers}>
                  Filter
                </Button>
              </div>
            </div>

            <TableContainer
              component={Paper}
              sx={{ marginTop: "3px", height: 440 }}
            >
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#333" }}>
                    <TableCell sx={{ color: "white", textAlign: "center" }}>
                      {t("Name")}
                    </TableCell>
                    <TableCell sx={{ color: "white", textAlign: "center" }}>
                      Email
                    </TableCell>
                    <TableCell sx={{ color: "white", textAlign: "center" }}>
                      Phone
                    </TableCell>
                    <TableCell sx={{ color: "white", textAlign: "center" }}>
                      Company Name
                    </TableCell>
                    <TableCell sx={{ color: "white", textAlign: "center" }}>
                      Business Type
                    </TableCell>
                    <TableCell sx={{ color: "white", textAlign: "center" }}>
                      View Video List
                    </TableCell>

                    <TableCell sx={{ color: "white", textAlign: "center" }}>
                      {t("Actions")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allUsers?.map((row) => (
                    <TableRow key={uuidv4()}>
                      <TableCell sx={{ textAlign: "center" }}>
                        {row.Name}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {row.Email}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {row.Phone}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {row.CompanyName}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {row.TypeOfBusiness}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Button
                          size="small"
                          variant="contained"
                          sx={{ marginLeft: "10px" }}
                          onClick={() => {
                            setVideoListDisplay(true);
                            setVideoList(row);
                          }}
                        >
                          {" "}
                          <RemoveRedEyeIcon
                            onClick={() => {
                              setVideoListDisplay(true);
                              setVideoList(row);
                            }}
                          />
                        </Button>
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => {
                            setDisplayUpdate(true);
                            setVideoList(row);
                          }}
                        >
                          {" "}
                          <EditIcon />
                        </Button>
                        <Button
                          size="small"
                          variant="contained"
                          sx={{ marginLeft: "10px" }}
                          //   onClick={event => { setSubscriptionSettingsToBeUpdated(subscriptionSettingsToBeUpdated => row); setDisplayView(true); }}
                        >
                          {" "}
                          <RemoveRedEyeIcon />
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => deleteHandler(row._id)}
                        >
                          <DeleteIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <div
              style={{
                marginTop: "15px",
                display: "flex",
                justifyContent: "space-between",
                gap: "30px",
              }}
            >
              {" "}
              <div
                style={{
                  border: 2,
                  // padding: 5,
                  marginTop: "10px",
                  textAlign: "center",
                  display: "flex",
                  gap: "30px",
                }}
              >
                <Typography style={{ marginTop: "10px" }}>
                  Page Number
                </Typography>
                {/* <input
                                  type="number"
                                  value={pageNumber}
                                  onChange={handlePageInputChange}
                                  style={{ width: "50px", textAlign: "center" }}
                                  min="1"
                                  max={totalPage}
                                /> */}
                <TextField
                  type="number"
                  value={inputPageNumber}
                  onChange={handlePageInputChange}
                  style={{
                    textAlign: "center",
                    borderWidth: 2,
                  }}
                  placeholder="Enter Page Number.."
                  max={totalPage}
                  min={1}
                />
                <Button variant="contained" onClick={handleGoButtonClick}>
                  Go
                </Button>
              </div>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "30px" }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "15px" }}
                >
                  <Typography>Rows per page</Typography>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Rows</InputLabel>
                    <Select
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={rowsLimit}
                      label="Rows limit"
                      onChange={(event) => {
                        setRowsLimit(event.target.value);
                        setPageNumber(1);
                      }}
                    >
                      <MenuItem value="5">5</MenuItem>
                      <MenuItem value="10">10</MenuItem>
                      <MenuItem value="15">15</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "0px",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <SkipPreviousIcon
                    onClick={(event) =>
                      setPageNumber((pageNumber) =>
                        pageNumber <= 1 ? 1 : --pageNumber,
                      )
                    }
                  />
                  <div style={{ minWidth: "50px", textAlign: "center" }}>
                    {pageNumber} of {totalPage}
                  </div>
                  <SkipNextIcon
                    onClick={(event) =>
                      setPageNumber((pageNumber) =>
                        pageNumber < totalPage ? ++pageNumber : totalPage,
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export default SkitiiAIUser;
