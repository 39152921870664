import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaSort } from "react-icons/fa";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import ViewUserList from "./ViewUserList";
import Swal from "sweetalert2";
import { AddData } from "../../api/GeneralFile";
import { toast } from "react-toastify";

const SkitiiVideoList = (props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const formatViews = (num) => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // Format as millions
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + "K"; // Format as thousands
    }
    return num.toString(); // Return the number as is if less than 1000
  };

  const [searchQuery, setSearchQuery] = useState("");

  const [tag, setTag] = useState("All Data");
  const [videoData, setVideoData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [isVideoSelect, setIsVideoSelect] = useState();
  const [isViewUserDisplay, setIsViewUserDisplay] = useState(false);
  const [display, setDisplay] = useState(true);
  const [data, setData] = useState();
  const [category, setCategory] = useState("Individual Data");
  const [category2, setCategory2] = useState("All Data");

  useEffect(() => {
    if (props?.videoList) {
      if (category === "Individual Data") {
        if (isViewUserDisplay) {
          let filteredViewers = data?.viewer || [];

          if (searchQuery !== "") {
            filteredViewers = filteredViewers?.filter((item) =>
              item.Name.toLowerCase().includes(searchQuery.toLowerCase()),
            );
          }

          setUserData(filteredViewers);
        } else {
          let filteredVideos =
            props?.videoList?.UploadedVideo.filter(
              (item) => item.Tag != "Demo",
            ) || [];

          // Apply tag filter
          if (tag !== "All Data") {
            filteredVideos = filteredVideos.filter((item) => item.Tag === tag);
          }

          // Apply search query filter
          if (searchQuery !== "") {
            filteredVideos = filteredVideos.filter((item) =>
              item.Name.toLowerCase().includes(searchQuery.toLowerCase()),
            );
          }

          setVideoData(filteredVideos);
        }
      } else {
        if (isViewUserDisplay) {
          let filteredViewers = data?.viewer || [];

          if (searchQuery !== "") {
            filteredViewers = filteredViewers?.filter((item) =>
              item.Name.toLowerCase().includes(searchQuery.toLowerCase()),
            );
          }

          setUserData(filteredViewers);
        } else {
          let filteredVideos =
            props?.videoList?.UploadedVideo.filter(
              (item) => item.Tag != "Demo" && item.Tag != "Camera",
            ) || [];

          // Apply tag filter
          if (tag !== "All Data") {
            filteredVideos = filteredVideos.filter((item) => item.Tag === tag);
          }

          // Apply search query filter
          if (searchQuery !== "") {
            filteredVideos = filteredVideos.filter((item) =>
              item.Name.toLowerCase().includes(searchQuery.toLowerCase()),
            );
          }

          setVideoData(filteredVideos);
        }
      }
    }
  }, [searchQuery, props?.videoList, tag, category, isViewUserDisplay, data]);

  useEffect(() => {
    if (props?.videoList) {
      let filteredVideos =
        props?.videoList?.UploadedVideo.filter((item) => item.Tag != "Demo") ||
        [];

      setVideoData(filteredVideos);
    }
  }, [props?.videoList]);
  console.log(videoData);
  useEffect(() => {
    if (isVideoSelect === "videoData") {
      setVideoData(
        props?.videoList?.UploadedVideo.filter(
          (item) => item.Tag != "Camera" && item.Tag != "Demo",
        ),
      );
    }
  }, [isVideoSelect, props?.videoList]);
  const [sortOrder, setSortOrder] = useState("asc");

  const sortByColumn = (columnName) => {
    const sortComparator = (key, order) => (a, b) => {
      console.log(a, b);
      const comparison = a[key] > b[key] ? 1 : -1;
      return order === "asc" ? comparison : -comparison;
    };

    if (columnName === "Tag") {
      const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
      const sortedUsers = videoData
        .slice()
        .sort(sortComparator(columnName, newSortOrder));
      setSortOrder(newSortOrder);
      setVideoData(sortedUsers);
    }
  };

  const deleteHandler = async (item) => {
    if (item?.viewer?.length > 0) {
      Swal.fire({
        title: "Are you sure?",
        text: `Do you want to delete this Video? You will lose the data permanently.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          AddData(`/skitiiai/delete`, {
            id: item._id,
            user: props?.videoList,
          })
            .then((response) => {
              toast(response.data.message);
              props.setVideoListDisplay(false);
              props?.getAllUsers();
            })
            .catch((err) => {
              console.error(err, "error");
              toast(err.data?.message);
            });
        }
      });
    } else {
      AddData(`/skitiiai/delete`, {
        id: item._id,
        user: props?.videoList,
      })
        .then((response) => {
          console.log(response.data);
          props.setVideoListDisplay(false);
          props?.getAllUsers();
        })
        .catch((err) => {
          console.error(err, "error");
          toast(err.data?.message);
        });
    }
  };
  useEffect(() => {
    if (category2 === "All Data") {
      setTag("All Data");
      setIsVideoSelect("");
    } else if (category2 === "Camera") {
      setTag("Camera");
      setIsVideoSelect("");
    } else {
      setTag("");
      setIsVideoSelect("videoData");
    }
  }, [category2]);
  const tags = [
    "Ads",
    "Action",
    "Adventure",
    "Comedy",
    "Drama",
    "Fantasy",
    "Horror",
    "Musicals",
    "Mystery",
    "Sports",
    "Science Fiction",
    "Thriller",
  ];

  return (
    <>
      {isViewUserDisplay && (
        <ViewUserList
          setIsViewUserDisplay={setIsViewUserDisplay}
          data={data}
          userData={userData}
          user={props?.videoList}
          category={category}
        />
      )}
      {!isViewUserDisplay && (
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Category{" "}
              </FormLabel>
              <RadioGroup
                row
                aria-label="category"
                name="category"
                value={category}
                onChange={(event) => {
                  setCategory(event.target.value);
                }}
              >
                <FormControlLabel
                  value="Individual Data"
                  control={<Radio />}
                  label="Individual Data"
                />
                <FormControlLabel
                  value="Cumulative Data"
                  control={<Radio />}
                  label="Cumulative Data"
                />
              </RadioGroup>
            </FormControl>
            <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
              <TextField
                size="small"
                label="Search By Video Name.."
                inputProps={{
                  autoComplete: "new-password",
                }}
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.target.value)}
              />
              <Button
                variant="contained"
                size="small"
                onClick={props?.getAllUsers}
              >
                Filter
              </Button>
            </div>
          </div>
          {category === "Individual Data" && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <FormControl>
                <RadioGroup
                  row
                  aria-label="category"
                  name="category"
                  value={category2}
                  onChange={(event) => {
                    setCategory2(event.target.value);
                  }}
                >
                  <FormControlLabel
                    value="All Data"
                    control={<Radio />}
                    label="All Data"
                  />
                  <FormControlLabel
                    value="Camera"
                    control={<Radio />}
                    label="Camera"
                  />
                  <FormControlLabel
                    value="Video"
                    control={<Radio />}
                    label="Video"
                  />
                  {/* {tags.map((item) => (
                  <FormControlLabel
                    key={item}
                    value={item}
                    control={<Radio />}
                    label={item}
                  />
                ))} */}
                </RadioGroup>
              </FormControl>
            </div>
          )}
          {isVideoSelect === "videoData" && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <FormControl>
                <RadioGroup
                  row
                  aria-label="category"
                  name="category"
                  value={tag}
                  onChange={(event) => {
                    setTag(event.target.value);
                  }}
                >
                  {tags.map((item) => (
                    <FormControlLabel
                      key={item}
                      value={item}
                      control={<Radio />}
                      label={item}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
          )}
          <TableContainer component={Paper}>
            <Table
              size="small"
              aria-label="a dense table"
              // sx={{ border: "none" }}
            >
              <TableHead>
                <TableRow sx={{ backgroundColor: "#333" }}>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    Video Name
                  </TableCell>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    {/* <h1 className="font-bold text-white text-center text-sm md:text-xl">
                        Tag
                      </h1> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Tag
                      <FaSort
                        style={{ padding: "5px", backgroundColor: "#000" }}
                        onClick={(event) => sortByColumn("Tag")}
                      />
                    </div>
                  </TableCell>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    Views
                  </TableCell>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    Viewer
                  </TableCell>
                  {/* <TableCell>
                    <h1 className="font-bold text-white text-center text-sm md:text-xl">
                      Name
                    </h1>
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  // videoData
                  videoData
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage,
                    )
                    ?.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={index}
                          // onClick={() => handleRowClick(row._id)}
                        >
                          <TableCell sx={{ textAlign: "center" }}>
                            {row?.Name}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {row?.Tag}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {formatViews(row?.viewer?.length) || 0}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            <Button
                              size="small"
                              variant="contained"
                              sx={{ marginLeft: "10px" }}
                              onClick={() => {
                                setIsViewUserDisplay(true);
                                setData(row);
                              }}
                            >
                              <RemoveRedEyeIcon
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setIsViewUserDisplay(true);
                                  setData(row);
                                }}
                              />
                            </Button>
                            <Button
                              size="small"
                              variant="contained"
                              sx={{ marginLeft: "10px" }}
                              onClick={() => deleteHandler(row)}
                            >
                              <DeleteIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={videoData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
            <Button
              variant="contained"
              disabled={isLoading}
              onClick={() => props.setVideoListDisplay(false)}
            >
              {t("Cancel")}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default SkitiiVideoList;
