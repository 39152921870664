import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AddData, GetData, UpdateData } from "../../api/GeneralFile";
import { FaSort } from "react-icons/fa";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import loadingGIF from "../../assets/images/loading-gif.gif";
import EditUserData from "./EditUserData";
import Papa from "papaparse";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import Upload from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";

const XLSXDownload = (props) => {
  const handleDownload = () => {
    const wb = XLSX.utils.book_new();

    const getTotalMarks = (user) => {
      if (props.category === "Intra School") {
        const offline = user?.OfflineIntraMarks ? user?.OfflineIntraMarks : 0;
        const online = user?.IntraSchoolMarks ? user?.IntraSchoolMarks : 0;
        const total = parseFloat(offline) + parseFloat(online);
        return total.toFixed(0);
      } else if (props.category === "State") {
        const offline = user?.OfflineStateMarks ? user?.OfflineStateMarks : 0;
        const online = user?.StateMarks ? user?.StateMarks : 0;
        const total = parseFloat(offline) + parseFloat(online);
        return total.toFixed(0);
      } else if (props.category === "National") {
        const offline = user?.OfflineNationalMarks
          ? user?.OfflineNationalMarks
          : 0;
        const online = user?.NationalMarks ? user?.NationalMarks : 0;
        const total = parseFloat(offline) + parseFloat(online);
        return total.toFixed(0);
      }
    };

    const sheetData = props
      ? props.data.map((entry) => {
          return props.category === "Intra School"
            ? {
                Name: entry.name,
                Dob: entry.dob,
                Class: entry.grade,
                PhoneNo: entry.phoneNo,
                Hallticket: entry.hallticket,
                IntraSchoolMarks: entry.IntraSchoolMarks
                  ? entry.IntraSchoolMarks
                  : 0,
                OfflineIntraMarks: entry.OfflineIntraMarks
                  ? entry.OfflineIntraMarks
                  : 0,
                TotalMarks: getTotalMarks(entry),
                IntraSchoolTime: entry.IntraSchoolTime
                  ? entry.IntraSchoolTime
                  : 0,
                SchoolName: entry.school.schoolName,
                "EQ Score": props?.getEqScoreData(entry),
              }
            : props.category === "State"
              ? {
                  Name: entry.name,
                  DOB: entry.dob,
                  Class: entry.grade,
                  PhoneNo: entry.phoneNo,
                  Hallticket: entry.hallticket,
                  StateMarks: entry.StateMarks ? entry.StateMarks : 0,
                  OfflineStateMarks: entry.OfflineStateMarks
                    ? entry.OfflineStateMarks
                    : 0,
                  TotalMarks: getTotalMarks(entry),
                  StateTime: entry.StateTime ? entry.StateTime : 0,
                  SchoolName: entry.school.schoolName,
                  "EQ Score": props?.getEqScoreData(entry),
                }
              : {
                  Name: entry.name,
                  DOB: entry.dob,
                  Class: entry.grade,
                  PhoneNo: entry.phoneNo,
                  Hallticket: entry.hallticket,
                  NationalMarks: entry.NationalMarks ? entry.NationalMarks : 0,
                  OfflineNationalMarks: entry.OfflineNationalMarks
                    ? entry.OfflineNationalMarks
                    : 0,
                  TotalMarks: getTotalMarks(entry),
                  NationalTime: entry.NationalTime ? entry.NationalTime : 0,
                  SchoolName: entry.school.schoolName,
                  "EQ Score": props?.getEqScoreData(entry),
                };
        })
      : null;

    const ws = XLSX.utils.json_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(wb, ws, props.name);
    XLSX.writeFile(wb, props.name + ".xlsx");
  };
  XLSXDownload.propTypes = {
    data: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
  };
  return (
    <Button variant="contained" size="small" onClick={handleDownload}>
      Export file as excel sheet
    </Button>
  );
};

const AllStudent = (props) => {
  const { t } = useTranslation();

  const [searchInput, setSearchInput] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsLimit, setRowsLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [searchBy, setSearchBy] = useState("Name");
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [category, setCategory] = useState("National");
  const [displayUpdate, setDisplayUpdate] = useState(false);
  const userToBeUpdated = "";
  const [distinctGrades, setDistinctGrades] = useState([]);
  const [grade, setGrade] = useState("");
  const [distinctState, setDistinctState] = useState([]);
  const [rank, setRank] = useState("");
  const [open, setOpen] = useState(false);
  const [id, setId] = useState();
  const [status, setStatus] = useState(false);
  const [nationalStudent, setNationalStudent] = useState();
  const [sortedAllUsers, setSortedAllUsers] = useState([]);
  const [isPictureUpload, setIsPictureUpload] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const getDistinctValue = () => {
    GetData(`/gfoExamSchoolLogin/getdistincts/value`)
      .then((response) => {
        // console.log(response, "response");
        // setUsers(response.data.results.paginatedUsers);
        // setTotalPage(response.data.results.totalPage);
        // setAllUsers(response.data.allFilteredStudents);
        setDistinctGrades(response.data.distinctGrades);
        setDistinctState(response.data.distinctState);

        if (response.data?.message === false) {
          toast(response.data?.message);
        }
      })
      .catch((err) => {
        console.error(err, "errpr");
        toast(err.data?.message);
      });
  };
  const searchData = () => {
    setIsLoading2(true);

    GetData(
      `/gfoExamSchoolLogin/getschool/national/students/hj?isChecked=${isChecked}&isPictureUpload=${isPictureUpload}&searchBy=${searchBy}&search=${searchInput}&pageNumber=${pageNumber}&rowsLimit=${rowsLimit}&category=${category}&grade=${grade}`,
    )
      .then((response) => {
        console.log(response, "response");
        setUsers(response.data.results.paginatedUsers);
        setTotalPage(response.data.results.totalPage);
        setAllUsers(response.data.allFilteredStudents);
        // setDistinctGrades(response.data.distinctGrades);
        // setDistinctState(response.data.distinctState);

        if (response.data?.message === false) {
          toast(response.data?.message);
        }
      })
      .catch((err) => {
        console.error(err, "errpr");
        toast(err.data?.message);
      })
      .finally(() => {
        setIsLoading2(false);
      });
  };

  useEffect(() => {
    searchData();
  }, [
    pageNumber,
    rowsLimit,
    props,
    category,
    grade,
    isPictureUpload,
    isChecked,
  ]);
  useEffect(() => {
    getDistinctValue();
  }, []);

  useEffect(() => {
    // setSearchInput("");
    setPageNumber(1);
  }, [grade, category]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setPageNumber(1);
      setSearchInput(event.target.value);
      searchData();
    }
  };

  const [sortOrder, setSortOrder] = useState("asc");

  const sortByColumn = (columnName) => {
    const sortComparator = (key, order) => (a, b) => {
      let comparison = 0;

      if (columnName === "Total Marks") {
        const totalMarksA = getTotalMarks(a);
        const totalMarksB = getTotalMarks(b);
        if (parseInt(totalMarksA) !== parseInt(totalMarksB)) {
          comparison = parseInt(totalMarksA) > parseInt(totalMarksB) ? 1 : -1;
        } else {
          const specificMarksA = getMarks(a);
          const specificMarksB = getMarks(b);

          if (parseInt(specificMarksA) !== parseInt(specificMarksB)) {
            comparison =
              parseInt(specificMarksA) > parseInt(specificMarksB) ? 1 : -1;
          } else {
            const timeA = getTime(a);
            const timeB = getTime(b);
            if (parseInt(timeA) !== parseInt(timeB)) {
              comparison = parseInt(timeA) < parseInt(timeB) ? 1 : -1;
            } else {
              const offlineA = getOfflineMarks(a);
              const offlineB = getOfflineMarks(b);
              comparison = parseInt(offlineA) > parseInt(offlineB) ? 1 : -1;
            }
          }
        }
      } else {
        comparison = a[key] > b[key] ? 1 : -1;
      }
      return order === "asc" ? comparison : -comparison;
    };

    if (
      columnName === "_id" ||
      columnName === "Name" ||
      columnName === "Total Marks"
    ) {
      const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
      const sortedUsers = allUsers
        .slice()
        .sort(sortComparator(columnName, newSortOrder));
      setSortOrder(newSortOrder);
      setUsers(sortedUsers);
      setAllUsers(sortedUsers);
    }
  };

  useEffect(() => {
    const sortComparator = (key, order) => (a, b) => {
      let comparison = 0;

      const totalMarksA = getTotalMarks(a);
      const totalMarksB = getTotalMarks(b);
      if (parseInt(totalMarksA) !== parseInt(totalMarksB)) {
        comparison = parseInt(totalMarksA) > parseInt(totalMarksB) ? 1 : -1;
      } else {
        const specificMarksA = getMarks(a);
        const specificMarksB = getMarks(b);

        if (parseInt(specificMarksA) !== parseInt(specificMarksB)) {
          comparison =
            parseInt(specificMarksA) > parseInt(specificMarksB) ? 1 : -1;
        } else {
          const timeA = getTime(a);
          const timeB = getTime(b);

          if (parseInt(timeA) !== parseInt(timeB)) {
            comparison = parseInt(timeA) < parseInt(timeB) ? 1 : -1;
          } else {
            const offlineA = getOfflineMarks(a);
            const offlineB = getOfflineMarks(b);
            comparison = parseInt(offlineA) > parseInt(offlineB) ? 1 : -1;
          }
        }
      }

      return order === "asc" ? comparison : -comparison;
    };

    const newSortOrder = "desc";
    const sortedUsers = allUsers.slice().sort(sortComparator(newSortOrder));

    setSortedAllUsers(sortedUsers);
  }, [allUsers]);

  const statusHandler3 = (status, id, rankdata, text) => {
    AddData(`/gfoExamSchoolLogin/setFinalstatus/national`, {
      id,
      status,
      category,
      rank: text && text === "allstudent" ? rankdata : rank,
    })
      .then((response) => {
        toast(response.data?.message);
        searchData();
        setOpen(false);
      })
      .catch((err) => {
        console.error(err, "errpr");
        toast(err.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const picdisplayHandler = (status, id) => {
    AddData(`/gfoExamSchoolLogin/setpicDisplay/status`, {
      id,
      status,
    })
      .then((response) => {
        toast(response.data?.message);
        searchData();
      })
      .catch((err) => {
        console.error(err, "errpr");
        toast(err.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getMarks = (user) => {
    if (category === "Intra School") {
      return user?.IntraSchoolMarks || user?.IntraSchoolMarks === 0
        ? user?.IntraSchoolMarks
        : 0;
    } else if (category === "State") {
      return user?.StateMarks || user?.StateMarks === 0 ? user?.StateMarks : 0;
    } else if (category === "National") {
      return user?.NationalMarks || user?.NationalMarks === 0
        ? user?.NationalMarks
        : 0;
    }
  };

  const getTime = (user) => {
    if (category === "Intra School") {
      return user?.IntraSchoolTime ? user?.IntraSchoolTime : 0;
    } else if (category === "State") {
      return user?.StateTime ? user?.StateTime : 0;
    } else if (category === "National") {
      return user?.NationalTime ? user?.NationalTime : 0;
    }
  };

  const valueSetForData = (row) => {
    if (category === "Intra School") {
      return (
        <FormControl fullWidth>
          <FormControlLabel
            control={
              <Switch
                checked={row?.isState}
                onChange={(event) => {
                  const newStatus = event.target.checked;
                  if (rankListData(row) === "-" && !row?.isState) {
                    Swal.fire({
                      title:
                        "Student not in top 3 or cutoff percentage,Do you want to continue?",
                      showDenyButton: true,
                      confirmButtonText: "Yes",
                      denyButtonText: `No`,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        statusHandler3(newStatus, row._id);
                      }
                    });
                  } else {
                    statusHandler3(newStatus, row._id);
                  }
                }}
                name="statusToggle"
                color="primary"
              />
            }
            label={row?.isState ? "Qualified" : "Not Qualified"}
          />
        </FormControl>
      );
    } else if (category === "State") {
      return (
        <FormControl fullWidth>
          <FormControlLabel
            control={
              <Switch
                checked={row?.isNational}
                onChange={(event) => {
                  const newStatus = event.target.checked;
                  if (rankListData(row) === "-" && !row?.isNational) {
                    Swal.fire({
                      title:
                        "Student not in top 3 or cutoff percentage,Do you want to continue?",
                      showDenyButton: true,
                      confirmButtonText: "Yes",
                      denyButtonText: `No`,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        statusHandler3(newStatus, row._id);
                      }
                    });
                  } else {
                    statusHandler3(newStatus, row._id);
                  }
                }}
                name="statusToggle"
                color="primary"
              />
            }
            label={row?.isNational ? "Qualified" : "Not Qualified"}
          />
        </FormControl>
      );
    } else if (category === "National") {
      return (
        <FormControl fullWidth>
          <FormControlLabel
            control={
              <Switch
                checked={row?.isFinalQualified}
                onChange={(event) => {
                  setOpen(true);
                  setNationalStudent(row);
                  setId(row._id);
                  setStatus(row?.isFinalQualified);
                  setRank(row?.FinalRank ? row?.FinalRank : "");
                }}
                name="statusToggle"
                color="primary"
              />
            }
            label={
              row?.isFinalQualified
                ? `Qualified_Rank-${row?.FinalRank}`
                : "Not Qualified"
            }
          />
        </FormControl>
      );
    }
  };
  const getOfflineMarks = (user) => {
    if (category === "Intra School") {
      return user?.OfflineIntraMarks ? user?.OfflineIntraMarks : 0;
    } else if (category === "State") {
      return user?.OfflineStateMarks ? user?.OfflineStateMarks : 0;
    } else if (category === "National") {
      return user?.OfflineNationalMarks ? user?.OfflineNationalMarks : 0;
    }
  };

  const getTotalMarks = (user) => {
    if (category === "Intra School") {
      const offline = user?.OfflineIntraMarks ? user?.OfflineIntraMarks : 0;
      const online = user?.IntraSchoolMarks ? user?.IntraSchoolMarks : 0;
      const total = parseFloat(offline) + parseFloat(online);
      return total.toFixed(0);
    } else if (category === "State") {
      const offline = user?.OfflineStateMarks ? user?.OfflineStateMarks : 0;
      const online = user?.StateMarks ? user?.StateMarks : 0;
      const total = parseFloat(offline) + parseFloat(online);
      return total.toFixed(0);
    } else if (category === "National") {
      const offline = user?.OfflineNationalMarks
        ? user?.OfflineNationalMarks
        : 0;
      const online = user?.NationalMarks ? user?.NationalMarks : 0;
      const total = parseFloat(offline) + parseFloat(online);
      return total.toFixed(0);
    }
  };

  const [intraSchoolPercentage, setIntraSchoolPercentage] = useState(0);
  const [statePercentage, setStatePercentage] = useState(0);
  const [nationalPercentage, setNationalPercentage] = useState(0);

  const getPerData = () => {
    setIsLoading(true);
    GetData(`/admin/getQualified/percentagerange`)
      .then((response) => {
        console.log(response);

        setIntraSchoolPercentage(
          parseInt(response.data.percentagerange[0].intraSchoolPercentage)
            ? parseInt(response.data.percentagerange[0].intraSchoolPercentage)
            : 0,
        );
        setStatePercentage(
          parseInt(response.data.percentagerange[0].statePercentage)
            ? parseInt(response.data.percentagerange[0].statePercentage)
            : 0,
        );
        setNationalPercentage(
          parseInt(response.data.percentagerange[0].nationalPercentage)
            ? parseInt(response.data.percentagerange[0].nationalPercentage)
            : 0,
        );
      })
      .catch((err) => {
        console.error(err, "error");
        toast(err.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getPerData();
  }, []);

  const rankListData = (user) => {
    if (
      sortedAllUsers?.length > 0 &&
      user?.hallticket &&
      intraSchoolPercentage &&
      statePercentage &&
      nationalPercentage
    ) {
      if (
        user?.hallticket?.toString() ===
        sortedAllUsers[0]?.hallticket?.toString()
      ) {
        return "1st";
      } else if (
        user?.hallticket?.toString() ===
        sortedAllUsers[1]?.hallticket?.toString()
      ) {
        return "2nd";
      } else if (
        user?.hallticket?.toString() ===
        sortedAllUsers[2]?.hallticket?.toString()
      ) {
        return "3rd";
      } else if (
        intraSchoolPercentage &&
        statePercentage &&
        nationalPercentage
      ) {
        if (
          category === "Intra School" &&
          (getTotalMarks(user) / 200) * 100 >= parseInt(intraSchoolPercentage)
        ) {
          return `>${intraSchoolPercentage}%`;
        } else if (
          category === "State" &&
          (getTotalMarks(user) / 200) * 100 >= parseInt(statePercentage)
        ) {
          return `>${statePercentage}%`;
        } else if (
          category === "National" &&
          (getTotalMarks(user) / 200) * 100 >= parseInt(nationalPercentage)
        ) {
          return `>${nationalPercentage}%`;
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    } else {
      return "-";
    }
  };

  const handleStudentChanged = (e) => {
    const rankArray = sortedAllUsers
      .filter((user) => rankListData(user) !== "-")
      .map((user) => {
        return {
          _id: user._id, // Store the user's _id
          rank: rankListData(user), // Store the rank or percentage result from rankListData
        };
      });
    console.log(rankArray);
    rankArray.forEach((user) => {
      const status = e.target.checked; // Get the new status (true/false) from the switch
      const userId = user._id; // Get the user ID from rankArray

      // Call the appropriate status handler based on the category
      if (category === "Intra School") {
        statusHandler3(status, userId);
      } else if (category === "State") {
        statusHandler3(status, userId);
      } else if (category === "National") {
        statusHandler3(status, userId, user.rank, "allstudent");
      }
    });
  };

  const handleCheckedStatus = () => {
    const rankArray = sortedAllUsers
      .filter((user) => rankListData(user) !== "-")
      .map((user) => {
        return {
          _id: user._id, // Store the user's _id
          rank: rankListData(user), // Store the rank or percentage result from rankListData
        };
      });
    if (rankArray.length > 0) {
      if (category === "Intra School") {
        return sortedAllUsers
          .filter((user) => rankListData(user) !== "-")
          .every((user) => user.isState);
      } else if (category === "State") {
        return sortedAllUsers
          .filter((user) => rankListData(user) !== "-")
          .every((user) => user.isNational);
      } else if (category === "National") {
        return sortedAllUsers
          .filter((user) => rankListData(user) !== "-")
          .every((user) => user.isFinalQualified);
      }
    } else {
      return false;
    }
  };
  const getEqScoreData = (user) => {
    const finaldata = allEQScoreData.filter(
      (item) => item?.participant?._id.toString() === user._id.toString(),
    );

    return finaldata[0]?.averagePoints?.toFixed(2)
      ? finaldata[0]?.averagePoints?.toFixed(2)
      : 0.0;
  };
  const tableRow = (user, index) => {
    return (
      <TableRow
        key={user._id}
        style={{
          backgroundColor:
            sortedAllUsers?.length > 0 && rankListData(user) != "-"
              ? "lightgreen"
              : "",
        }}
      >
        <TableCell>{user.name}</TableCell>

        <TableCell>{user.dob ? user.dob : "-"}</TableCell>
        <TableCell>{user.grade ? user.grade : "-"}</TableCell>
        <TableCell>{user.phoneNo ? user.phoneNo : "-"}</TableCell>
        <TableCell>{user.hallticket ? user.hallticket : "-"}</TableCell>

        <TableCell>{getOfflineMarks(user)}</TableCell>

        <TableCell>{getMarks(user)}</TableCell>
        <TableCell>{getTotalMarks(user)}</TableCell>
        <TableCell>{getTime(user)}</TableCell>
        <TableCell>
          {(sortedAllUsers?.length > 0 && rankListData(user)) || "-"}
        </TableCell>
        {grade && <TableCell>{valueSetForData(user)}</TableCell>}
        <TableCell>{getEqScoreData(user)}</TableCell>
        <TableCell>
          {user?.school?.schoolName ? user?.school?.schoolName : ""}
        </TableCell>
        <TableCell>{user?.school?.state ? user?.school?.state : ""}</TableCell>
        {category === "National" && (
          <TableCell>
            {user?.userPicture ? (
              <a
                href={user?.userPicture}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={user?.userPicture}
                  alt="userPicture"
                  style={{ maxWidth: "100px", maxHeight: "100px" }}
                />
              </a>
            ) : (
              "-"
            )}
          </TableCell>
        )}
        {category === "National" && (
          <TableCell>
            <FormControlLabel
              control={
                <Switch
                  checked={user?.isPicVisible}
                  onChange={(event) =>
                    picdisplayHandler(event.target.checked, user?._id)
                  }
                  name="statusToggle"
                  color="primary"
                />
              }
              label={
                user?.isPicVisible ? "Display Picture" : "Not Display Picture"
              }
            />
          </TableCell>
        )}
      </TableRow>
    );
  };

  const renderItem = (row, index) => {
    return tableRow(row, index);
  };

  const getLabel = () => {
    if (category === "Intra School") {
      return "Is State Qualified?";
    } else if (category === "State") {
      return "Is National Qualified?";
    } else if (category === "National") {
      return "Is Qualified?";
    }
  };

  const rankList = [
    "1st",
    "2nd",
    "3rd",
    "4th",
    "5th",
    "6th",
    "7th",
    "8th",
    "9th",
    "10th",
    "11th",
    "12th",
    "13th",
    "14th",
    "15th",
    "16th",
    "17th",
    "18th",
    "19th",
    "20th",
    "21st",
    "22nd",
    "23rd",
    "24th",
    "25th",
    "26th",
    "27th",
    "28th",
    "29th",
    "30th",
    "31st",
    "32nd",
    "33rd",
    "34th",
    "35th",
    "36th",
    "37th",
    "38th",
    "39th",
    "40th",
    "41st",
    "42nd",
    "43rd",
    "44th",
    "45th",
    "46th",
    "47th",
    "48th",
    "49th",
    "50th",
    "51st",
    "52nd",
    "53rd",
    "54th",
    "55th",
    "56th",
    "57th",
    "58th",
    "59th",
    "60th",
    "61st",
    "62nd",
    "63rd",
    "64th",
    "65th",
    "66th",
    "67th",
    "68th",
    "69th",
    "70th",
    "71st",
    "72nd",
    "73rd",
    "74th",
    "75th",
    "76th",
    "77th",
    "78th",
    "79th",
    "80th",
    "81st",
    "82nd",
    "83rd",
    "84th",
    "85th",
    "86th",
    "87th",
    "88th",
    "89th",
    "90th",
    "91st",
    "92nd",
    "93rd",
    "94th",
    "95th",
    "96th",
    "97th",
    "98th",
    "99th",
    "100th",
    "101st",
    "102nd",
    "103rd",
    "104th",
    "105th",
    "106th",
    "107th",
    "108th",
    "109th",
    "110th",
    "111th",
    "112th",
    "113th",
    "114th",
    "115th",
    "116th",
    "117th",
    "118th",
    "119th",
    "120th",
    "121st",
    "122nd",
    "123rd",
    "124th",
    "125th",
    "126th",
    "127th",
    "128th",
    "129th",
    "130th",
    "131st",
    "132nd",
    "133rd",
    "134th",
    "135th",
    "136th",
    "137th",
    "138th",
    "139th",
    "140th",
    "141st",
    "142nd",
    "143rd",
    "144th",
    "145th",
    "146th",
    "147th",
    "148th",
    "149th",
    "150th",
    "151st",
    "152nd",
    "153rd",
    "154th",
    "155th",
    "156th",
    "157th",
    "158th",
    "159th",
    "160th",
    "161st",
    "162nd",
    "163rd",
    "164th",
    "165th",
    "166th",
    "167th",
    "168th",
    "169th",
    "170th",
    "171st",
    "172nd",
    "173rd",
    "174th",
    "175th",
    "176th",
    "177th",
    "178th",
    "179th",
    "180th",
    "181st",
    "182nd",
    "183rd",
    "184th",
    "185th",
    "186th",
    "187th",
    "188th",
    "189th",
    "190th",
    "191st",
    "192nd",
    "193rd",
    "194th",
    "195th",
    "196th",
    "197th",
    "198th",
    "199th",
    "200th",
  ];

  const handleFormat = () => {
    const wb = XLSX.utils.book_new();
    const sheetData = allUsers.map((entry) => {
      return category === "Intra School"
        ? {
            Name: entry.name,
            Class: entry.grade,
            PhoneNo: entry.phoneNo,
            Hallticket: entry.hallticket,

            OfflineIntraMarks: entry.OfflineIntraMarks
              ? entry.OfflineIntraMarks
              : 0,
          }
        : category === "State"
          ? {
              schoolid: entry.school._id,
              Name: entry.name,
              Class: entry.grade,
              PhoneNo: entry.phoneNo,
              Hallticket: entry.hallticket,

              OfflineStateMarks: entry.OfflineStateMarks
                ? entry.OfflineStateMarks
                : 0,
            }
          : {
              schoolid: entry.school._id,
              Name: entry.name,

              Class: entry.grade,
              PhoneNo: entry.phoneNo,
              Hallticket: entry.hallticket,
              OfflineNationalMarks: entry.OfflineNationalMarks
                ? entry.OfflineNationalMarks
                : 0,
            };
    });

    const ws = XLSX.utils.json_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(wb, ws, "OflineMarks Format");
    XLSX.writeFile(wb, `${category}_OflineMarks Format` + ".xlsx");
  };

  const fileInputRef = useRef(null);
  const [filename, setFilename] = useState("");
  const [uploading, setUploading] = useState(false);
  const uploadScore = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const submitScore = (data) => {
    console.log(data);
    UpdateData("/gfoExamSchoolLogin/common/addOfflineScore", {
      data,
      category,
    })
      .then((response) => {
        console.log(response, "response add user");
        toast(response.data.message);
        fileInputRef.current.value = null;
        setFilename("");
        searchData();
      })
      .catch((err) => {
        console.error(err, "errpr");
        toast(err.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
        setUploading(false);
      });
  };

  const handleFileChange = (e) => {
    setUploading(true);
    const file = e.target.files[0];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    setFilename(file.name);
    if (fileExtension === "csv") {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const newData = results.data.map((row) => {
            const newRow = {};

            Object.keys(row).forEach((key) => {
              const [fieldName] = key.toLowerCase().split("(");
              const fieldValue = row[key];

              newRow[fieldName] = fieldValue;
            });
            return newRow;
          });

          submitScore(newData);
        },
      });
    } else if (fileExtension === "xlsx") {
      const reader = new FileReader();
      reader.onload = (e) => {
        const binaryStr = e.target.result;
        const workbook = XLSX.read(binaryStr, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const headers = jsonData[0];
        const rows = jsonData.slice(1);

        const formattedData = rows
          .map((row) => {
            let rowData = {};
            headers.forEach((header, index) => {
              if (row[index]) {
                // rowData[header.toLowerCase()] = row[index];
                const [fieldName] = header.toLowerCase().split("(");
                const fieldValue = row[index];

                rowData[fieldName] = fieldValue;
              }
            });

            return rowData;
          })
          .filter((row) => Object.keys(row).length !== 0);
        // console.log(formattedData);
        submitScore(formattedData);
      };
      reader.readAsBinaryString(file);
    } else {
      toast.error(
        "Please ensure that uploaded files are in either .csv or .xlsx format.",
      );
    }
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const [eQScoreData, setEQScoreData] = useState([]);
  const [allEQScoreData, setAllEQScoreData] = useState([]);
  const [standard, setStandard] = useState("");
  const [displayViewChart, setDisplayViewChart] = useState(false);
  // const [userToBeUpdated, setUserToBeUpdated] = useState();

  const searchData2 = () => {
    setIsLoading(true);
    GetData(
      `/content-management/eqquestions/getallEqScore?standard=${standard}&searchBy=${searchBy}&category=Gfo Exam&search=${searchInput}&pageNumber=${pageNumber}&rowsLimit=${rowsLimit}`,
    )
      .then((response) => {
        console.log(response, "response");
        setIsLoading(false);
        setEQScoreData(response.data.results.EQScoreData);
        setAllEQScoreData(response.data.data);
      })
      .catch((err) => {
        console.error(err, "errpr");
        toast(err.data?.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    searchData2();
  }, [pageNumber, rowsLimit, standard]);

  const [inputPageNumber, setInputPageNumber] = useState("");

  const handlePageInputChange = (event) => {
    // const value = parseInt(event.target.value, 10);
    // if (!isNaN(value)) {
    //   setInputPageNumber(value);
    // }
    let value = parseInt(event.target.value, 10);

    // Ensure the value is a number and clamp it between 1 and totalPage
    if (!isNaN(value)) {
      value = Math.max(1, Math.min(value, totalPage)); // Clamp between 1 and totalPage
      setInputPageNumber(value);
    } else {
      setInputPageNumber(""); // Clear the input if not a valid number
    }
  };

  const handleGoButtonClick = () => {
    if (inputPageNumber === "") {
      return toast.error("Please enter a valid page number");
    }
    if (inputPageNumber >= 1 && inputPageNumber <= totalPage) {
      setPageNumber(inputPageNumber);
    } else {
      return toast.error("Please Enter a Valid PageNumber");
    }
  };

  return (
    <div style={{ marginTop: "15px" }}>
      {displayUpdate && (
        <EditUserData
          user={userToBeUpdated}
          displayUpdate={displayUpdate}
          setDisplayUpdate={setDisplayUpdate}
          schoolid={props?.school?._id}
          searchData={searchData}
          category={category}
        />
      )}

      {!displayUpdate && (
        <div style={{ textAlign: "center" }}>
          {isLoading ? (
            <img
              src={loadingGIF}
              alt="loading"
              style={{
                width: "50px",
                height: "50px",
                margin: "0 auto",
                marginTop: "50px",
              }}
            />
          ) : (
            <>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{ display: "flex", gap: "30px", alignItems: "center" }}
                >
                  <XLSXDownload
                    data={allUsers}
                    name={`${category}_Students`}
                    category={category}
                    getEqScoreData={getEqScoreData}
                  />
                </div>
                {category === "National" && (
                  <div style={{ borderWidth: 3, padding: 5 }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isPictureUpload}
                          onChange={(event) =>
                            setIsPictureUpload(event.target.checked)
                          }
                          name="statusToggle"
                          color="primary"
                        />
                      }
                      label={
                        isPictureUpload
                          ? "Display Student Shorting"
                          : "Not Display Student Shorting"
                      }
                    />
                  </div>
                )}
                <div style={{ display: "flex", gap: "10px" }}>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Category{" "}
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="category"
                      name="category"
                      value={category}
                      onChange={(event) => {
                        setCategory(event.target.value);
                        setGrade("");
                      }}
                    >
                      <FormControlLabel
                        value="National"
                        control={<Radio />}
                        label="National"
                      />
                      <FormControlLabel
                        value="State"
                        control={<Radio />}
                        label="State"
                      />
                      <FormControlLabel
                        value="Intra School"
                        control={<Radio />}
                        label="Intra School"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl>
                    <InputLabel id="select-label">{t("Searchby")}</InputLabel>
                    <Select
                      labelId="select-label"
                      id="select"
                      label="Search by"
                      size="small"
                      value={searchBy}
                      onChange={(event) => setSearchBy(event.target.value)}
                    >
                      <MenuItem value="Name">{t("Name")}</MenuItem>
                      <MenuItem value="hallticket">Hall Ticket</MenuItem>
                      <MenuItem value="schoolName">School Name</MenuItem>
                      <MenuItem value="state">{t("State")}</MenuItem>
                    </Select>
                  </FormControl>
                  {searchBy === "state" ? (
                    <FormControl>
                      <InputLabel id="select-label">{t("Searchby")}</InputLabel>
                      <Select
                        labelId="select-label"
                        id="select"
                        label="Search by"
                        size="small"
                        value={searchInput}
                        onChange={(event) => setSearchInput(event.target.value)}
                        onKeyDown={handleKeyPress}
                      >
                        {distinctState?.length > 0 &&
                          distinctState?.map((state, index) => (
                            <MenuItem value={state} key={String(index + 1)}>
                              {state}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <form noValidate>
                      <TextField
                        size="small"
                        label={t("Searchhere")}
                        value={searchInput}
                        autoComplete="off"
                        onChange={(event) => setSearchInput(event.target.value)}
                        onKeyDown={handleKeyPress}
                      />
                    </form>
                  )}
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      setPageNumber(1);
                      searchData();
                    }}
                  >
                    {t("Filter")}
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => setSearchInput("")}
                  >
                    {t("Clear")}
                  </Button>
                </div>
              </div>

              {grade && (
                <div
                  style={{
                    borderWidth: 3,
                    padding: 5,
                    width: 500,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={handleCheckedStatus()}
                        onChange={handleStudentChanged}
                        name="statusToggle"
                        color="primary"
                      />
                    }
                    label={
                      handleCheckedStatus()
                        ? "Students Qualified"
                        : "Students Not Qualified"
                    }
                  />
                </div>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 10,
                  gap: 10,
                }}
              >
                <Button variant="contained" size="small" onClick={handleFormat}>
                  Formate For Offline Score <DownloadIcon />
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={uploadScore}
                  disabled={uploading}
                >
                  <input
                    type="file"
                    accept=".csv, .xlsx"
                    ref={fileInputRef}
                    hidden
                    onChange={handleFileChange}
                  />
                  {uploading
                    ? "Uploading..."
                    : filename !== ""
                      ? filename
                      : " Upload Offline Score"}{" "}
                  <Upload />
                </Button>
                <div style={{ borderWidth: 3, padding: 5 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        color="primary"
                      />
                    }
                    label="Display Offline/Online Mismatch Data"
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                <FormControl>
                  <FormLabel>Class </FormLabel>
                  <RadioGroup
                    row
                    aria-label="grade"
                    name="grade"
                    value={grade}
                    onChange={(event) => {
                      setGrade(event.target.value);
                    }}
                  >
                    {distinctGrades?.length > 0 &&
                      distinctGrades?.map((std, index) => (
                        <FormControlLabel
                          value={std}
                          control={<Radio />}
                          label={std}
                          key={String(index + 1)}
                        />
                      ))}
                  </RadioGroup>
                </FormControl>
              </div>
              {isLoading2 ? (
                <img
                  src={loadingGIF}
                  alt="loading"
                  style={{
                    width: "50px",
                    height: "50px",
                    margin: "0 auto",
                    marginTop: "50px",
                  }}
                />
              ) : (
                <TableContainer
                  component={Paper}
                  sx={{ marginTop: "5px", height: 440 }}
                >
                  <Table size="small">
                    <TableHead sx={{ backgroundColor: "#333", color: "#fff" }}>
                      <TableRow>
                        <TableCell sx={{ color: "#fff" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            {t("Name")}{" "}
                            <FaSort
                              style={{
                                padding: "5px",
                                backgroundColor: "#000",
                              }}
                              onClick={(event) => sortByColumn(t("Name"))}
                            />
                          </div>
                        </TableCell>
                        <TableCell sx={{ color: "#fff" }}>{t("DOB")}</TableCell>
                        <TableCell sx={{ color: "#fff" }}>Grade</TableCell>
                        <TableCell sx={{ color: "#fff" }}>
                          {t("Phone No")}
                        </TableCell>
                        <TableCell sx={{ color: "#fff" }}>
                          Hall Ticket No.
                        </TableCell>

                        <TableCell sx={{ color: "#fff" }}>
                          Offline Marks
                        </TableCell>

                        <TableCell sx={{ color: "#fff" }}>
                          Online Marks
                        </TableCell>

                        <TableCell sx={{ color: "#fff" }}>
                          Total Marks
                          <FaSort
                            style={{ padding: 2, backgroundColor: "#000" }}
                            onClick={(event) => sortByColumn("Total Marks")}
                          />
                        </TableCell>
                        <TableCell sx={{ color: "#fff" }}>
                          Online Exam Time(sec)
                        </TableCell>
                        <TableCell sx={{ color: "#fff" }}>Rank</TableCell>
                        {grade && (
                          <TableCell sx={{ color: "#fff" }}>
                            {getLabel()}
                          </TableCell>
                        )}
                        <TableCell sx={{ color: "#fff" }}>EQ Score</TableCell>
                        <TableCell sx={{ color: "#fff" }}>
                          School Name
                        </TableCell>
                        <TableCell sx={{ color: "#fff" }}>State</TableCell>
                        {category === "National" && (
                          <TableCell sx={{ color: "#fff" }}>
                            Student Pic
                          </TableCell>
                        )}
                        {category === "National" && (
                          <TableCell sx={{ color: "#fff" }}>
                            Picture Status
                          </TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users?.map((user, index) => renderItem(user, index))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              <div
                style={{
                  marginTop: "15px",
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "30px",
                }}
              >
                {" "}
                <div
                  style={{
                    border: 2,
                    // padding: 5,
                    marginTop: "10px",
                    textAlign: "center",
                    display: "flex",
                    gap: "30px",
                  }}
                >
                  <Typography style={{ marginTop: "10px" }}>
                    Page Number
                  </Typography>
                  {/* <input
                                  type="number"
                                  value={pageNumber}
                                  onChange={handlePageInputChange}
                                  style={{ width: "50px", textAlign: "center" }}
                                  min="1"
                                  max={totalPage}
                                /> */}
                  <TextField
                    type="number"
                    value={inputPageNumber}
                    onChange={handlePageInputChange}
                    style={{
                      textAlign: "center",
                      borderWidth: 2,
                    }}
                    placeholder="Enter Page Number.."
                    max={totalPage}
                    min={1}
                  />
                  <Button variant="contained" onClick={handleGoButtonClick}>
                    Go
                  </Button>
                </div>
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "30px" }}
                >
                  <div style={{ flexDirection: "row", display: "flex" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      <Typography>{t("RPP")}</Typography>
                      <FormControl>
                        <InputLabel id="demo-simple-select-label">
                          {t("Rows")}
                        </InputLabel>
                        <Select
                          size="small"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={rowsLimit}
                          label="Rows limit"
                          onChange={(event) => {
                            setRowsLimit(event.target.value);
                            setPageNumber(1);
                          }}
                        >
                          <MenuItem value="5">5</MenuItem>
                          <MenuItem value="10">10</MenuItem>
                          <MenuItem value="15">15</MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        gap: "0px",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <SkipPreviousIcon
                        onClick={(event) =>
                          setPageNumber((pageNumber) =>
                            pageNumber <= 1 ? 1 : --pageNumber,
                          )
                        }
                      />
                      <div style={{ minWidth: "50px", textAlign: "center" }}>
                        {pageNumber} of {totalPage}
                      </div>
                      <SkipNextIcon
                        onClick={(event) =>
                          setPageNumber((pageNumber) =>
                            pageNumber < totalPage ? ++pageNumber : totalPage,
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth={true}
        sx={{ "& .MuiDialog-paper": { width: "400px", height: "300px" } }}
      >
        <DialogTitle>Rank</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <b>Select if Qualified</b>
          </DialogContentText>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Switch
                  checked={status}
                  onChange={(event) => {
                    const newStatus = event.target.checked;
                    setStatus(newStatus);
                    !newStatus
                      ? setRank("")
                      : setRank(nationalStudent?.FinalRank);
                  }}
                  name="statusToggle"
                  color="primary"
                />
              }
            />
          </FormControl>
          {status && (
            <>
              {" "}
              <DialogContentText>
                <b>Select Rank</b>
              </DialogContentText>
              <FormControl fullWidth sx={{ marginTop: "15px" }}>
                <InputLabel id="audio-type-select-label">Rank</InputLabel>
                <Select
                  labelId="rank-type-select-label"
                  id="rank-type-select"
                  label="Rank"
                  size="small"
                  value={rank}
                  onChange={(event) => setRank(event.target.value)}
                >
                  {rankList.map((rank, i) => (
                    <MenuItem key={String(i + 1)} value={rank}>
                      {rank}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={() => statusHandler3(status, id)}
            variant="contained"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AllStudent;
