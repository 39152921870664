import React, { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import loadingGIF from "../assets/images/loading-gif.gif";

import Login from "../pages/Login";
import PageNotFound from "../pages/PageNotFound";
import ProtectedRoutes from "../components/ProtectedRoute";
import { AnimatePresence } from "framer-motion";
import GFOExam from "../pages/GFOExam";

import GFOhistory from "./downloads/GFOhistory";
import FinanceDetails from "../pages/FinanceDetails";
import SkitiiAIPage from "../pages/SkitiiAIPage";
import EQQuestionScore from "./Institute-dashboard/Data/EQQuestionScore";

const Dashboard = lazy(() =>
  wait(300).then(() => import("../pages/Dashboard")),
);
const UserManagement = lazy(() =>
  wait(300).then(() => import("../pages/UserManagement")),
);
const AdminManagement = lazy(() =>
  wait(300).then(() => import("../pages/AdminManagement")),
);
const InstituteManagement = lazy(() =>
  wait(300).then(() => import("../pages/InstituteManagement")),
);
const BundledD2CManagement = lazy(() =>
  wait(300).then(() => import("../pages/BundledD2CManagement")),
);
const ContentManagement = lazy(() =>
  wait(300).then(() => import("../pages/ContentManagement")),
);
const SubscriptionSettings = lazy(() =>
  wait(300).then(() => import("../pages/SubscriptionSettings")),
);
const GlobalSettings = lazy(() =>
  wait(300).then(() => import("../pages/GlobalSettings")),
);
const Settings = lazy(() => wait(300).then(() => import("../pages/Settings")));
const Downloads = lazy(() =>
  wait(300).then(() => import("../pages/Downloads")),
);
const ReferralProgram = lazy(() =>
  wait(300).then(() => import("../pages/ReferralProgram")),
);
const InstituteDashboard = lazy(() =>
  wait(300).then(() => import("../pages/InstituteDashboard")),
);
const TiktokActivity = lazy(() =>
  wait(300).then(
    () => import("../components/Institute-dashboard/Data/TiktokActivity"),
  ),
);
const InsUserDetails = lazy(() =>
  wait(300).then(
    () => import("../components/Institute-dashboard/Data/InsUserDetails"),
  ),
);
const MoodCount = lazy(() =>
  wait(300).then(
    () => import("../components/Institute-dashboard/Data/MoodCount"),
  ),
);
const BreakCount = lazy(() =>
  wait(300).then(
    () => import("../components/Institute-dashboard/Data/BreakCount"),
  ),
);
const BestMood = lazy(() =>
  wait(300).then(
    () => import("../components/Institute-dashboard/Data/BestMood"),
  ),
);

const AppRoutes = () => {
  return (
    <div>
      <Suspense
        fallback={
          <img
            src={loadingGIF}
            alt="loading"
            style={{
              width: "30px",
              height: "30px",
              position: "absolute",
              margin: "auto",
              left: "0px",
              right: 0,
              top: "0px",
              bottom: 0,
            }}
          />
        }
      >
        <AnimatePresence mode="wait">
          <Routes>
            <Route path="/login" element={<Login />} />
            {/* <Route path="/certificate" element={<StateCertificate />} /> */}
            {/* <Route path="/certificate" element={<NationalCertificate />} /> */}
            {/* <Route path="/certificate" element={<IntraSchoolCertificate />} /> */}
            <Route element={<ProtectedRoutes />}>
              <Route path="*" element={<PageNotFound />} />

              <Route path="/" element={<Navigate to="/dashboard" />} />
              <Route path="/home" element={<Navigate to="/dashboard" />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/user-management" element={<UserManagement />} />
              <Route path="/admin-management" element={<AdminManagement />} />
              <Route
                path="/institute-management"
                element={<InstituteManagement />}
              />
              <Route path="/bundled-d2c" element={<BundledD2CManagement />} />
              <Route
                path="/content-management"
                element={<ContentManagement />}
              />
              <Route
                path="/subscription-settings"
                element={<SubscriptionSettings />}
              />
              <Route path="/global-settings" element={<GlobalSettings />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/downloads" element={<Downloads />} />
              <Route path="/referral-program" element={<ReferralProgram />} />
              <Route path="/finance-details" element={<FinanceDetails />} />
              <Route
                path="/institute-dashboard"
                element={<InstituteDashboard />}
              />
              <Route path="/institute/tikTok" element={<TiktokActivity />} />
              <Route path="/institute/EqScore" element={<EQQuestionScore />} />
              <Route
                path="/institute/userDetails"
                element={<InsUserDetails />}
              />
              <Route path="/institute/moodDetails" element={<MoodCount />} />
              {/* <Route path="/institute/breakCount" element={<BreakCount />} /> */}
              <Route path="/institute/bestMood" element={<BestMood />} />
              <Route
                path="/institute/GfoCount"
                element={<GFOhistory isInstitute={true} />}
              />
              <Route path="/gfo-exam" element={<GFOExam />} />
              <Route path="/skitiiai" element={<SkitiiAIPage />} />
            </Route>
          </Routes>
        </AnimatePresence>
      </Suspense>
    </div>
  );
};

export default AppRoutes;

const wait = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};
