import React from "react";

const FirstPage = () => {
  return (
    <div className="flex flex-col  px-12 py-5">
      <div className=" mx-auto p-8">
        {/* "max-w-4xl */}
        <h1 className="text-3xl mt-10 font-bold mb-10">
          Understanding Emotion, Sentiment, and Cognitive States
        </h1>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">What is Emotion?</h2>
          <p className="text-xl mb-4">
            Emotion is a complex psychological state involving a combination of
            physiological responses, subjective experiences, and behavioral
            expressions. Emotions arise as reactions to internal thoughts or
            external events and play a critical role in decision-making, social
            interactions, and learning.
          </p>
          <div className="text-xl bg-blue-50 border-l-4 border-blue-400 p-4">
            <h3 className="font-semibold">Example:</h3>
            <p className="text-xl">
              A student receiving praise from a teacher may experience
              happiness, leading to increased motivation. Conversely, a student
              struggling with a difficult test may feel frustration or sadness,
              affecting their performance.
            </p>
          </div>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">What is Sentiment?</h2>
          <p className="text-xl mb-4">
            Sentiment refers to the overall tone or feeling derived from
            emotions, often categorized as positive, negative, or neutral. While
            emotions are momentary reactions, sentiment represents a broader,
            more sustained attitude toward a situation, person, or event.
          </p>
          <div className="text-xl bg-green-50 border-l-4 border-green-400 p-4">
            <h3 className="font-semibold">Example:</h3>
            <ul className="list-disc list-inside">
              <li className="text-xl">
                A student consistently engaged and excited about learning a
                subject may develop a positive sentiment toward it.
              </li>
              <li className="text-xl">
                A student who frequently feels overwhelmed and anxious about
                exams may develop a negative sentiment toward academics.
              </li>
            </ul>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-2">
            Four Cognitive States Captured and Their Significance
          </h2>
          <div className="text-xl bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-6">
            <h3 className="font-semibold">1. High Control State</h3>
            <p className="mb-2 text-xl">
              <strong className="text-xl">Definition:</strong> The user is
              emotionally stable, self-regulated, and focused.
            </p>
            <p>
              <strong className="text-xl">Significance:</strong>
              <ul className="list-disc list-inside ml-4">
                <li className="text-xl">
                  Indicates high emotional intelligence and resilience.
                </li>
                <li className="text-xl">
                  Ideal for learning, problem-solving, and productivity.
                </li>
                <li className="text-xl">
                  Helps in effective decision-making and stress management.
                </li>
              </ul>
            </p>
            <div className="bg-blue-50 border-l-4 border-blue-400 p-4 mt-2">
              <h3 className="text-xl font-semibold">Example:</h3>
              <p className="text-xl">
                A student preparing for an exam who remains calm and focused,
                managing their stress effectively, is in a high control state.
                They can recall information easily and solve problems
                efficiently.
              </p>
            </div>
          </div>

          <div className="text-xl bg-red-50 border-l-4 border-red-400 p-4 mb-6">
            <h3 className="font-semibold">2. Low Control State</h3>
            <p className="mb-2 text-xl">
              <strong className="text-xl">Definition:</strong> The user
              struggles with emotional regulation, showing signs of frustration,
              distraction, or impulsivity.
            </p>
            <p className="text-xl">
              <strong className="text-xl">Significance:</strong>
              <ul className="list-disc list-inside ml-4">
                <li className="text-xl">
                  May lead to errors in judgment and decreased focus.
                </li>
                <li className="text-xl">
                  Often associated with anxiety, stress, or emotional overwhelm.
                </li>
                <li className="text-xl">
                  Intervention (such as mindfulness or emotional coaching) can
                  help regain stability.
                </li>
              </ul>
            </p>
            <div className="bg-blue-50 border-l-4 border-blue-400 p-4 mt-2">
              <h3 className="text-xl font-semibold">Example:</h3>
              <p className="text-xl">
                A student panicking during a test, unable to concentrate and
                making careless mistakes, is in a low control state. Their
                stress response prevents them from recalling studied material.
              </p>
            </div>
          </div>

          {/* <div className="text-xl bg-green-50 border-l-4 border-green-400 p-4 mb-6">
            <h3 className="font-semibold">3. Conductive State</h3>
            <p className="mb-2">
              <strong>Definition:</strong> The user is emotionally engaged in a
              way that promotes learning, creativity, and social interaction.
            </p>
            <p>
              <strong>Significance:</strong>
              <ul className="list-disc list-inside ml-4">
                <li>
                  Indicates curiosity, motivation, and a positive mindset.
                </li>
                <li>Enhances collaboration and open-minded thinking.</li>
                <li>Encourages better communication and teamwork.</li>
              </ul>
            </p>
            <div className="bg-blue-50 border-l-4 border-blue-400 p-4 mt-2">
              <h3 className="font-semibold">Example:</h3>
              <p>
                A student working on a group project with enthusiasm,
                brainstorming innovative ideas, and actively contributing is in
                a conductive state. Their engagement enhances creativity and
                teamwork.
              </p>
            </div>
          </div> */}

          {/* <div className="bg-orange-50 border-l-4 border-orange-400 p-4 mb-6">
            <h3 className="font-semibold">4. Obstructive State</h3>
            <p className="mb-2">
              <strong>Definition:</strong> The user experiences emotions that
              interfere with focus, engagement, or productivity.
            </p>
            <p>
              <strong>Significance:</strong>
              <ul className="list-disc list-inside ml-4">
                <li>
                  Often linked to negative emotions like anger, sadness, or
                  fear.
                </li>
                <li>
                  Can lead to withdrawal, decreased efficiency, or poor
                  decision-making.
                </li>
                <li>
                  Identifying this state early allows for timely intervention
                  and emotional support.
                </li>
              </ul>
            </p>
            <div className="bg-blue-50 border-l-4 border-blue-400 p-4 mt-2">
              <h3 className="font-semibold">Example:</h3>
              <p>
                A student feeling angry or discouraged after failing a test may
                withdraw from class discussions and avoid studying, leading to
                further academic struggles.
              </p>
            </div>
          </div> */}
        </section>
      </div>
    </div>
  );
};

export default FirstPage;
