import React from "react";

const SecondPage = () => {
  return (
    // <div className="min-h-screen bg-gray-100 p-6">
    //   <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-8">
    <div className="flex flex-col  px-12 py-5">
      <div className=" mx-auto p-8">
        <h1 className="text-3xl mt-10 font-bold mb-10">
          Understanding Emotion, Sentiment, and Cognitive States
        </h1>

        <section>
          {/* <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-6">
            <h3 className="font-semibold">2. Low Control State</h3>
            <p className="mb-2">
              <strong>Definition:</strong> The user struggles with emotional
              regulation, showing signs of frustration, distraction, or
              impulsivity.
            </p>
            <p>
              <strong>Significance:</strong>
              <ul className="list-disc list-inside ml-4">
                <li>May lead to errors in judgment and decreased focus.</li>
                <li>
                  Often associated with anxiety, stress, or emotional overwhelm.
                </li>
                <li>
                  Intervention (such as mindfulness or emotional coaching) can
                  help regain stability.
                </li>
              </ul>
            </p>
            <div className="bg-blue-50 border-l-4 border-blue-400 p-4 mt-2">
              <h3 className="font-semibold">Example:</h3>
              <p>
                A student panicking during a test, unable to concentrate and
                making careless mistakes, is in a low control state. Their
                stress response prevents them from recalling studied material.
              </p>
            </div>
          </div> */}

          <div className="text-xl bg-green-50 border-l-4 border-green-400 p-4 mb-6">
            <h3 className="font-semibold">3. Conductive State</h3>
            <p className="text-xl mb-2">
              <strong className="text-xl ">Definition:</strong> The user is
              emotionally engaged in a way that promotes learning, creativity,
              and social interaction.
            </p>
            <p className="text-xl">
              <strong className="text-xl">Significance:</strong>
              <ul className="list-disc list-inside ml-4">
                <li className="text-xl">
                  Indicates curiosity, motivation, and a positive mindset.
                </li>
                <li className="text-xl">
                  Enhances collaboration and open-minded thinking.
                </li>
                <li className="text-xl">
                  Encourages better communication and teamwork.
                </li>
              </ul>
            </p>
            <div className="bg-blue-50 border-l-4 border-blue-400 p-4 mt-2">
              <h3 className="text-xl font-semibold">Example:</h3>
              <p className="text-xl">
                A student working on a group project with enthusiasm,
                brainstorming innovative ideas, and actively contributing is in
                a conductive state. Their engagement enhances creativity and
                teamwork.
              </p>
            </div>
          </div>

          <div className="text-xl bg-orange-50 border-l-4 border-orange-400 p-4 mb-6">
            <h3 className="font-semibold">4. Obstructive State</h3>
            <p className="text-xl mb-2">
              <strong className="text-xl">Definition:</strong> The user
              experiences emotions that interfere with focus, engagement, or
              productivity.
            </p>
            <p>
              <strong className="text-xl">Significance:</strong>
              <ul className="list-disc list-inside ml-4">
                <li className="text-xl">
                  Often linked to negative emotions like anger, sadness, or
                  fear.
                </li>
                <li className="text-xl">
                  Can lead to withdrawal, decreased efficiency, or poor
                  decision-making.
                </li>
                <li className="text-xl">
                  Identifying this state early allows for timely intervention
                  and emotional support.
                </li>
              </ul>
            </p>
            <div className="bg-blue-50 border-l-4 border-blue-400 p-4 mt-2">
              <h3 className="text-xl font-semibold">Example:</h3>
              <p className="text-xl">
                A student feeling angry or discouraged after failing a test may
                withdraw from class discussions and avoid studying, leading to
                further academic struggles.
              </p>
            </div>
          </div>
        </section>
        <section className="bg-gray-50 border-l-4 border-gray-400 p-4">
          <h2 className="text-2xl font-semibold mb-2">Why Does This Matter?</h2>
          <p className="text-xl">
            By analyzing emotions and cognitive states, we can provide valuable
            insights into mental well-being, focus levels, and engagement
            patterns. These insights help in:
          </p>
          <ul className="text-xl list-disc list-inside ml-4 mt-2">
            <li className="text-xl">✔️ Improving student learning outcomes</li>
            <li className="text-xl">✔️ Enhancing workplace productivity</li>
            <li className="text-xl">
              ✔️ Supporting mental and emotional well-being
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default SecondPage;
