import React, { useEffect, useState } from "react";
import styles from "../assets/styles/navigationLinks.module.scss";
import skitiLogo from "../assets/images/logo-nobg.png";
import { NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GfoImg from "../assets/images/gfoExam.png";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import DownloadIcon from "@mui/icons-material/Download";
import PsychologyIcon from "@mui/icons-material/Psychology";
import SourceIcon from "@mui/icons-material/Source";
import SettingsIcon from "@mui/icons-material/Settings";
import PublicIcon from "@mui/icons-material/Public";
import { Tooltip } from "react-tooltip";

import Cookies from "universal-cookie";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setNavRole, setInsEmail } from "../features/inputSlice";
import { useTranslation } from "react-i18next";

const NavigationLinks = () => {
  const cookies = new Cookies();
  const token = cookies.get("TOKEN");

  const [adminNameEmail, setAdminNameEmail] = useState({
    adminUserName: "Username",
    email: "Email address",
  });
  const [isAllowedUserManagement, setIsAllowedUserManagement] = useState(false);
  const [isAllowedAdminManagement, setIsAllowedAdminManagement] =
    useState(false);
  const [isAllowedContentManagement, setIsAllowedContentManagement] =
    useState(false);
  const [isAllowedDownloads, setIsAllowedDownloads] = useState(false);
  const [isAllowedGlobalSettings, setIsAllowedGlobalSettings] = useState(false);
  const [isAllowedInstituteManagement, setIsAllowedInstituteManagement] =
    useState(false);
  const [isAllowedBundledD2C, setIsAllowedBundledD2C] = useState(false);
  const isAllowedReferralProgram = false;
  const [isAllowedSettings, setIsAllowedSettings] = useState(false);
  const [isAllowedSubscriptionSettings, setIsAllowedSubscriptionSettings] =
    useState(false);
  const [isAllowedDashboard, setIsAllowedDashboard] = useState(false);
  const [isAllowedInstituteDashboard, setIsAllowInstituteDashboards] =
    useState(false);
  const [allowGFOExam, setAllowGFOExam] = useState(false);
  const [allowFinanceDetails, setAllowFinanceDetails] = useState(false);
  const [allowSkitiiAIDetails, setAllowSkitiiAIDetails] = useState(false);

  const [role, setRole] = useState("Admin");
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        if (token) {
          const adminConfig = {
            method: "POST",
            url: "/admin/get",
            headers: {
              Authorization: "Bearer " + token,
            },
          };

          const adminResponse = await axios(adminConfig);
          console.log(adminResponse, "...---..>>>> navlinks");

          setAdminNameEmail({
            adminUserName: adminResponse.data.message.admin.firstName,
            email: adminResponse.data.message.admin.email,
          });
          setRole(adminResponse.data.message.admin.role);
          dispatch(setNavRole(adminResponse.data.message.admin.role));
          dispatch(setInsEmail(adminResponse.data.message.admin.email));

          const rolePermission = {
            method: "POST",
            url: "/role-management/get",
            data: { role: adminResponse.data.message.admin.role },
            headers: {
              Authorization: "Bearer " + token,
            },
          };

          const roleResponse = await axios(rolePermission);
          console.log(roleResponse, "get role");

          if (roleResponse.data?.success === false) {
            toast(roleResponse.data?.message);
          }

          setIsAllowedUserManagement(
            roleResponse.data?.result?.allowUserManagement,
          );
          setIsAllowedAdminManagement(
            roleResponse.data?.result?.allowAdminManagement,
          );
          setIsAllowedInstituteManagement(
            roleResponse.data?.result?.allowInstituteManagement,
          );
          setIsAllowedBundledD2C(
            roleResponse.data?.result?.allowBundledD2CManagement,
          );
          setIsAllowedSubscriptionSettings(
            roleResponse.data?.result?.allowSubscriptionSettings,
          );
          setIsAllowedDownloads(roleResponse.data?.result?.allowDownloads);
          setIsAllowedContentManagement(
            roleResponse.data?.result?.allowContentManagement,
          );
          setIsAllowedSettings(roleResponse.data?.result?.allowSettings);
          setIsAllowedGlobalSettings(
            roleResponse.data?.result?.allowGlobalSettings,
          );
          setIsAllowedDashboard(roleResponse.data?.result?.allowDashboard);
          setIsAllowInstituteDashboards(
            roleResponse.data?.result?.allowInstituteDashboard,
          );
          setAllowGFOExam(roleResponse.data?.result?.allowGFOExam);
          setAllowFinanceDetails(
            roleResponse.data?.result?.allowFinanceDetails,
          );
          setAllowSkitiiAIDetails(
            roleResponse.data?.result?.allowSkitiiAIDetails,
          );
        }
      } catch (error) {
        toast(error.data.message);
      }
    };

    fetchAdminData();
  }, [token]);
  return (
    <div className={styles.container}>
      <Tooltip id="my-tooltip" />
      <div className={styles.navigationInnerContainer}>
        <NavLink to="/dashboard" className={styles.logoNameContainer}>
          <img src={skitiLogo} alt="" />
          <div className={styles.siteNameContainer}>
            <div className={styles.mainName}>SKITII Admin Panel</div>
          </div>
        </NavLink>
        <div className={styles.navlinksOuterContainer}>
          <div className={styles.infoContainer}>
            <div className={styles.userNameContainer}>
              {" "}
              {adminNameEmail.adminUserName}{" "}
            </div>
            <div className={styles.emailContainer}>{adminNameEmail.email}</div>
            <div className={styles.emailContainer}>{role}</div>
          </div>
          <div className={styles.navlinksContainer}>
            {isAllowedDashboard && (
              <NavLink
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Dashboard"
                className={styles.navlink}
                to="/dashboard"
                style={({ isActive }) => ({
                  backgroundColor: isActive ? "aliceblue" : "inherit",
                  color: isActive ? "#000" : "#333",
                })}
              >
                <DashboardIcon /> {t("Dashboard")}
              </NavLink>
            )}

            {isAllowedUserManagement && (
              <NavLink
                data-tooltip-id="my-tooltip"
                data-tooltip-content="User management"
                className={styles.navlink}
                to="/user-management"
                style={({ isActive }) => ({
                  backgroundColor: isActive ? "aliceblue" : "inherit",
                  color: isActive ? "#000" : "#333",
                })}
              >
                <ManageAccountsIcon /> {t("User_management")}
              </NavLink>
            )}

            {isAllowedAdminManagement && (
              <NavLink
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Data management"
                className={styles.navlink}
                to="/admin-management"
                style={({ isActive }) => ({
                  backgroundColor: isActive ? "aliceblue" : "inherit",
                  color: isActive ? "#000" : "#333",
                })}
              >
                <AdminPanelSettingsIcon /> {t("Admin_management")}
              </NavLink>
            )}

            {isAllowedInstituteManagement && (
              <NavLink
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Institute management"
                className={styles.navlink}
                to="/institute-management"
                style={({ isActive }) => ({
                  backgroundColor: isActive ? "aliceblue" : "inherit",
                  color: isActive ? "#000" : "#333",
                })}
              >
                <SupervisorAccountIcon /> {t("Institute_management")}
              </NavLink>
            )}

            {isAllowedBundledD2C && (
              <NavLink
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Bundled D2C"
                className={styles.navlink}
                to="/bundled-d2c"
                style={({ isActive }) => ({
                  backgroundColor: isActive ? "aliceblue" : "inherit",
                  color: isActive ? "#000" : "#333",
                })}
              >
                <SupervisorAccountIcon /> Bundled D2C
              </NavLink>
            )}

            {
              isAllowedSubscriptionSettings && (
                <NavLink
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Subscription & SKU"
                  className={styles.navlink}
                  to="/subscription-settings"
                  style={({ isActive }) => ({
                    backgroundColor: isActive ? "aliceblue" : "inherit",
                    color: isActive ? "#000" : "#333",
                  })}
                >
                  <SubscriptionsIcon /> {t("Subscription_settings")}
                </NavLink>
              )
              // ><SubscriptionsIcon /> Subscription Settings</NavLink>
            }

            {isAllowedDownloads && (
              <NavLink
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Downloads"
                className={styles.navlink}
                to="/downloads"
                style={({ isActive }) => ({
                  backgroundColor: isActive ? "aliceblue" : "inherit",
                  color: isActive ? "#000" : "#333",
                })}
              >
                <DownloadIcon /> {t("Downloads")}
              </NavLink>
            )}

            {isAllowedReferralProgram && (
              <NavLink
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Referral program"
                className={styles.navlink}
                to="/referral-program"
                style={({ isActive }) => ({
                  backgroundColor: isActive ? "aliceblue" : "inherit",
                  color: isActive ? "#000" : "#333",
                })}
              >
                <PsychologyIcon /> Referral Program
              </NavLink>
            )}

            {isAllowedContentManagement && (
              <NavLink
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Content management"
                className={styles.navlink}
                to="/content-management"
                style={({ isActive }) => ({
                  backgroundColor: isActive ? "aliceblue" : "inherit",
                  color: isActive ? "#000" : "#333",
                })}
              >
                <SourceIcon />
                Content Management
              </NavLink>
            )}

            {isAllowedSettings && (
              <NavLink
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Settings"
                className={styles.navlink}
                to="/settings"
                style={({ isActive }) => ({
                  backgroundColor: isActive ? "aliceblue" : "inherit",
                  color: isActive ? "#000" : "#333",
                })}
              >
                <SettingsIcon /> {t("Settings")}
              </NavLink>
            )}

            {isAllowedGlobalSettings && (
              <NavLink
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Global settings"
                className={styles.navlink}
                to="/global-settings"
                style={({ isActive }) => ({
                  backgroundColor: isActive ? "aliceblue" : "inherit",
                  color: isActive ? "#000" : "#333",
                })}
              >
                <PublicIcon /> {t("Global_Settings")}
              </NavLink>
            )}
            {allowFinanceDetails && (
              <NavLink
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Finance Details"
                className={styles.navlink}
                to="/finance-details"
                style={({ isActive }) => ({
                  backgroundColor: isActive ? "aliceblue" : "inherit",
                  color: isActive ? "#000" : "#333",
                })}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  width="20"
                  height="20"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.674 2.075a.75.75 0 0 1 .652 0l7.25 3.5A.75.75 0 0 1 17 6.957V16.5h.25a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1 0-1.5H3V6.957a.75.75 0 0 1-.576-1.382l7.25-3.5ZM11 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM7.5 9.75a.75.75 0 0 0-1.5 0v5.5a.75.75 0 0 0 1.5 0v-5.5Zm3.25 0a.75.75 0 0 0-1.5 0v5.5a.75.75 0 0 0 1.5 0v-5.5Zm3.25 0a.75.75 0 0 0-1.5 0v5.5a.75.75 0 0 0 1.5 0v-5.5Z"
                    clipRule="evenodd"
                  />
                </svg>
                {"Finance Details"}{" "}
              </NavLink>
            )}

            {allowSkitiiAIDetails && (
              <NavLink
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Finance Details"
                className={styles.navlink}
                to="/skitiiai"
                style={({ isActive }) => ({
                  backgroundColor: isActive ? "aliceblue" : "inherit",
                  color: isActive ? "#000" : "#333",
                })}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  width="20"
                  height="20"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.674 2.075a.75.75 0 0 1 .652 0l7.25 3.5A.75.75 0 0 1 17 6.957V16.5h.25a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1 0-1.5H3V6.957a.75.75 0 0 1-.576-1.382l7.25-3.5ZM11 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM7.5 9.75a.75.75 0 0 0-1.5 0v5.5a.75.75 0 0 0 1.5 0v-5.5Zm3.25 0a.75.75 0 0 0-1.5 0v5.5a.75.75 0 0 0 1.5 0v-5.5Zm3.25 0a.75.75 0 0 0-1.5 0v5.5a.75.75 0 0 0 1.5 0v-5.5Z"
                    clipRule="evenodd"
                  />
                </svg>
                Skitii AI
              </NavLink>
            )}
            {isAllowedInstituteDashboard && (
              <NavLink
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Institute Dashboard"
                className={styles.navlink}
                to="/institute-dashboard"
                style={({ isActive }) => ({
                  backgroundColor: isActive ? "aliceblue" : "inherit",
                  color: isActive ? "#000" : "#333",
                })}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  width="20"
                  height="20"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.674 2.075a.75.75 0 0 1 .652 0l7.25 3.5A.75.75 0 0 1 17 6.957V16.5h.25a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1 0-1.5H3V6.957a.75.75 0 0 1-.576-1.382l7.25-3.5ZM11 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM7.5 9.75a.75.75 0 0 0-1.5 0v5.5a.75.75 0 0 0 1.5 0v-5.5Zm3.25 0a.75.75 0 0 0-1.5 0v5.5a.75.75 0 0 0 1.5 0v-5.5Zm3.25 0a.75.75 0 0 0-1.5 0v5.5a.75.75 0 0 0 1.5 0v-5.5Z"
                    clipRule="evenodd"
                  />
                </svg>
                {"Institute Dashboard"}{" "}
              </NavLink>
            )}
            {/* {allowGFOExam && (
              <NavLink
                data-tooltip-id="my-tooltip"
                data-tooltip-content="GFO Exam"
                className={styles.navlink}
                to="/gfo-exam"
                style={({ isActive }) => ({
                  backgroundColor: isActive ? "aliceblue" : "inherit",
                  color: isActive ? "#000" : "#333",
                })}
              >
                <img src={GfoImg} style={{ height: 20, width: 20 }} />
              
                {"GFO Exam"}{" "}
              </NavLink>
            )} */}

            {isAllowedInstituteDashboard && (
              <NavLink
                data-tooltip-id="my-tooltip"
                data-tooltip-content="User Details"
                className={styles.navlink}
                to="/institute/userDetails"
                style={({ isActive }) => ({
                  backgroundColor: isActive ? "aliceblue" : "inherit",
                  color: isActive ? "#000" : "#333",
                })}
              >
                {"User Details"}
              </NavLink>
            )}
            {/* {isAllowedInstituteDashboard && (
              <NavLink
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Tik Tok activity"
                className={styles.navlink}
                to="/institute/tikTok"
                style={({ isActive }) => ({
                  backgroundColor: isActive ? "aliceblue" : "inherit",
                  color: isActive ? "#000" : "#333",
                })}
              >
                {"Tik Tok Activity"}
              </NavLink>
            )} */}
            {isAllowedInstituteDashboard && (
              <NavLink
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Tik Tok activity"
                className={styles.navlink}
                to="/institute/EqScore"
                style={({ isActive }) => ({
                  backgroundColor: isActive ? "aliceblue" : "inherit",
                  color: isActive ? "#000" : "#333",
                })}
              >
                EQ Score Data
              </NavLink>
            )}
            {isAllowedInstituteDashboard && (
              <NavLink
                data-tooltip-id="my-tooltip"
                data-tooltip-content="User Emotion"
                className={styles.navlink}
                to="/institute/moodDetails"
                style={({ isActive }) => ({
                  backgroundColor: isActive ? "aliceblue" : "inherit",
                  color: isActive ? "#000" : "#333",
                })}
              >
                {"User Emotion"}
              </NavLink>
            )}
            {isAllowedInstituteDashboard && (
              <NavLink
                data-tooltip-id="my-tooltip"
                data-tooltip-content="GFO Count"
                className={styles.navlink}
                to="/institute/GfoCount"
                style={({ isActive }) => ({
                  backgroundColor: isActive ? "aliceblue" : "inherit",
                  color: isActive ? "#000" : "#333",
                })}
              >
                {"GFO Count"}
              </NavLink>
            )}
            {isAllowedInstituteDashboard && (
              <NavLink
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Feedback Improvemnt"
                className={styles.navlink}
                to="/institute/bestMood"
                style={({ isActive }) => ({
                  backgroundColor: isActive ? "aliceblue" : "inherit",
                  color: isActive ? "#000" : "#333",
                })}
              >
                {"Feedback Improvement"}
              </NavLink>
            )}
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default NavigationLinks;
