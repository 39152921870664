import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Line } from "react-chartjs-2";
import * as XLSX from "xlsx";
import PropTypes from "prop-types";
import jsPDF from "jspdf";
import ReactDOM from "react-dom";
import html2canvas from "html2canvas";

const PrintIndivisualReport2 = (props) => {
  const videoRef = useRef(null);

  const getColorForEmotion = (emotion) => {
    const colors = {
      Happy: "#FF914D",
      Sad: "#0000ff",
      Surprise: "#9D4EDD",
      Disgust: "#FC45BF",
      Angry: "#4FAAF5",
      Neutral: "#29BF12",
      Fear: "#800080",
    };
    return colors[emotion];
  };

  const chartOptions = {
    chart: {
      type: "line",
      height: 450,
    },
    title: {
      text: "Multi-Line Chart Example",
      align: "center",
    },
    xaxis: {
      tickAmount: 10,
      categories: props?.viewerdata?.SummaryData[0]["Happy"].map(
        (item, index) => index + 1,
      ),
    },
    yaxis: {
      title: {
        text: "Values",
      },
    },
    stroke: {
      curve: "smooth", // Makes the lines smooth
      width: 2.5,
    },
    colors: Object.keys(props?.viewerdata?.SummaryData[0]).map((emotion) =>
      getColorForEmotion(emotion),
    ),
    legend: {
      position: "top",
    },
  };

  const chartdata = Object.keys(props?.viewerdata?.SummaryData[0]).map(
    (emotion) => ({
      name: emotion,
      data: props?.viewerdata?.SummaryData[0][emotion],
    }),
  );

  //   const chartdata = Object.keys(getFilteredData()).map((emotion) => ({
  //     name: emotion,
  //     data: getFilteredData()[emotion],
  //   }));

  const getFilteredGazeData = () => {
    return (
      props?.viewerdata?.attentionData[0]
        //   ?.slice(0, currentTime)
        .map((item) => ({
          name: item?.emotion,
          data: item?.gaze_direction === "Center" ? 1 : 0,
        }))
    );
  };

  // Prepare data for the "Gaze Direction" chart
  const series = [
    {
      name: "Attention",
      data: getFilteredGazeData().map((item) => item.data), // Filtered data
    },
  ];
  const options = {
    chart: {
      type: "line",
      zoom: {
        enabled: true,
      },
    },
    title: {
      text: "Attention",
      align: "center",
    },
    xaxis: {
      tickAmount: 10,
      categories: Array.from(
        { length: getFilteredGazeData().length },
        (_, index) => index + 1,
      ), // Up to the current time
      // title: {
      //   text: "Emotion",
      // },
    },
    yaxis: {
      title: {
        text: "Attention (1=True, 0=False)",
      },
    },
    colors: ["#FF0000"],
    stroke: {
      curve: "smooth",
      width: 2.5,
    },
  };

  const getFilteredFaceData = () => {
    return (
      props?.viewerdata?.attentionData[0]
        //   ?.slice(0, currentTime)
        ?.map((item) => ({
          name: item?.emotion,
          data: item?.num_faces,
          // === 0 ? 0 : item?.num_faces === 1 ? 1 : -1,
        }))
    );
  };

  const options2 = {
    chart: {
      type: "line",
      zoom: {
        enabled: true,
      },
    },
    // title: {
    // text: `Face Data`,
    // align: "center",
    // style: {
    //   fontSize: "14px", // Adjust font size for responsiveness
    //   color: "#333",
    //   width: 20,
    // },
    // },
    xaxis: {
      tickAmount: 10,
      categories: Array.from(
        { length: getFilteredFaceData()?.length },
        (_, index) => index + 1,
      ), // Up to the current time
      // title: {
      //   text: "Emotion",
      // },
    },
    yaxis: {
      title: {
        text: "No Of Face",
      },
    },
    colors: ["#FF0000"],
    stroke: {
      curve: "smooth",
      width: 2.5,
    },
  };

  // Prepare data for the "Gaze Direction" chart
  const faceData = [
    {
      name: "Face Data",
      data: getFilteredFaceData().map((item) => item.data), // Filtered data
    },
  ];

  const summaryData = props?.viewerdata?.SummaryData[0];

  // Initialize an array to store results
  const emotionExtremes = [];

  // Iterate over the emotions in summaryData
  for (const [emotion, values] of Object.entries(summaryData)) {
    if (Array.isArray(values)) {
      let max = Number.NEGATIVE_INFINITY;
      let min = Number.POSITIVE_INFINITY;
      const maxIndices = [];
      const minIndices = [];

      // Loop through the array to find max, min and their indices
      values.forEach((value, index) => {
        if (value !== null) {
          if (value > max) {
            max = value;
            maxIndices.length = 0; // Clear previous indices
            maxIndices.push(index + 1); // Add current index
          } else if (value === max) {
            maxIndices.push(index + 1); // Add current index
          }

          if (value < min) {
            min = value;
            minIndices.length = 0; // Clear previous indices
            minIndices.push(index + 1); // Add current index
          } else if (value === min) {
            minIndices.push(index + 1); // Add current index
          }
        }
      });

      // Add the result for the current emotion
      emotionExtremes.push({
        emotion,
        max,
        maxIndices,
        min,
        minIndices,
      });
    }
  }

  const Feeling96 = [
    { emotion: "Happy", x: 0.8, y: 0.7 },
    { emotion: "Joyful", x: 0.9, y: 0.8 },
    { emotion: "Excited", x: 0.7, y: 0.9 },
    { emotion: "Content", x: 0.6, y: 0.4 },
    { emotion: "Proud", x: 0.7, y: 0.6 },
    { emotion: "Grateful", x: 0.6, y: 0.5 },
    { emotion: "Surprise", x: 0.5, y: 0.7 },
    { emotion: "Love", x: 0.9, y: 0.6 },
    { emotion: "Amused", x: 0.7, y: 0.6 },
    { emotion: "Elated", x: 0.8, y: 0.8 },
    { emotion: "Optimistic", x: 0.7, y: 0.5 },
    { emotion: "Hopeful", x: 0.7, y: 0.4 },
    { emotion: "Cheerful", x: 0.7, y: 0.6 },
    { emotion: "Confident", x: 0.6, y: 0.5 },
    { emotion: "Affectionate", x: 0.8, y: 0.5 },
    { emotion: "Calm", x: 0.5, y: 0.2 },
    { emotion: "Peaceful", x: 0.6, y: 0.3 },
    { emotion: "Relaxed", x: 0.7, y: 0.3 },
    { emotion: "Satisfied", x: 0.6, y: 0.4 },
    { emotion: "Tender", x: 0.6, y: 0.3 },
    { emotion: "Contented", x: 0.7, y: 0.3 },
    { emotion: "Pleased", x: 0.6, y: 0.3 },
    { emotion: "Quiet", x: 0.5, y: 0.2 },
    { emotion: "Neutral", x: 0.5, y: 0.5 },
    { emotion: "Bored", x: -0.2, y: 0.3 },
    { emotion: "Anxious", x: -0.5, y: 0.7 },
    { emotion: "Sad", x: -0.7, y: 0.4 },
    { emotion: "Disappointed", x: -0.6, y: 0.5 },
    { emotion: "Irritated", x: -0.6, y: 0.6 },
    { emotion: "Lonely", x: -0.6, y: 0.5 },
    { emotion: "Tired", x: -0.2, y: 0.3 },
    { emotion: "Confused", x: -0.4, y: 0.5 },
    { emotion: "Nervous", x: -0.4, y: 0.6 },
    { emotion: "Frustrated", x: -0.6, y: 0.7 },
    { emotion: "Guilty", x: -0.7, y: 0.5 },
    { emotion: "Ashamed", x: -0.8, y: 0.5 },
    { emotion: "Hopeless", x: -0.8, y: 0.6 },
    { emotion: "Fear", x: -0.7, y: 0.8 },
    { emotion: "Horrified", x: -0.9, y: 0.9 },
    { emotion: "Disgust", x: -0.8, y: 0.7 },
    { emotion: "Embarrassed", x: -0.7, y: 0.6 },
    { emotion: "Angry", x: -0.8, y: 0.8 },
    { emotion: "Enraged", x: -0.9, y: 0.9 },
    { emotion: "Vengeful", x: -0.9, y: 0.8 },
    { emotion: "Miserable", x: -0.9, y: 0.7 },
    { emotion: "Resentful", x: -0.8, y: 0.7 },
    { emotion: "Depressed", x: -0.9, y: 0.5 },
    { emotion: "Terrified", x: -0.9, y: 0.9 },
    // { emotion: "Horrified", x: -0.9, y: 0.8 },
    { emotion: "HelpLess", x: -0.8, y: 0.5 },
    { emotion: "Worried", x: -0.6, y: 0.6 },
    { emotion: "Grief-stricken", x: -0.8, y: 0.5 },
    { emotion: "Shocked", x: -0.5, y: 0.7 },
    // { emotion: "Shocked", x: -0.6, y: 0.7 },
    { emotion: "Regretful", x: -0.6, y: 0.5 },
    { emotion: "Frightened", x: -0.7, y: 0.7 },
    { emotion: "Insecure", x: -0.5, y: 0.4 },
    //  {emotion: "Hopeless",x: -0.7,y: 0.6},
    // { emotion: "Depressed", x: -0.8, y: 0.5 },
    { emotion: "Uncertain", x: -0.4, y: 0.5 },
    // { emotion: "Jealous", x: -0.6, y: 0.6 },
    { emotion: "Uncomfortable", x: -0.5, y: 0.6 },
    { emotion: "Unhappy", x: -0.7, y: 0.6 },
    { emotion: "Agitated", x: -0.7, y: 0.8 },
    { emotion: "Displeased", x: -0.5, y: 0.5 },
    { emotion: "Vulnerable", x: -0.6, y: 0.5 },
    { emotion: "Pessimistic", x: -0.6, y: 0.4 },
    { emotion: "Horrible", x: -0.9, y: 0.8 },
    { emotion: "Bewildered", x: -0.6, y: 0.6 },
    // { emotion: "Angry", x: -0.8, y: 0.9 },
    // { emotion: "Disappointed", x: -0.6, y: 0.6 },
    { emotion: "Defeated", x: -0.7, y: 0.5 },
    { emotion: "Indifferent", x: -0.4, y: 0.4 },
    { emotion: "Hurt", x: -0.7, y: 0.5 },
    { emotion: "Afraid", x: -0.7, y: 0.6 },
    { emotion: "Apprehensive", x: -0.5, y: 0.5 },
    { emotion: "Grief", x: -0.8, y: 0.7 },
    { emotion: "Overwhelmed", x: -0.7, y: 0.8 },
    { emotion: "Tense", x: -0.5, y: 0.6 },
    { emotion: "Resentment", x: -0.7, y: 0.6 },
    // Lonely -0.6 0.5
    { emotion: "Rejected", x: -0.7, y: 0.6 },
    // Hopeless -0.9 0.7
    { emotion: "Shameful", x: -0.8, y: 0.6 },
    { emotion: "Disheartened", x: -0.7, y: 0.5 },
    { emotion: "Deflated", x: -0.6, y: 0.5 },
    { emotion: "Stress", x: -0.7, y: 0.8 },
    { emotion: "Worry", x: -0.6, y: 0.7 },
    { emotion: "Concern", x: -0.2, y: 0.5 },
    { emotion: "Anxiety", x: -0.8, y: 0.9 },
    { emotion: "Upset", x: -0.7, y: 0.7 },
    // { emotion: "Agitated", x: -0.6, y: 0.8 },
    { emotion: "Humiliated", x: -0.9, y: 0.7 },
    { emotion: "Overconfident", x: 0.5, y: 0.6 },
    // { emotion: "Depressed", x: -0.9, y: 0.3 },
    { emotion: "Bothered", x: -0.5, y: 0.6 },
    // { emotion: "Jealous", x: -0.7, y: 0.7 },
    { emotion: "Timid", x: -0.4, y: 0.4 },
    { emotion: "Chilled", x: 0.6, y: 0.2 },
    // { emotion: "Excited", x: 0.8, y: 0.9 },
  ];

  const getXValue = (emotionName) => {
    const found = Feeling96.find((feeling) => feeling.emotion === emotionName);
    return found ? found.x : null; // Return null if emotion is not found
  };
  const getYValue = (emotionName) => {
    const found = Feeling96.find((feeling) => feeling.emotion === emotionName);
    return found ? found.y : null; // Return null if emotion is not found
  };
  const calculateValenceArousal = (data) => {
    console.log(data);
    return props?.data.Tag === "Camera"
      ? data.map((entry, index) => {
          const { emotion_confidences } = entry;
          console.log(emotion_confidences);
          const valence =
            emotion_confidences &&
            Object.keys(emotion_confidences).reduce((sum, emotion) => {
              console.log(
                sum,
                emotion_confidences[emotion],
                emotion,
                Feeling96[emotion]?.x,
              );
              return (
                sum + emotion_confidences[emotion] * (getXValue(emotion) || 0)
              );
            }, 0);
          console.log(valence);
          const arousal =
            emotion_confidences &&
            Object.keys(emotion_confidences).reduce((sum, emotion) => {
              return (
                sum + emotion_confidences[emotion] * (getYValue(emotion) || 0)
              );
            }, 0);

          return {
            ...entry,
            valence: valence ? valence : 0,
            arousal: arousal ? arousal : 0,
          };
        })
      : data.slice(0, -1).map((entry, index) => {
          const { emotion_confidences } = entry;
          console.log(emotion_confidences);
          const valence =
            emotion_confidences &&
            Object.keys(emotion_confidences).reduce((sum, emotion) => {
              console.log(
                sum,
                emotion_confidences[emotion],
                emotion,
                Feeling96[emotion]?.x,
              );
              return (
                sum + emotion_confidences[emotion] * (getXValue(emotion) || 0)
              );
            }, 0);
          console.log(valence);
          const arousal =
            emotion_confidences &&
            Object.keys(emotion_confidences).reduce((sum, emotion) => {
              return (
                sum + emotion_confidences[emotion] * (getYValue(emotion) || 0)
              );
            }, 0);

          return {
            ...entry,
            valence: valence ? valence : 0,
            arousal: arousal ? arousal : 0,
          };
        });
  };
  const [closeEmotion, setCloseEmotion] = useState([]);

  useEffect(() => {
    const result =
      props?.viewerdata?.attentionData[0] &&
      calculateValenceArousal(props?.viewerdata?.attentionData[0]);
    const getEmotion = (V_computed, A_computed, oldEmotion, faceData) => {
      const distances = Feeling96.map(({ emotion, x, y }) => {
        const distance = Math.sqrt(
          Math.pow(V_computed - x, 2) + Math.pow(A_computed - y, 2),
        );
        return {
          emotion,
          distance,
          oldEmotion,
          faceData,
          V_computed,
          A_computed,
        };
      });

      console.log("Distances:", distances);

      // Example: Find the closest emotion
      const closestEmotion = distances.reduce((prev, curr) =>
        prev.distance < curr.distance ? prev : curr,
      );

      console.log("Closest Emotion:", closestEmotion);

      setCloseEmotion((prev) => [...prev, closestEmotion]);
      // return closestEmotion;
    };
    console.log(result);
    result?.map((item, index) =>
      // Emotion: {item?.emotion}, Valence: {item?.valence?.toFixed(3)},
      // Arousal: {item?.arousal?.toFixed(3)}
      // Close Emotion:{" "}
      getEmotion(
        item?.valence?.toFixed(3),
        item?.arousal?.toFixed(3),
        item?.emotion,
        item?.num_faces,
      ),
    );
  }, [props]);

  // console.log("Closest Emotion:", closestEmotion);

  const quadrantCounts = {
    "High Power/Control": 0,
    Obstructive: 0,
    Conductive: 0,
    "Low Power/Control": 0,
  };

  closeEmotion?.length > 0 &&
    closeEmotion?.forEach(
      ({ V_computed: valence, A_computed: arousal, faceData }) => {
        if (faceData === 0) {
          return;
        }

        if (
          (parseFloat(valence) > 0 && parseFloat(arousal) > 0) ||
          parseFloat(valence) === 0.0 ||
          parseFloat(arousal) === 0.0
        )
          quadrantCounts["High Power/Control"]++;
        else if (parseFloat(valence) > 0 && parseFloat(arousal) < 0)
          quadrantCounts["Obstructive"]++;
        else if (parseFloat(valence) < 0 && parseFloat(arousal) > 0)
          quadrantCounts["Conductive"]++;
        else if (parseFloat(valence) < 0 && parseFloat(arousal) < 0)
          quadrantCounts["Low Power/Control"]++;
      },
    );

  // Prepare chart data
  const series3 = Object.values(quadrantCounts); // [++, +-, -+, --]
  const labels = Object.keys(quadrantCounts); // ["++", "+-", "-+", "--"]

  // Chart options
  const options3 = {
    chart: {
      type: "pie",
    },
    labels: labels,
    legend: {
      position: "bottom",
      labels: {
        colors: ["#000000", "#000000", "#000000", "#000000"], // Label color
        useSeriesColors: false,
        fontSize: "20px",
        fontWeight: "bold", // Make legend labels bold
      },
    },
    // colors: ["#FFA07A", "#87CEFA", "#A9DFBF", "#FFC107"],
    colors: ["#D1EFB8", "#A4D8D8", "#F7DFC2", "#EBCCFF"],
    // title: {
    //   text: "Emotion Quadrants Distribution",
    //   align: "center",
    //   style: { fontSize: "24px", fontWeight: "bold", color: "#000000" },
    // },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        const name = opts.w.globals.labels[opts.seriesIndex]; // Quadrant name
        const percentage = val.toFixed(1); // Percentage
        return `${percentage}%`;
      },
      style: {
        fontSize: "24px",
        justifyContent: "center",
        alignItems: "center",
        // fontWeight: "bold",
        colors: ["#000000"], // Adjust text color if needed
      },
    },

    tooltip: {
      enabled: true,
      style: {
        fontSize: "12px",
        fontWeight: "normal", // Tooltip font weight
        color: "#000000", // Tooltip text color
      },
      theme: "light", // Tooltip background theme (optional)
      y: {
        formatter: function (val) {
          return `${val}`; // Customize tooltip value format
        },
      },
    },
  };

  const rowsPerColumn = 10; // Number of rows per column
  const splitDataIntoChunks = (data, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < data.length; i += chunkSize) {
      chunks.push(data.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const formattedData = closeEmotion.map((emotion, index) => {
    return { emotion, formattedEmotion: `${index + 1}-${emotion.emotion}` };
  });
  // Split data into groups of 10
  const chunks = splitDataIntoChunks(formattedData, rowsPerColumn);

  // Split chunks into groups of 3 for new tables
  const tables = splitDataIntoChunks(chunks, 3);
  console.log(tables);

  const getColor = (data) => {
    if (
      (parseFloat(data?.emotion?.V_computed) > 0 &&
        parseFloat(data?.emotion?.A_computed) > 0) ||
      parseFloat(data?.emotion?.V_computed) === 0.0 ||
      parseFloat(data?.emotion?.A_computed) === 0.0
    )
      return "#D1EFB8";
    //  "#FFA07A";
    else if (
      parseFloat(data?.emotion?.V_computed) > 0 &&
      parseFloat(data?.emotion?.A_computed) < 0
    )
      return "#A4D8D8";
    // "#87CEFA";
    else if (
      parseFloat(data?.emotion?.V_computed) < 0 &&
      parseFloat(data?.emotion?.A_computed) > 0
    )
      return "#F7DFC2";
    //  "#A9DFBF";
    else if (
      parseFloat(data?.emotion?.V_computed) < 0 &&
      parseFloat(data?.emotion?.A_computed) < 0
    )
      return "#EBCCFF";
    // "#FFC107";
    else return "#F3F5F8";
  };

  const processEmotionData = (data) => {
    const result = {
      "High Power/Control": {},
      Obstructive: {},
      Conductive: {},
      "Low Power/Control": {},
    };

    data.forEach((item) => {
      const v = parseFloat(item.V_computed);
      const a = parseFloat(item.A_computed);
      const emotion = item.emotion || "Unknown";
      if ((v > 0 && a > 0) || v === 0.0 || a === 0.0) {
        result["High Power/Control"][emotion] =
          (result["High Power/Control"][emotion] || 0) + 1;
      } else if (v > 0 && a < 0) {
        result["Obstructive"][emotion] =
          (result["Obstructive"][emotion] || 0) + 1;
      } else if (v < 0 && a > 0) {
        result["Conductive"][emotion] =
          (result["Conductive"][emotion] || 0) + 1;
      } else if (v < 0 && a < 0) {
        result["Low Power/Control"][emotion] =
          (result["Low Power/Control"][emotion] || 0) + 1;
      }
    });

    return result;
  };

  const processData = processEmotionData(closeEmotion);
  console.log(processData);
  const generateTableData = (quadrantData, row) => {
    console.log(quadrantData);
    const emotions = Object.entries(quadrantData); // Convert object to array: [["Concern", 16], ["Neutral", 4], ...]
    const columns = [];
    let columnData = [];

    emotions.forEach(([emotion, count], index) => {
      columnData.push({ emotion, count });
      if ((index + 1) % row === 0 || index === emotions.length - 1) {
        columns.push(columnData);
        columnData = [];
      }
    });

    return columns;
  };

  return (
    <div
      // className="flex justify-center items-center flex-col"
      className="flex flex-col justify-center items-center px-12 py-5"
      id="certificate"
    >
      <div className="justify-center mb-16 mt-10 items-center flex text-5xl font-bold text-primary2">
        Emotion AI
      </div>
      <div className="w-full ">
        {/* <div className="w-full ">
          <ReactApexChart
            options={chartOptions}
            series={chartdata}
            type="line"
            height={450}
          />
        </div>

        <div className="w-full ">
          <ReactApexChart
            options={options}
            series={series}
            type="line"
            height={450}
          />
        </div>

        <div className="w-full ">
          <h3 className="text-xl md:text-xl font-bold text-center">
            Face Data <br />
            <span className="text-xl md:text-xl ">
             
            </span>
          </h3>
          <ReactApexChart
            options={options2}
            series={faceData}
            type="line"
            height={450}
          />
        </div> */}

        <div className="mt-16 font-bold flex-col justify-center text-center flex items-center text-black text-sm md:text-[20px] ">
          {/* Max/Min Values of emotions */}
          Traking Max/Min value of emotions<br></br>
          <span className="text-sm md:text-[20px]  mt-2">
            for focused engagements
          </span>
        </div>

        <div className="mt-10 flex justify-center items-center ">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer>
              <Table sx={{ border: "none", tableLayout: "fixed" }}>
                <TableHead sx={{ backgroundColor: "#04226d" }}>
                  <TableRow>
                    <TableCell>
                      <h1 className="font-bold text-white text-center text-xl ">
                        Emotion
                      </h1>
                    </TableCell>
                    <TableCell>
                      <h1 className="font-bold text-white text-center text-xl ">
                        Max Value
                      </h1>
                    </TableCell>
                    <TableCell>
                      <h1 className="font-bold text-white text-center text-xl ">
                        Peaks of Max Value
                      </h1>
                    </TableCell>
                    <TableCell>
                      <h1 className="font-bold text-white text-center text-xl ">
                        Min Value
                      </h1>
                    </TableCell>

                    <TableCell>
                      <h1 className="font-bold text-white text-center text-xl ">
                        Peaks of Min Value
                      </h1>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ backgroundColor: "#F3F5F8" }}>
                  {emotionExtremes?.length > 0 &&
                    emotionExtremes?.map((item, index) => (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={item.emotion}
                        // onClick={() => handleRowClick(row._id)}
                      >
                        <TableCell
                          sx={{
                            border: "none",
                            fontSize: 18,
                            textAlign: "center",
                          }}
                        >
                          {item.emotion}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "none",
                            fontSize: 18,
                            textAlign: "center",
                          }}
                        >
                          {item.max}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "none",
                            textAlign: "center",
                            width: "150px", // Set a fixed width
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                          }}
                        >
                          {item.maxIndices.join(",")}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "none",
                            fontSize: 18,
                            textAlign: "center",
                          }}
                        >
                          {item.min}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "none",
                            textAlign: "center",
                            width: "100px", // Set a fixed width
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                          }}
                        >
                          {item.minIndices.join(",")}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
        <div className="justify-center flex-col gap-3 flex items-center mt-16 text-xl font-bold">
          <div className="text-sm gap-2 flex flex-col md:text-[20px]  font-bold text-center">
            <h2 className=" text-sm md:text-[20px]  ">4 Emotion Quadrants</h2>{" "}
            <h2 className=" text-sm md:text-[20px]  ">
              (for optimisation and Decision making)
            </h2>
          </div>
          <ReactApexChart
            options={options3}
            series={series3}
            type="pie"
            height={450}
            width={750}
          />
        </div>
        {/* <div className="mt-16 font-bold justify-center flex items-center text-primary2 text-xm md:text-xl ">
          Sentiments per second
        </div>
        <div className="mt-10 p-5">
          {tables?.length > 0 &&
            tables.map((tableChunks, tableIndex) => {
              const maxRows = Math.max(...tableChunks.map((col) => col.length));
              return (
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer
                    component={Paper}
                    key={tableIndex}
                    // sx={{ width: "100%", overflow: "hidden" }}
                    // sx={{ maxHeight: 440 }}
                    style={{ marginBottom: "20px" }}
                  >
                    <Table sx={{ border: "none" }}>
                      <TableHead sx={{ backgroundColor: "#04226d" }}>
                        <TableRow>
                          {tableChunks.map((_, colIndex) => (
                            <TableCell key={colIndex} align="center">
                              <h1 className="font-bold text-white text-center text-xl ">
                               
                                Sentiments per second
                              </h1>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody sx={{ backgroundColor: "#F3F5F8" }}>
                        {Array.from({ length: maxRows }).map((_, rowIndex) => (
                          <TableRow key={rowIndex}>
                            {tableChunks.map((chunk, colIndex) => (
                              <TableCell
                                key={colIndex}
                                align="center "
                                sx={{
                                  backgroundColor: getColor(chunk[rowIndex]),
                                }}
                              >
                                <h1 className=" text-primary2 text-center text-xl ">
                                  {chunk[rowIndex]?.formattedEmotion || ""}
                                </h1>
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              );
            })}
        </div> */}

        {/* <div className="mt-10">
          {Object.entries(processData).map(([quadrant, quadrantData]) => {
            // <EmotionTable key={quadrant} title={`Quadrant: ${quadrant}`} data={quadrantData} />
            const totalEmotions = Object.values(quadrantData).reduce(
              (sum, count) => sum + count,
              0,
            ); // Calculate total count
            // Helper to split table data into chunks
            const splitIntoChunks = (array, chunkSize) =>
              array.reduce((result, item, index) => {
                const chunkIndex = Math.floor(index / chunkSize);
                if (!result[chunkIndex]) {
                  result[chunkIndex] = []; // Start a new chunk
                }
                result[chunkIndex].push(item);
                return result;
              }, []);
            console.log(generateTableData(quadrantData, 5));
            // Generate table data for each chunk
            const tableChunks = splitIntoChunks(
              generateTableData(quadrantData, 5),
              3, // Max 3 columns per table
            );
            // let tableData = generateTableData(quadrantData, 5);
            // let maxRows = Math.max(...tableData.map((col) => col.length));
            // if (maxRows < 10) {
            //   maxRows = 5;
            //   tableData = generateTableData(quadrantData, 5);
            // }
            const isHighest =
              totalEmotions ===
              Math.max(
                ...Object.values(processData).map((qData) =>
                  Object.values(qData).reduce((sum, count) => sum + count, 0),
                ),
              );
            return (
              <Paper
                key={quadrant}
                sx={{ width: "100%", overflow: "hidden" }}
                className="border-2 border-primary2 m-2"
              >
               
                <Typography align="center" sx={{ padding: 2 }}>
                  <h1 className="font-bold text-xl md:text-2xl">{`${quadrant} (${totalEmotions})`}</h1>
                </Typography>
                {tableChunks.map((tableData, tableIndex) => {
                  const maxRows = Math.max(
                    ...tableData.map((col) => col.length),
                  );
                  return (
                    <TableContainer
                      component={Paper}
                      sx={{ marginBottom: 4 }}
                      key={`${quadrant}-${tableIndex}`}
                    >
                      <Table sx={{ border: "none" }}>
                        <TableHead sx={{ backgroundColor: "#04226d" }}>
                          <TableRow>
                            {tableData.map((_, index) => (
                              <TableCell key={index} align="center">
                                <h1 className="font-bold text-white text-center text-xl ">
                                 
                                  (sentiment-count)
                                </h1>
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody
                          sx={{
                            backgroundColor: isHighest ? "#A4D8D8" : "#F3F5F8",
                          }}
                        >
                          {Array.from({ length: maxRows }).map(
                            (_, rowIndex) => (
                              <TableRow key={rowIndex}>
                                {tableData.map((column, colIndex) => (
                                  <TableCell key={colIndex} align="center">
                                    {column[rowIndex]
                                      ? `${column[rowIndex].emotion} (${column[rowIndex].count})`
                                      : ""}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ),
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  );
                })}
              </Paper>
            );
          })}
        </div>  */}
      </div>
    </div>
  );
};

export default PrintIndivisualReport2;
