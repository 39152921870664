import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AddData, GetData } from "../../api/GeneralFile";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

import { v4 as uuidv4 } from "uuid";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import { useTranslation } from "react-i18next";

const UpdateUser = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [name, setName] = useState(
    props?.videoList?.Name ? props?.videoList?.Name : "",
  );
  const [email, setEmail] = useState(
    props?.videoList?.Email ? props?.videoList?.Email : "",
  );
  const [phone, setPhone] = useState(
    props?.videoList?.Phone ? props?.videoList?.Phone : "",
  );
  const [companyName, setCompanyName] = useState(
    props?.videoList?.CompanyName ? props?.videoList?.CompanyName : "",
  );
  const [typeOfBusiness, setTypeOfBusiness] = useState(
    props?.videoList?.TypeOfBusiness ? props?.videoList?.TypeOfBusiness : "",
  );

  const [timeSeconds, setTimeSeconds] = useState(
    "",
    // props?.videoList?.timeSeconds ? props?.videoList?.timeSeconds : "",
  );
  const [showWarning, setShowWarning] = useState(false);

  const [newTimeSeconds, setNewTimeSeconds] = useState("");

  const [allUsers, setAllUsers] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsLimit, setRowsLimit] = useState(10);
  const [searchBy, setSearchBy] = useState("Name");
  const [totalPage, setTotalPage] = useState(0);
  const { t } = useTranslation();

  const [remainigTimeData, setRemainigTimeData] = useState("");
  const [viewTimeData, setViewTimeData] = useState("");
  const [purchaseTimeData, setPurchaseTimeData] = useState("");
  const getRemainingtime = () => {
    AddData(`/skitiiai/getRemainingtime`, {
      userId: props?.videoList?._id,
    })
      .then((response) => {
        console.log(response.data);
        setRemainigTimeData(
          response.data.remainingTime ? response.data.remainingTime : 0,
        );
        setViewTimeData(
          response.data.totalViewTime ? response.data.totalViewTime : 0,
        );
        setPurchaseTimeData(
          response.data.totalPlanSecond ? response.data.totalPlanSecond : 0,
        );
      })
      .catch((err) => {
        console.error(err, "error");
        // toast(err.data?.message);
        // setIsLoading2(false);
      });
  };

  useEffect(() => {
    getRemainingtime();
  }, [props]);

  const getAllUsers = () => {
    GetData(
      `/skitiiai/get?search=${searchInput}&pageNumber=${pageNumber}&rowsLimit=${rowsLimit}&searchBy=${searchBy}`,
    )
      .then((response) => {
        console.log(response, "response");
        setAllUsers(response?.data.results.paginatedUsers);
        setTotalPage(response.data.results.totalPage);
      })
      .catch((err) => {
        console.error(err, "errpr");
      });
  };

  useEffect(() => {
    getAllUsers();
  }, [pageNumber, rowsLimit]);

  const handleTimeChange = (value) => {
    setNewTimeSeconds(value);
    setShowWarning(true);
  };

  const confirmTimeChange = () => {
    setTimeSeconds(newTimeSeconds);
    setShowWarning(false);
  };

  const updateDetails = () => {
    setIsLoading(true);
    AddData(`/skitiiai/update`, {
      name,
      email,
      phone,
      typeOfBusiness,
      CompanyName: companyName,
      timeSeconds,
      userid: props?.videoList?._id,
    })
      .then((response) => {
        toast(response.data.message);
        props.setDisplayUpdate(false);
        props?.getAllUsers();
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err, "error");
        toast(err.data?.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (showWarning) {
      Swal.fire({
        title: "Are you sure?",
        text: ` Are you sure you want to change the "Time in Seconds" value? This
            action might affect related configurations.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          confirmTimeChange();
        } else {
          setShowWarning(false);
        }
      });
    }
  }, [showWarning]);

  const addPlan = () => {
    if (timeSeconds === "") {
      return toast("Please select a Seconds.");
    }
    setIsLoading2(true);
    AddData(`/skitiiai/addPlan`, {
      planSecond: timeSeconds,
      userid: props?.videoList?._id,
    })
      .then((response) => {
        toast(response.data.message);
        setTimeSeconds("");
        setIsLoading2(false);
        getAllUsers();
        getRemainingtime();
      })
      .catch((err) => {
        console.error(err, "error");
        toast(err.data?.message);
        setIsLoading2(false);
      });
  };

  const [planData, setPlanData] = useState([]);
  useEffect(() => {
    const data = allUsers?.filter(
      (item) => item._id.toString() === props?.videoList?._id?.toString(),
    );

    setPlanData(data);
  }, [allUsers]);

  const formateDate = (date) => {
    const edate = new Date(date);

    const day = edate.getUTCDate();
    const month = edate.getUTCMonth() + 1;
    const year = edate.getUTCFullYear();

    const end = `${day < 10 ? "0" : ""}${day}-${
      month < 10 ? "0" : ""
    }${month}-${year}`;

    return end;
  };

  const [planSecond, setPlanSecond] = useState("");
  const getFreeTierData = () => {
    GetData(`/skitiiai/getFreetierSecond`)
      .then((response) => {
        console.log(response, "response");

        setPlanSecond(
          response?.data?.data[0].freetimeSeconds
            ? response?.data?.data[0].freetimeSeconds
            : "",
        );
      })
      .catch((err) => {
        console.error(err, "errpr");
      });
  };
  useEffect(() => {
    getFreeTierData();
  }, []);
  return (
    <div>
      <Typography variant="h4">User Details</Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: 550,
          gap: 10,
          marginTop: 15,
        }}
      >
        <TextField
          fullWidth
          label="Name"
          type="text"
          InputProps={{ inputProps: { min: 0, max: 99999 } }}
          value={name}
          onChange={(event) => {
            setName(event.target.value);
          }}
        />
        <TextField
          fullWidth
          label="Email"
          type="email"
          InputProps={{ inputProps: { min: 0, max: 99999 } }}
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        />
        <TextField
          fullWidth
          label="Phone"
          type="text"
          InputProps={{ inputProps: { min: 0, max: 99999 } }}
          value={phone}
          onChange={(event) => {
            setPhone(event.target.value);
          }}
        />
        <TextField
          fullWidth
          label="Company Name"
          type="text"
          InputProps={{ inputProps: { min: 0, max: 99999 } }}
          value={companyName}
          onChange={(event) => {
            setCompanyName(event.target.value);
          }}
        />
        <FormControl fullWidth>
          <InputLabel id="Type of Business-select">Type of Business</InputLabel>
          <Select
            labelId="Type of Business-select-label"
            id="Type of Business-select"
            label="Type of Business"
            value={typeOfBusiness}
            onChange={(event) => {
              setTypeOfBusiness(event.target.value);
            }}
          >
            <MenuItem value="individual">Individual</MenuItem>
            <MenuItem value="privateLimited">Private Limited</MenuItem>
            <MenuItem value="proprietor">Proprietor</MenuItem>
            <MenuItem value="llp">LLP</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="Total Purchased Seconds"
          type="text"
          InputProps={{ inputProps: { min: 0, max: 99999 } }}
          value={purchaseTimeData}
          onChange={(event) => {
            setPurchaseTimeData(event.target.value);
          }}
          disabled
        />
        <TextField
          fullWidth
          label="Total Viewer's Seconds"
          type="text"
          InputProps={{ inputProps: { min: 0, max: 99999 } }}
          value={viewTimeData}
          onChange={(event) => {
            setViewTimeData(event.target.value);
          }}
          disabled
        />{" "}
        <TextField
          fullWidth
          label="Remaining Seconds"
          type="text"
          InputProps={{ inputProps: { min: 0, max: 99999 } }}
          value={remainigTimeData}
          onChange={(event) => {
            setRemainigTimeData(event.target.value);
          }}
          disabled
        />{" "}
        {/* <FormControl fullWidth>
          <InputLabel id="Time in Second-select">Time in Second</InputLabel>
          <Select
            labelId="Time in Second"
            id="Time in Second-select"
            label="Time in Second"
            value={timeSeconds}
            // onChange={(event) => {
            //   setTimeSeconds(event.target.value);
            // }}
            onChange={(event) => handleTimeChange(event.target.value)}
          >
            <MenuItem value={120}>120</MenuItem>
            {Array.from(
              { length: (12000 - 300) / 300 + 1 },
              (_, index) => 300 * (index + 1),
            ).map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
      </div>
      <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
        <Button
          variant="contained"
          disabled={isLoading}
          onClick={() => updateDetails()}
        >
          Edit
        </Button>
        <Button
          variant="contained"
          disabled={isLoading}
          onClick={() => props.setDisplayUpdate(false)}
        >
          Cancel
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: 550,
          gap: 10,
          marginTop: 15,
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="Time in Second-select">Time in Second</InputLabel>
          <Select
            labelId="Time in Second"
            id="Time in Second-select"
            label="Time in Second"
            value={timeSeconds}
            // onChange={(event) => {
            //   setTimeSeconds(event.target.value);
            // }}
            onChange={(event) => handleTimeChange(event.target.value)}
          >
            <MenuItem value={planSecond ? planSecond : ""}>
              {planSecond ? planSecond : ""}
            </MenuItem>
            {Array.from(
              { length: (12000 - 300) / 300 + 1 },
              (_, index) => 300 * (index + 1),
            ).map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
        <Button
          variant="contained"
          disabled={isLoading2}
          onClick={() => addPlan()}
        >
          Add Plan
        </Button>
      </div>
      <TableContainer component={Paper} sx={{ marginTop: "3px", height: 440 }}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#333" }}>
              <TableCell sx={{ color: "white", textAlign: "center" }}>
                PlanSeconds
              </TableCell>
              <TableCell sx={{ color: "white", textAlign: "center" }}>
                Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {planData[0]?.PlanDetails?.map((row) => (
              <TableRow key={uuidv4()}>
                <TableCell sx={{ textAlign: "center" }}>
                  {row.planSecond}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {formateDate(row.date)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div
        style={{
          marginTop: "15px",
          display: "flex",
          justifyContent: "flex-end",
          gap: "30px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
          <Typography>Rows per page</Typography>
          <FormControl>
            <InputLabel id="demo-simple-select-label">Rows</InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={rowsLimit}
              label="Rows limit"
              onChange={(event) => {
                setRowsLimit(event.target.value);
                setPageNumber(1);
              }}
            >
              <MenuItem value="5">5</MenuItem>
              <MenuItem value="10">10</MenuItem>
              <MenuItem value="15">15</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div
          style={{
            display: "flex",
            gap: "0px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <SkipPreviousIcon
            onClick={(event) =>
              setPageNumber((pageNumber) =>
                pageNumber <= 1 ? 1 : --pageNumber,
              )
            }
          />
          <div style={{ minWidth: "50px", textAlign: "center" }}>
            {pageNumber} of {totalPage}
          </div>
          <SkipNextIcon
            onClick={(event) =>
              setPageNumber((pageNumber) =>
                pageNumber < totalPage ? ++pageNumber : totalPage,
              )
            }
          />
        </div>
      </div>
      {/* <Dialog open={showWarning}>
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to change the "Time in Seconds" value? This
            action might affect related configurations.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowWarning(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={confirmTimeChange} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog> */}
    </div>
  );
};

export default UpdateUser;
