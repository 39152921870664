import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ViewerDataDisplay from "./ViewerDataDisplay";
import ViewerWiseDataReport from "./AllViewerReport/ViewerWiseDataReport";
import ViewerWiseDataReport2 from "./AllViewerReport/ViewerWiseDataReport2";
import ViewerWiseDataReport3 from "./AllViewerReport/ViewerWiseDataReport3";
import ViewerWiseDataReport4 from "./AllViewerReport/ViewerWiseDataReport4";
import jsPDF from "jspdf";
import ReactDOM from "react-dom";
import html2canvas from "html2canvas";
import SecondPage from "./Reportlastpages/SecondPage";
import FirstPage from "./Reportlastpages/FirstPage";
import UserReportSummary from "./Reportlastpages/UserReportSummary";

const ViewUserList = (props) => {
  const { t } = useTranslation();
  const [category, setCategory] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isDisplayViewersData, setIsDisplayViewersData] = useState(false);
  const [viewerdata, setViewerdata] = useState();
  const [userData, setUserData] = useState();
  const [searchQuery, setSearchQuery] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    let filteredViewers = props?.data?.viewer || [];

    if (searchQuery !== "") {
      filteredViewers = filteredViewers?.filter((item) =>
        item.Name.toLowerCase().includes(searchQuery.toLowerCase()),
      );
    }

    if (category) {
      filteredViewers = filteredViewers.filter(
        (item) => item.Gender === category,
      );
    }
    setUserData(filteredViewers);
  }, [category, searchQuery, props]);

  const [pdfDownload, setPdfDownload] = useState(false);
  const handlePrintSingle = async (data, index) => {
    setPdfDownload(true);
    const pdf = new jsPDF("p", "mm", "a4"); // Create a new PDF document
    const imgWidth = 210; // Width of A4 page in mm
    const pageHeight = 297; // Height of A4 page in mm
    // const imgHeight = (canvas.height * imgWidth) / canvas.width;

    // Now render the table into a separate virtual div
    const tableElement4 = document.createElement("div");
    document.body.appendChild(tableElement4);

    // Render the table into its own div
    ReactDOM.render(
      <UserReportSummary
        data={props?.data}
        userData={userData}
        user={props?.user}
      />,
      tableElement4,
    );

    // Wait for the table to render
    await new Promise((resolve) => setTimeout(resolve, 3000));

    // Capture the table content into a canvas
    const tableCanvas4 = await html2canvas(tableElement4, { scale: 2 });

    // Get table image data
    const tableImgData4 = tableCanvas4.toDataURL("image/jpeg");

    let position4 = 0;
    const imgHeight4 = (tableCanvas4.height * imgWidth) / tableCanvas4.width;
    // Split the content into multiple pages if necessary
    while (position4 < imgHeight4) {
      pdf.addImage(
        tableImgData4,
        "JPEG",
        0,
        // 0,
        -position4,
        imgWidth,
        (tableCanvas4.height * imgWidth) / tableCanvas4.width,
      );

      position4 += pageHeight;

      // Add a new page if there is more content to render
      if (position4 < imgHeight4) {
        pdf.addPage();
      }
    }
    // Add the table to the next page
    // pdf.addImage(
    //   tableImgData3,
    //   "JPEG",
    //   0,
    //   0,
    //   imgWidth,
    //   (tableCanvas3.height * imgWidth) / tableCanvas3.width,
    // );

    pdf.addPage();

    const element = document.createElement("div");
    document.body.appendChild(element);

    // Render the component into the newly created element
    ReactDOM.render(
      <ViewerWiseDataReport
        data={props?.data}
        userData={userData}
        user={props?.user}
      />,
      element,
    );
    await new Promise((resolve) => setTimeout(resolve, 3000));
    // Use html2canvas to capture the rendered content
    const canvas = await html2canvas(element, {
      scale: 2, // Higher scale for better quality
    });

    const imgData = canvas.toDataURL("image/jpeg");

    let position = 0;

    // Split the content into multiple pages if necessary
    // while (position < imgHeight) {
    pdf.addImage(
      imgData,
      "JPEG",
      0,
      0,
      // -position,
      imgWidth,
      (canvas.height * imgWidth) / canvas.width,
    );

    // position += pageHeight;

    // Add a new page if there is more content to render
    // if (position < imgHeight) {
    //   pdf.addPage();
    // }
    // }

    pdf.addPage();

    // Now render the table into a separate virtual div
    const tableElement = document.createElement("div");
    document.body.appendChild(tableElement);

    // Render the table into its own div
    ReactDOM.render(
      <ViewerWiseDataReport2
        data={props?.data}
        userData={userData}
        user={props?.user}
      />,
      tableElement,
    );

    // Wait for the table to render
    await new Promise((resolve) => setTimeout(resolve, 3000));

    // Capture the table content into a canvas
    const tableCanvas = await html2canvas(tableElement, { scale: 2 });

    // Get table image data
    const tableImgData = tableCanvas.toDataURL("image/jpeg");

    // Add the table to the next page
    pdf.addImage(
      tableImgData,
      "JPEG",
      0,
      0,
      imgWidth,
      (tableCanvas.height * imgWidth) / tableCanvas.width,
    );

    pdf.addPage();

    // Now render the table into a separate virtual div
    const tableElement2 = document.createElement("div");
    document.body.appendChild(tableElement2);

    // Render the table into its own div
    ReactDOM.render(
      <ViewerWiseDataReport3
        data={props?.data}
        userData={userData}
        user={props?.user}
      />,
      tableElement2,
    );

    // Wait for the table to render
    await new Promise((resolve) => setTimeout(resolve, 3000));

    // Capture the table content into a canvas
    const tableCanvas2 = await html2canvas(tableElement2, { scale: 2 });

    // Get table image data
    const tableImgData2 = tableCanvas2.toDataURL("image/jpeg");
    let position2 = 0;
    const imgHeight2 = (tableCanvas2.height * imgWidth) / tableCanvas2.width;
    // Split the content into multiple pages if necessary
    while (position2 < imgHeight2) {
      pdf.addImage(
        tableImgData2,
        "JPEG",
        0,
        // 0,
        -position2,
        imgWidth,
        (tableCanvas2.height * imgWidth) / tableCanvas2.width,
      );

      position2 += pageHeight;

      // Add a new page if there is more content to render
      if (position2 < imgHeight2) {
        pdf.addPage();
      }
    }
    // Add the table to the next page
    // pdf.addImage(
    //   tableImgData2,
    //   "JPEG",
    //   0,
    //   0,
    //   imgWidth,
    //   (tableCanvas2.height * imgWidth) / tableCanvas2.width,
    // );

    pdf.addPage();

    // Now render the table into a separate virtual div
    const tableElement3 = document.createElement("div");
    document.body.appendChild(tableElement3);

    // Render the table into its own div
    ReactDOM.render(
      <ViewerWiseDataReport4
        data={props?.data}
        userData={userData}
        user={props?.user}
      />,
      tableElement3,
    );

    // Wait for the table to render
    await new Promise((resolve) => setTimeout(resolve, 3000));

    // Capture the table content into a canvas
    const tableCanvas3 = await html2canvas(tableElement3, { scale: 2 });

    // Get table image data
    const tableImgData3 = tableCanvas3.toDataURL("image/jpeg");

    let position3 = 0;
    const imgHeight3 = (tableCanvas3.height * imgWidth) / tableCanvas3.width;
    // Split the content into multiple pages if necessary
    while (position3 < imgHeight3) {
      pdf.addImage(
        tableImgData3,
        "JPEG",
        0,
        // 0,
        -position3,
        imgWidth,
        (tableCanvas3.height * imgWidth) / tableCanvas3.width,
      );

      position3 += pageHeight;

      // Add a new page if there is more content to render
      if (position3 < imgHeight3) {
        pdf.addPage();
      }
    }

    pdf.addPage();

    // Now render the table into a separate virtual div
    const tableElement5 = document.createElement("div");
    document.body.appendChild(tableElement5);

    // Render the table into its own div
    ReactDOM.render(<FirstPage />, tableElement5);

    // Capture the table content into a canvas
    const tableCanvas5 = await html2canvas(tableElement5, { scale: 2 });

    // Get table image data
    const tableImgData5 = tableCanvas5.toDataURL("image/jpeg");

    let position5 = 0;
    const imgHeight5 = (tableCanvas5.height * imgWidth) / tableCanvas5.width;
    // Split the content into multiple pages if necessary
    while (position5 < imgHeight5) {
      pdf.addImage(
        tableImgData5,
        "JPEG",
        0,
        // 0,
        -position5,
        imgWidth,
        (tableCanvas5.height * imgWidth) / tableCanvas5.width,
      );

      position5 += pageHeight;

      // Add a new page if there is more content to render
      if (position5 < imgHeight5) {
        pdf.addPage();
      }
    }

    pdf.addPage();

    // Now render the table into a separate virtual div
    const tableElement6 = document.createElement("div");
    document.body.appendChild(tableElement6);

    // Render the table into its own div
    ReactDOM.render(<SecondPage />, tableElement6);

    // Capture the table content into a canvas
    const tableCanvas6 = await html2canvas(tableElement6, { scale: 2 });

    // Get table image data
    const tableImgData6 = tableCanvas6.toDataURL("image/jpeg");

    let position6 = 0;
    const imgHeight6 = (tableCanvas5.height * imgWidth) / tableCanvas6.width;
    // Split the content into multiple pages if necessary
    while (position6 < imgHeight6) {
      pdf.addImage(
        tableImgData6,
        "JPEG",
        0,
        // 0,
        -position6,
        imgWidth,
        (tableCanvas6.height * imgWidth) / tableCanvas6.width,
      );

      position6 += pageHeight;

      // Add a new page if there is more content to render
      if (position6 < imgHeight6) {
        pdf.addPage();
      }
    }

    // pdf.addPage();

    // // Now render the table into a separate virtual div
    // const tableElement7 = document.createElement("div");
    // document.body.appendChild(tableElement7);

    // // Render the table into its own div
    // ReactDOM.render(<ThirdPage />, tableElement7);

    // // Capture the table content into a canvas
    // const tableCanvas7 = await html2canvas(tableElement7, { scale: 2 });

    // // Get table image data
    // const tableImgData7 = tableCanvas7.toDataURL("image/jpeg");

    // let position7 = 0;
    // const imgHeight7 = (tableCanvas7.height * imgWidth) / tableCanvas7.width;
    // // Split the content into multiple pages if necessary
    // while (position7 < imgHeight7) {
    //   pdf.addImage(
    //     tableImgData7,
    //     "JPEG",
    //     0,
    //     // 0,
    //     -position7,
    //     imgWidth,
    //     (tableCanvas7.height * imgWidth) / tableCanvas7.width,
    //   );

    //   position7 += pageHeight;

    //   // Add a new page if there is more content to render
    //   if (position7 < imgHeight7) {
    //     pdf.addPage();
    //   }
    // }

    document.body.removeChild(element);
    document.body.removeChild(tableElement);
    document.body.removeChild(tableElement2);
    document.body.removeChild(tableElement3);
    document.body.removeChild(tableElement4);
    document.body.removeChild(tableElement5);
    document.body.removeChild(tableElement6);
    // document.body.removeChild(tableElement7);

    // Save the PDF
    pdf.save(`${props?.user?.CompanyName}.pdf`);
    setPdfDownload(false);
  };

  return (
    <>
      {isDisplayViewersData && (
        <ViewerDataDisplay
          setIsDisplayViewersData={setIsDisplayViewersData}
          data={props?.data}
          viewerdata={viewerdata}
          user={props?.user}
        />
      )}
      <div>
        {!isDisplayViewersData && (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div></div>
              <div
                style={{ display: "flex", gap: "10px", marginBottom: "10px" }}
              >
                <TextField
                  size="small"
                  label="Search By User Name.."
                  inputProps={{
                    autoComplete: "new-password",
                  }}
                  value={searchQuery}
                  onChange={(event) => setSearchQuery(event.target.value)}
                />
                <Button
                  variant="contained"
                  size="small"
                  onClick={props?.getAllUsers}
                >
                  Filter
                </Button>
              </div>
            </div>
            {props?.category === "Cumulative Data" && (
              <button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: 10,
                  color: "white",
                  backgroundColor: pdfDownload ? "gray" : "#04226d",
                  borderRadius: 14,
                  margin: 10,
                }}
                // className={`flex flex-col justify-center rounded-xl items-center p-5 text-white ${pdfDownload ? "bg-gray-300" : "bg-primary"}`}
                onClick={() => {
                  if (pdfDownload) {
                    return;
                  }
                  handlePrintSingle();
                }}
              >
                {pdfDownload ? "Downloading..." : "Download Report"}
              </button>
            )}
            <div className="justify-center items-center flex">
              <FormControl>
                <FormLabel> </FormLabel>
                <RadioGroup
                  row
                  aria-label="category"
                  name="category"
                  value={category}
                  onChange={(event) => {
                    setCategory(event.target.value);
                  }}
                >
                  <FormControlLabel
                    value="Male"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="Female"
                    control={<Radio />}
                    label="Female"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <TableContainer sx={{ maxHeight: 440 }} component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow sx={{ backgroundColor: "#333" }}>
                      <TableCell sx={{ color: "white", textAlign: "center" }}>
                        Name
                      </TableCell>
                      <TableCell sx={{ color: "white", textAlign: "center" }}>
                        Gender
                      </TableCell>
                      {/* <TableCell sx={{ color: "white", textAlign: "center" }}>
                      
                        Views
                      
                    </TableCell> */}
                      <TableCell sx={{ color: "white", textAlign: "center" }}>
                        Viewer's Emotion Data
                      </TableCell>
                      {/* <TableCell>
                    <h1 className="font-bold text-white text-center text-sm md:text-xl">
                      Name
                    </h1>
                  </TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      // props?.data?.viewer
                      userData
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                        ?.map((row, index) => {
                          return (
                            <TableRow
                              hover
                              tabIndex={-1}
                              key={index}
                              // onClick={() => handleRowClick(row._id)}
                            >
                              <TableCell sx={{ textAlign: "center" }}>
                                {row?.Name}
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                {row?.Gender}
                              </TableCell>
                              {/* <TableCell sx={{  textAlign: "center" }}>
                          {formatViews(row?.viewer?.length) || 0}
                        </TableCell> */}
                              <TableCell sx={{ textAlign: "center" }}>
                                <Button
                                  variant="contained"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setIsDisplayViewersData(true);
                                    setViewerdata(row);
                                    setCategory();
                                  }}
                                >
                                  <RemoveRedEyeIcon
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setIsDisplayViewersData(true);
                                      setViewerdata(row);
                                      setCategory();
                                    }}
                                  />
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={userData?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                // sx={{ backgroundColor: "#F3F5F8" }}
              />
            </div>
            <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
              <Button
                variant="contained"
                disabled={isLoading}
                onClick={() => props.setIsViewUserDisplay(false)}
              >
                {t("Cancel")}
              </Button>
            </div>
          </>
        )}
      </div>
      {/* <UserReportSummary
        data={props?.data}
        userData={userData}
        user={props?.user}
      />
      <ViewerWiseDataReport
        data={props?.data}
        userData={userData}
        user={props?.user}
      />
      <ViewerWiseDataReport2
        data={props?.data}
        userData={userData}
        user={props?.user}
      />
      <ViewerWiseDataReport3
        data={props?.data}
        userData={userData}
        user={props?.user}
      />
      <ViewerWiseDataReport4
        data={props?.data}
        userData={userData}
        user={props?.user}
      />
      <FirstPage />
      <SecondPage /> */}
    </>
  );
};

export default ViewUserList;
