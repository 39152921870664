import React, { useEffect, useState } from "react";
import { AddData, GetData } from "../../api/GeneralFile";
import { Button, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";

const SetFreeTierPlan = () => {
  const [planSecond, setPlanSecond] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const getFreeTierData = () => {
    GetData(`/skitiiai/getFreetierSecond`)
      .then((response) => {
        console.log(response, "response");

        setPlanSecond(
          response?.data?.data[0].freetimeSeconds
            ? response?.data?.data[0].freetimeSeconds
            : "",
        );
      })
      .catch((err) => {
        console.error(err, "errpr");
      });
  };
  useEffect(() => {
    getFreeTierData();
  }, []);

  const setFreeTierData = () => {
    setIsLoading(true);
    AddData(`/skitiiai/setFreetierSecond`, { planSecond: planSecond })
      .then((response) => {
        console.log(response, "response");
        toast.success("Added Successfully");
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err, "errpr");
        setIsLoading(false);
      });
  };

  return (
    <div style={{ marginTop: "25px" }}>
      <Typography variant="h4">Free Tier Second Details</Typography>
      <TextField
        fullWidth
        label="Free Tier Seconds"
        type="text"
        InputProps={{ inputProps: { min: 0, max: 99999 } }}
        value={planSecond}
        onChange={(event) => {
          setPlanSecond(event.target.value);
        }}
        sx={{ marginTop: "25px" }}
      />{" "}
      <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
        <Button
          variant="contained"
          disabled={isLoading}
          onClick={() => setFreeTierData()}
        >
          Add Data
        </Button>
      </div>
    </div>
  );
};

export default SetFreeTierPlan;
