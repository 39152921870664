import React, { useEffect, useState } from "react";

const ConclusionRecommendationsComulutive = (props, { processData2 }) => {
  const Feeling96 = [
    { emotion: "Happy", x: 0.8, y: 0.7 },
    { emotion: "Joyful", x: 0.9, y: 0.8 },
    { emotion: "Excited", x: 0.7, y: 0.9 },
    { emotion: "Content", x: 0.6, y: 0.4 },
    { emotion: "Proud", x: 0.7, y: 0.6 },
    { emotion: "Grateful", x: 0.6, y: 0.5 },
    { emotion: "Surprise", x: 0.5, y: 0.7 },
    { emotion: "Love", x: 0.9, y: 0.6 },
    { emotion: "Amused", x: 0.7, y: 0.6 },
    { emotion: "Elated", x: 0.8, y: 0.8 },
    { emotion: "Optimistic", x: 0.7, y: 0.5 },
    { emotion: "Hopeful", x: 0.7, y: 0.4 },
    { emotion: "Cheerful", x: 0.7, y: 0.6 },
    { emotion: "Confident", x: 0.6, y: 0.5 },
    { emotion: "Affectionate", x: 0.8, y: 0.5 },
    { emotion: "Calm", x: 0.5, y: 0.2 },
    { emotion: "Peaceful", x: 0.6, y: 0.3 },
    { emotion: "Relaxed", x: 0.7, y: 0.3 },
    { emotion: "Satisfied", x: 0.6, y: 0.4 },
    { emotion: "Tender", x: 0.6, y: 0.3 },
    { emotion: "Contented", x: 0.7, y: 0.3 },
    { emotion: "Pleased", x: 0.6, y: 0.3 },
    { emotion: "Quiet", x: 0.5, y: 0.2 },
    { emotion: "Neutral", x: 0.5, y: 0.5 },
    { emotion: "Bored", x: -0.2, y: 0.3 },
    { emotion: "Anxious", x: -0.5, y: 0.7 },
    { emotion: "Sad", x: -0.7, y: 0.4 },
    { emotion: "Disappointed", x: -0.6, y: 0.5 },
    { emotion: "Irritated", x: -0.6, y: 0.6 },
    { emotion: "Lonely", x: -0.6, y: 0.5 },
    { emotion: "Tired", x: -0.2, y: 0.3 },
    { emotion: "Confused", x: -0.4, y: 0.5 },
    { emotion: "Nervous", x: -0.4, y: 0.6 },
    { emotion: "Frustrated", x: -0.6, y: 0.7 },
    { emotion: "Guilty", x: -0.7, y: 0.5 },
    { emotion: "Ashamed", x: -0.8, y: 0.5 },
    { emotion: "Hopeless", x: -0.8, y: 0.6 },
    { emotion: "Fear", x: -0.7, y: 0.8 },
    { emotion: "Horrified", x: -0.9, y: 0.9 },
    { emotion: "Disgust", x: -0.8, y: 0.7 },
    { emotion: "Embarrassed", x: -0.7, y: 0.6 },
    { emotion: "Angry", x: -0.8, y: 0.8 },
    { emotion: "Enraged", x: -0.9, y: 0.9 },
    { emotion: "Vengeful", x: -0.9, y: 0.8 },
    { emotion: "Miserable", x: -0.9, y: 0.7 },
    { emotion: "Resentful", x: -0.8, y: 0.7 },
    { emotion: "Depressed", x: -0.9, y: 0.5 },
    { emotion: "Terrified", x: -0.9, y: 0.9 },
    // { emotion: "Horrified", x: -0.9, y: 0.8 },
    { emotion: "HelpLess", x: -0.8, y: 0.5 },
    { emotion: "Worried", x: -0.6, y: 0.6 },
    { emotion: "Grief-stricken", x: -0.8, y: 0.5 },
    { emotion: "Shocked", x: -0.5, y: 0.7 },
    // { emotion: "Shocked", x: -0.6, y: 0.7 },
    { emotion: "Regretful", x: -0.6, y: 0.5 },
    { emotion: "Frightened", x: -0.7, y: 0.7 },
    { emotion: "Insecure", x: -0.5, y: 0.4 },
    //  {emotion: "Hopeless",x: -0.7,y: 0.6},
    // { emotion: "Depressed", x: -0.8, y: 0.5 },
    { emotion: "Uncertain", x: -0.4, y: 0.5 },
    // { emotion: "Jealous", x: -0.6, y: 0.6 },
    { emotion: "Uncomfortable", x: -0.5, y: 0.6 },
    { emotion: "Unhappy", x: -0.7, y: 0.6 },
    { emotion: "Agitated", x: -0.7, y: 0.8 },
    { emotion: "Displeased", x: -0.5, y: 0.5 },
    { emotion: "Vulnerable", x: -0.6, y: 0.5 },
    { emotion: "Pessimistic", x: -0.6, y: 0.4 },
    { emotion: "Horrible", x: -0.9, y: 0.8 },
    { emotion: "Bewildered", x: -0.6, y: 0.6 },
    // { emotion: "Angry", x: -0.8, y: 0.9 },
    // { emotion: "Disappointed", x: -0.6, y: 0.6 },
    { emotion: "Defeated", x: -0.7, y: 0.5 },
    { emotion: "Indifferent", x: -0.4, y: 0.4 },
    { emotion: "Hurt", x: -0.7, y: 0.5 },
    { emotion: "Afraid", x: -0.7, y: 0.6 },
    { emotion: "Apprehensive", x: -0.5, y: 0.5 },
    { emotion: "Grief", x: -0.8, y: 0.7 },
    { emotion: "Overwhelmed", x: -0.7, y: 0.8 },
    { emotion: "Tense", x: -0.5, y: 0.6 },
    { emotion: "Resentment", x: -0.7, y: 0.6 },
    // Lonely -0.6 0.5
    { emotion: "Rejected", x: -0.7, y: 0.6 },
    // Hopeless -0.9 0.7
    { emotion: "Shameful", x: -0.8, y: 0.6 },
    { emotion: "Disheartened", x: -0.7, y: 0.5 },
    { emotion: "Deflated", x: -0.6, y: 0.5 },
    { emotion: "Stress", x: -0.7, y: 0.8 },
    { emotion: "Worry", x: -0.6, y: 0.7 },
    { emotion: "Concern", x: -0.2, y: 0.5 },
    { emotion: "Anxiety", x: -0.8, y: 0.9 },
    { emotion: "Upset", x: -0.7, y: 0.7 },
    // { emotion: "Agitated", x: -0.6, y: 0.8 },
    { emotion: "Humiliated", x: -0.9, y: 0.7 },
    { emotion: "Overconfident", x: 0.5, y: 0.6 },
    // { emotion: "Depressed", x: -0.9, y: 0.3 },
    { emotion: "Bothered", x: -0.5, y: 0.6 },
    // { emotion: "Jealous", x: -0.7, y: 0.7 },
    { emotion: "Timid", x: -0.4, y: 0.4 },
    { emotion: "Chilled", x: 0.6, y: 0.2 },
    // { emotion: "Excited", x: 0.8, y: 0.9 },
  ];

  const getXValue = (emotionName) => {
    const found = Feeling96.find((feeling) => feeling.emotion === emotionName);
    return found ? found.x : null; // Return null if emotion is not found
  };
  const getYValue = (emotionName) => {
    const found = Feeling96.find((feeling) => feeling.emotion === emotionName);
    return found ? found.y : null; // Return null if emotion is not found
  };
  const calculateValenceArousal = (data) => {
    console.log(data);
    return data.map((entry, index) => {
      const { emotion_confidences } = entry;
      console.log(emotion_confidences);
      const valence =
        emotion_confidences &&
        Object.keys(emotion_confidences).reduce((sum, emotion) => {
          return sum + emotion_confidences[emotion] * (getXValue(emotion) || 0);
        }, 0);
      console.log(valence);
      const arousal =
        emotion_confidences &&
        Object.keys(emotion_confidences).reduce((sum, emotion) => {
          return sum + emotion_confidences[emotion] * (getYValue(emotion) || 0);
        }, 0);

      return {
        ...entry,
        valence: valence ? valence : 0,
        arousal: arousal ? arousal : 0,
      };
    });
  };

  const calculateAverageEmotionConfidences = (viewerData) => {
    const numSeconds = viewerData[0].attentionData[0].length; // Number of seconds is the same for all viewers
    const averageData = [];
    const defaultEmotionConfidences = {
      Angry: 0,
      Disgust: 0,
      Fear: 0,
      Happy: 0,
      Neutral: 0,
      Sad: 0,
      Surprise: 0,
    };

    for (let secondIndex = 0; secondIndex < numSeconds; secondIndex++) {
      const emotionConfidences = {};

      viewerData.forEach((viewer) => {
        const entry = viewer.attentionData[0][secondIndex];
        const emotionConfidencesFromEntry = entry?.emotion_confidences
          ? entry.emotion_confidences
          : defaultEmotionConfidences;
        for (const emotion in emotionConfidencesFromEntry) {
          if (!emotionConfidences[emotion]) {
            emotionConfidences[emotion] = [];
          }
          emotionConfidences[emotion].push(
            emotionConfidencesFromEntry[emotion],
          );
        }
      });

      const averageEmotionConfidences = {};
      let maxEmotion = "";
      let maxConfidence = -Infinity;

      for (const emotion in emotionConfidences) {
        const values = emotionConfidences[emotion];
        const averageValue =
          values.reduce((sum, value) => sum + value, 0) / values.length;
        averageEmotionConfidences[emotion] = averageValue.toFixed(2);

        if (averageValue > maxConfidence) {
          maxConfidence = averageValue;
          maxEmotion = emotion;
        }
      }

      averageData.push({
        emotion: maxEmotion,
        emotion_confidences: averageEmotionConfidences,
      });
    }

    return averageData;
  };

  const [closeEmotion, setCloseEmotion] = useState([]);

  useEffect(() => {
    const averageData =
      props?.data?.viewer &&
      calculateAverageEmotionConfidences(props?.data?.viewer);
    const result = calculateValenceArousal(averageData);
    // const result =
    //   props?.data?.viewer && calculateAverageForAllViewers(props?.data?.viewer);

    const closestEmotions = [];
    const getEmotion = (V_computed, A_computed, oldEmotion, faceData) => {
      const distances = Feeling96.map(({ emotion, x, y }) => {
        const distance = Math.sqrt(
          Math.pow(V_computed - x, 2) + Math.pow(A_computed - y, 2),
        );
        return {
          emotion,
          distance,
          oldEmotion,
          faceData,
          V_computed,
          A_computed,
        };
      });

      console.log("Distances:", distances);

      // Example: Find the closest emotion
      const closestEmotion = distances.reduce((prev, curr) =>
        prev.distance < curr.distance ? prev : curr,
      );

      console.log("Closest Emotion:", closestEmotion);
      closestEmotions.push(closestEmotion);
      // setCloseEmotion((prev) => [...prev, closestEmotion]);
      // return closestEmotion;
    };
    console.log(result);
    result?.map((item, index) =>
      // Emotion: {item?.emotion}, Valence: {item?.valence?.toFixed(3)},
      // Arousal: {item?.arousal?.toFixed(3)}
      // Close Emotion:{" "}
      getEmotion(
        item?.valence?.toFixed(3),
        item?.arousal?.toFixed(3),
        item?.emotion,
        item?.num_faces,
      ),
    );

    setCloseEmotion(closestEmotions);
  }, [props]);

  // // console.log("Closest Emotion:", closestEmotion);

  const processEmotionData = (data) => {
    const result = {
      "High Power/Control": {},
      Obstructive: {},
      Conductive: {},
      "Low Power/Control": {},
    };
    data.forEach((item) => {
      const v = parseFloat(item.V_computed);
      const a = parseFloat(item.A_computed);
      const emotion =
        item.faceData === 0 ? `No Face` : item.emotion || "Unknown";
      if ((v > 0 && a > 0) || v === 0.0 || a === 0.0) {
        result["High Power/Control"][emotion] =
          (result["High Power/Control"][emotion] || 0) + 1;
      } else if (v > 0 && a < 0) {
        result["Obstructive"][emotion] =
          (result["Obstructive"][emotion] || 0) + 1;
      } else if (v < 0 && a > 0) {
        result["Conductive"][emotion] =
          (result["Conductive"][emotion] || 0) + 1;
      } else if (v < 0 && a < 0) {
        result["Low Power/Control"][emotion] =
          (result["Low Power/Control"][emotion] || 0) + 1;
      }
    });

    return result;
  };
  const [value, setValue] = useState("");
  const [condHigh, setCondHigh] = useState([]);
  const [obstLow, setObstLow] = useState([]);

  const sumValues = (obj) => Object.values(obj).reduce((a, b) => a + b, 0);

  // Function to merge emotion counts and concatenate
  const aggregateEmotions = (categories) => {
    const emotionCounts = {};

    // Iterate over all categories
    categories.forEach((category) => {
      // Iterate over each emotion in the category
      Object.entries(category).forEach(([emotion, count]) => {
        if (emotionCounts[emotion]) {
          emotionCounts[emotion] += count; // Add count if emotion already exists
        } else {
          emotionCounts[emotion] = count; // Initialize count if emotion is new
        }
      });
    });

    return emotionCounts;
  };

  // Function to format and concatenate emotions
  const concatenateEmotions = (aggregatedData) => {
    const emotions = Object.entries(aggregatedData).map(([emotion, count]) => {
      return { emotion, count };
    });
    return emotions;
  };

  const getTopThreeEmotions = (emotionsArray) => {
    // Sort emotions by count in descending order
    const sortedEmotions = emotionsArray.sort((a, b) => b.count - a.count);

    // Select the top three
    const topThree = sortedEmotions.slice(0, 3);

    return topThree;
  };

  useEffect(() => {
    if (closeEmotion?.length > 0) {
      const processData = processEmotionData(closeEmotion);
      console.log(processData);
      const totalConductiveHighPower =
        sumValues(processData?.Conductive) +
        sumValues(processData["High Power/Control"]);
      const totalLowPowerObstructive =
        sumValues(processData["Low Power/Control"]) +
        sumValues(processData.Obstructive);

      setValue(
        totalConductiveHighPower > totalLowPowerObstructive
          ? "Positive"
          : totalConductiveHighPower < totalLowPowerObstructive
            ? "Negative"
            : "Neutral",
      );

      // Aggregate the emotions from both Conductive and High Power/Control
      const aggregatedEmotions = aggregateEmotions([
        processData.Conductive,
        processData["High Power/Control"],
      ]);
      const aggregatedEmotions2 = aggregateEmotions([
        processData.Obstructive,
        processData["Low Power/Control"],
      ]);

      // Final concatenated result with summed counts
      const finalConcatenatedEmotions =
        aggregatedEmotions === ""
          ? []
          : concatenateEmotions(aggregatedEmotions);
      console.log(aggregatedEmotions, finalConcatenatedEmotions);
      const finalConcatenatedEmotions2 =
        aggregatedEmotions2 === ""
          ? []
          : concatenateEmotions(aggregatedEmotions2);
      console.log(aggregatedEmotions2, finalConcatenatedEmotions2);

      // Get the top three emotions
      const topThreeEmotions = getTopThreeEmotions(finalConcatenatedEmotions);

      setCondHigh(topThreeEmotions);

      // Get the top three emotions
      const topThreeEmotions2 = getTopThreeEmotions(finalConcatenatedEmotions2);

      setObstLow(topThreeEmotions2);
    }
  }, [closeEmotion]);

  return (
    <div className="flex flex-col  px-12 py-5">
      <div className=" mx-auto p-8">
        <h1 className="text-3xl font-bold mb-4">
          Conclusion & Recommendations
        </h1>
        <section className="mb-6 flex flex-row">
          <h2 className="text-2xl font-semibold ">
            Overall Impression:{" "}
            <span
              className={`text-2xl font-normal ${value === "Positive" ? "text-green-600" : "text-red-600"}`}
            >
              {value}
            </span>
          </h2>
        </section>
        <section className="mb-6 flex flex-row">
          <h2 className="text-2xl font-semibold ">
            Key Strengths:{" "}
            <span className="text-2xl font-normal">
              {" "}
              {condHigh?.length > 0
                ? condHigh
                    .filter((emotion) => emotion.emotion !== "No Face")
                    .map((emotion) => `${emotion.emotion}`)
                    .join(", ")
                : "Not Any Emotions Detected"}
            </span>
          </h2>
        </section>
        <section className="mb-6 flex flex-row">
          <h2 className="text-2xl font-semibold ">
            Key Areas of Concern:{" "}
            <span className="text-2xl font-normal">
              {" "}
              {obstLow?.length > 0
                ? obstLow
                    .filter((emotion) => emotion.emotion !== "No Face")
                    .map((emotion) => `${emotion.emotion}`)
                    .join(", ")
                : "Not Any Emotions Detected"}
            </span>
          </h2>
        </section>
        <section className="mb-6 flex flex-row">
          <h2 className="text-2xl font-semibold ">
            Final Recommendation:{" "}
            <span className="text-2xl font-normal">
              {props?.type === "indivisual"
                ? value === "Positive"
                  ? "Proceed with hiring/investment discussion"
                  : "Reconsider"
                : value === "Positive"
                  ? "Proceed with content optimization"
                  : "Reconsider content approach any one"}
            </span>
          </h2>
        </section>
        <footer className="mt-6 text-2xl border-t pt-4">
          <p className="text-2xl">
            <strong className="text-2xl">Prepared by:</strong> Skitii
          </p>
          <p className="text-2xl">
            <strong className="text-2xl">Reviewed by:</strong>{" "}
            __________________
          </p>
          <p className="text-2xl">
            <strong className="text-2xl">Date of Report:</strong>{" "}
            {new Date().toLocaleDateString()}
          </p>
        </footer>
        <section className="mb-6 mt-5 flex flex-row">
          <em className="text-xl text-gray-400 ">
            Disclaimer: This report is generated based on computer vision AI
            analysis in the given context. Individuals and organizations are
            encouraged to exercise their own discretion and validate the
            findings with their independent assessments. Skitii assumes no
            liability for any outcomes or deceisions made based on this report.
          </em>
        </section>
      </div>
    </div>
  );
};

export default ConclusionRecommendationsComulutive;
