import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import loadingGIF from "../../../assets/images/loading-gif.gif";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ViewEQScoreChart from "./ViewEQScoreChart";
import PropTypes from "prop-types";
import * as XLSX from "xlsx";
import { FaSort } from "react-icons/fa";
import { GetData } from "../../../api/GeneralFile";
import { useSelector } from "react-redux";

const XLSXDownload = (props) => {
  const handleDownload = () => {
    const wb = XLSX.utils.book_new();

    const sheetData = props
      ? props.data.map((item) => {
          return props.category === "Gfo Exam"
            ? {
                Name: item?.participant?.name || "-",
                SchoolName: item?.school?.schoolName || "-",
                Class: item?.participant?.grade || "-",
                PhoneNo: item?.participant?.phoneNo || "-",
                "Average EQScore":
                  item?.averagePoints > 100
                    ? "77.5"
                    : item?.averagePoints.toFixed(2),
              }
            : {
                Name: item?.participant?.Name || "-",
                SchoolName: item?.participant?.Institute || "-",
                Class: item?.participant?.exam?.ename
                  ? item?.participant?.exam?.ename
                  : "-",
                PhoneNo: item?.participant?.Phone || "-",
                "Average EQScore":
                  item?.averagePoints > 100
                    ? "77.5"
                    : item?.averagePoints.toFixed(2),
              };
        })
      : null;

    const ws = XLSX.utils.json_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(wb, ws, props.name);
    XLSX.writeFile(wb, `${props.name}` + ".xlsx");
  };
  XLSXDownload.propTypes = {
    data: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    school: PropTypes.object.isRequired,
    category: PropTypes.string.isRequired,
  };
  return (
    <Button variant="contained" size="small" onClick={handleDownload}>
      Export file as excel sheet
    </Button>
  );
};

const EQQuestionScore = () => {
  const [searchInput, setSearchInput] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsLimit, setRowsLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchBy, setSearchBy] = useState("Name");
  const [eQScoreData, setEQScoreData] = useState([]);
  const [allEQScoreData, setAllEQScoreData] = useState([]);
  const [category, setCategory] = useState("Gfo Practice");
  const [standard, setStandard] = useState("");
  const [displayViewChart, setDisplayViewChart] = useState(false);
  const [userToBeUpdated, setUserToBeUpdated] = useState();
  const { t } = useTranslation();
  const email = useSelector((state) => state.InsEmail);

  const searchData = () => {
    setIsLoading(true);
    GetData(
      `/downloads/getallEqScore?standard=${standard}&category=${category}&searchBy=${searchBy}&search=${searchInput}&pageNumber=${pageNumber}&rowsLimit=${rowsLimit}&email=${email}`,
    )
      .then((response) => {
        console.log(response, "response");
        setIsLoading(false);
        setEQScoreData(response.data.results.EQScoreData);
        setTotalPage(response.data.results.totalPage);
        setAllEQScoreData(response.data.data);
      })
      .catch((err) => {
        console.error(err, "errpr");
        toast(err.data?.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    searchData();
  }, [pageNumber, rowsLimit, category, standard]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setSearchInput(event.target.value);
      searchData();
    }
  };

  const classItems = [
    { key: "1", value: "1st", text: "1st" },
    { key: "2", value: "2nd", text: "2nd" },
    { key: "3", value: "3rd", text: "3rd" },
    { key: "4", value: "4th", text: "4th" },
    { key: "5", value: "5th", text: "5th" },
    { key: "6", value: "6th", text: "6th" },
    { key: "7", value: "7th", text: "7th" },
    { key: "8", value: "8th", text: "8th" },
    { key: "9", value: "9th", text: "9th" },
    { key: "10", value: "10th", text: "10th" },
    { key: "11", value: "11th", text: "11th" },
    { key: "12", value: "12th", text: "12th" },
  ];

  const [sortOrder, setSortOrder] = useState("asc");
  const sortByColumn = (columnName) => {
    const sortComparator = (key, order) => (a, b) => {
      // console.log(a, b);
      const comparison =
        (category === "Gfo Exam"
          ? a?.school?.schoolName || "-"
          : a?.participant?.Institute || "-") >
        (category === "Gfo Exam"
          ? b?.school?.schoolName || "-"
          : b?.participant?.Institute || "-")
          ? 1
          : -1;
      return order === "asc" ? comparison : -comparison;
    };

    if (columnName === "firstName") {
      const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
      const sortedUsers = allEQScoreData
        .slice()
        .sort(sortComparator(columnName, newSortOrder));
      setSortOrder(newSortOrder);
      setEQScoreData(sortedUsers);
      setAllEQScoreData(sortedUsers);
    }
  };

  return (
    <>
      {displayViewChart && (
        <ViewEQScoreChart
          setDisplayViewChart={setDisplayViewChart}
          user={userToBeUpdated}
          category={category}
        />
      )}
      {!displayViewChart && (
        <>
          {" "}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", gap: "5px" }}>
              <RadioGroup
                row
                aria-label="category"
                name="category"
                value={category}
                onChange={(event) => {
                  setCategory(event.target.value);
                }}
              >
                <FormControlLabel
                  value="Gfo Practice"
                  control={<Radio />}
                  label="Gfo Practice"
                />
                {/* <FormControlLabel
                  value="Gfo Exam"
                  control={<Radio />}
                  label="Gfo Exam"
                /> */}
              </RadioGroup>
            </div>
            <XLSXDownload
              data={allEQScoreData}
              name={`${category}_Students`}
              category={category}
            />
            <div style={{ display: "flex", gap: "10px" }}>
              <FormControl>
                <InputLabel id="select-label">{t("Searchby")}</InputLabel>
                <Select
                  labelId="select-label"
                  id="select"
                  label="Search by"
                  size="small"
                  value={searchBy}
                  onChange={(event) => setSearchBy(event.target.value)}
                >
                  <MenuItem value="Name">{t("Name")}</MenuItem>
                  <MenuItem value="Institute">Institute Name</MenuItem>
                  <MenuItem value="Phone">Phone No</MenuItem>
                </Select>
              </FormControl>
              <form noValidate>
                <TextField
                  size="small"
                  label={t("Searchhere")}
                  value={searchInput}
                  autoComplete="off"
                  onChange={(event) => setSearchInput(event.target.value)}
                  onKeyDown={handleKeyPress}
                />
              </form>
              <Button
                variant="contained"
                size="small"
                onClick={() => setSearchInput("")}
              >
                {t("Clear")}
              </Button>
              <Button variant="contained" size="small" onClick={searchData}>
                {t("Filter")}
              </Button>
            </div>
          </div>
          <div>
            <RadioGroup
              row
              aria-label="category"
              name="category"
              value={standard}
              onChange={(event) => {
                setStandard(event.target.value);
              }}
            >
              <FormControlLabel value="" control={<Radio />} label="All" />
              {classItems.map((item) => (
                <FormControlLabel
                  key={item.key}
                  value={item.value}
                  control={<Radio />}
                  label={item.text}
                />
              ))}
            </RadioGroup>
          </div>
          {isLoading ? (
            <img
              src={loadingGIF}
              alt="loading"
              style={{
                width: "50px",
                height: "50px",
                margin: "0 auto",
                marginTop: "50px",
              }}
            />
          ) : (
            <>
              <TableContainer
                component={Paper}
                sx={{ marginTop: "5px", height: 440 }}
              >
                <Table size="small">
                  <TableHead sx={{ backgroundColor: "#333", color: "#fff" }}>
                    <TableRow>
                      <TableCell sx={{ color: "#fff" }}>{t("Name")}</TableCell>

                      <TableCell sx={{ color: "#fff" }}>
                        {t("PhoneNumber")}
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }}>Grade</TableCell>
                      <TableCell sx={{ color: "#fff" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          Institute Name
                          <FaSort
                            style={{
                              padding: "5px",
                              backgroundColor: "#ffffff",
                              color: "#000000",
                              fontSize: "18px",
                            }}
                            onClick={(event) => sortByColumn("firstName")}
                          />
                        </div>
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }}>EQ Score</TableCell>

                      <TableCell sx={{ color: "#fff" }}>
                        {t("Actions")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {eQScoreData?.map((item, index) => (
                      <TableRow key={item._id}>
                        <TableCell>
                          {category === "Gfo Exam"
                            ? item?.participant?.name || "-"
                            : item?.participant?.Name || "-"}
                        </TableCell>
                        <TableCell>
                          {category === "Gfo Exam"
                            ? item?.participant?.phoneNo || "-"
                            : item?.participant?.Phone || "-"}
                        </TableCell>
                        <TableCell>
                          {category === "Gfo Exam"
                            ? item?.participant?.grade || "-"
                            : item?.participant?.exam?.ename
                              ? item?.participant?.exam?.ename
                              : "-"}
                        </TableCell>
                        <TableCell>
                          {category === "Gfo Exam"
                            ? item?.school?.schoolName || "-"
                            : item?.participant?.Institute || "-"}
                        </TableCell>
                        <TableCell>
                          {item?.averagePoints.toFixed(2) > 100
                            ? "77.5"
                            : item?.averagePoints.toFixed(2)}
                        </TableCell>
                        <TableCell>
                          {/* <Button
                            size="small"
                            variant="contained"
                            sx={{ marginRight: "5px" }}
                            onClick={(event) => {
                              setUserToBeUpdated((userToBeUpdated) => user);
                              setDisplayUpdate(true);
                            }}
                          >
                            {" "}
                            <EditIcon />
                          </Button> */}
                          <Button
                            size="small"
                            variant="contained"
                            sx={{ marginRight: "5px" }}
                            onClick={(event) => {
                              setUserToBeUpdated(item);
                              setDisplayViewChart(true);
                            }}
                          >
                            {" "}
                            <RemoveRedEyeIcon />{" "}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <div
                style={{
                  marginTop: "15px",
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "30px",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "15px" }}
                >
                  <Typography>{t("RPP")}</Typography>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">
                      {t("Rows")}
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={rowsLimit}
                      label="Rows limit"
                      onChange={(event) => {
                        setRowsLimit(event.target.value);
                        setPageNumber(1);
                      }}
                    >
                      <MenuItem value="5">5</MenuItem>
                      <MenuItem value="10">10</MenuItem>
                      <MenuItem value="15">15</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "0px",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <SkipPreviousIcon
                    onClick={(event) =>
                      setPageNumber((pageNumber) =>
                        pageNumber <= 1 ? 1 : --pageNumber,
                      )
                    }
                  />
                  <div style={{ minWidth: "50px", textAlign: "center" }}>
                    {pageNumber} of {totalPage}
                  </div>
                  <SkipNextIcon
                    onClick={(event) =>
                      setPageNumber((pageNumber) =>
                        pageNumber < totalPage ? ++pageNumber : totalPage,
                      )
                    }
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default EQQuestionScore;
