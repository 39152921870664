import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import PropTypes from "prop-types";
import jsPDF from "jspdf";
import ReactDOM from "react-dom";
import html2canvas from "html2canvas";
import PrintIndivisualReport from "./IndividualReportFiles/PrintIndivisualReport";
import PrintIndivisualReport2 from "./IndividualReportFiles/PrintIndivisualReport2";
import PrintIndivisualReport3 from "./IndividualReportFiles/PrintIndivisualReport3";
import PrintIndivisualReport4 from "./IndividualReportFiles/PrintIndivisualReport4";
import SecondPage from "./Reportlastpages/SecondPage";
import FirstPage from "./Reportlastpages/FirstPage";
import UserReportSummaryIndivisual from "./Reportlastpages/UserReportSummaryIndivisual";

const ViewerDataDisplay = (props) => {
  const [currentTime, setCurrentTime] = useState(0); // Tracks the current video playback time
  const videoRef = useRef(null);

  const getColorForEmotion = (emotion) => {
    const colors = {
      Happy: "#FF914D",
      Sad: "#0000ff",
      Surprise: "#9D4EDD",
      Disgust: "#FC45BF",
      Angry: "#4FAAF5",
      Neutral: "#29BF12",
      Fear: "#800080",
    };
    return colors[emotion];
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentTime(Math.floor(videoRef.current.currentTime)); // Update current time in seconds
    }
  };
  const getFilteredData = () => {
    const emotions = Object.keys(props?.viewerdata?.SummaryData[0]);
    const filteredData = {};

    emotions.forEach((emotion) => {
      filteredData[emotion] = props?.viewerdata?.SummaryData[0][emotion].filter(
        (value, index) => index + 1 <= currentTime,
      );
    });

    return filteredData;
  };
  const getChartConfig = () => {
    const filteredData = getFilteredData();
    return {
      labels: Array.from({ length: currentTime }, (_, index) => index + 1), // Generate labels up to current time
      datasets: Object.keys(filteredData).map((emotion) => ({
        label: emotion,
        data: filteredData[emotion],
        borderColor: getColorForEmotion(emotion),
        borderWidth: 2,
        fill: false,
        tension: 0.4,
        pointRadius: 0,
        pointHoverRadius: 0,
      })),
    };
  };

  const lineChartData = getChartConfig();
  const chartOptions = {
    chart: {
      type: "line",
      height: 350,
    },
    // title: {
    //   text: "Multi-Line Chart Example",
    //   align: "center",
    // },
    xaxis: {
      categories: props?.viewerdata?.SummaryData[0]["Happy"].map(
        (item, index) => index + 1,
      ),
    },
    yaxis: {
      title: {
        text: "Values",
      },
    },
    stroke: {
      curve: "smooth", // Makes the lines smooth
      width: 2.5,
    },
    colors: Object.keys(props?.viewerdata?.SummaryData[0]).map((emotion) =>
      getColorForEmotion(emotion),
    ),
    legend: {
      position: "top",
    },
  };

  const chartdata = Object.keys(getFilteredData()).map((emotion) => ({
    name: emotion,
    data: getFilteredData()[emotion],
  }));

  const getFilteredGazeData = () => {
    return props?.viewerdata?.attentionData[0]
      ?.slice(0, currentTime)
      .map((item) => ({
        name: item?.emotion,
        data: item?.gaze_direction === "Center" ? 1 : 0,
      }));
  };

  // Prepare data for the "Gaze Direction" chart
  const series = [
    {
      name: "Attention",
      data: getFilteredGazeData().map((item) => item.data), // Filtered data
    },
  ];
  const options = {
    chart: {
      type: "line",
      zoom: {
        enabled: true,
      },
    },
    // title: {
    //   text: "Attention",
    //   align: "center",
    // },
    xaxis: {
      categories: Array.from({ length: currentTime }, (_, index) => index + 1), // Up to the current time
      // title: {
      //   text: "Emotion",
      // },
    },
    yaxis: {
      title: {
        text: "Attention (1=True, 0=False)",
      },
    },
    colors: ["#FF0000"],
    stroke: {
      curve: "smooth",
      width: 2.5,
    },
  };

  const options2 = {
    chart: {
      type: "line",
      zoom: {
        enabled: true,
      },
    },
    // title: {
    // text: `Face Data`,
    // align: "center",
    // style: {
    //   fontSize: "14px", // Adjust font size for responsiveness
    //   color: "#333",
    //   width: 20,
    // },
    // },
    xaxis: {
      categories: Array.from({ length: currentTime }, (_, index) => index + 1), // Up to the current time
      // title: {
      //   text: "Emotion",
      // },
    },
    yaxis: {
      title: {
        text: "No Of Face",
      },
    },
    colors: ["#FF0000"],
    stroke: {
      curve: "smooth",
      width: 2.5,
    },
  };
  const getFilteredFaceData = () => {
    return props?.viewerdata?.attentionData[0]
      ?.slice(0, currentTime)
      .map((item) => ({
        name: item?.emotion,
        data: item?.num_faces,
        // === 0 ? 0 : item?.num_faces === 1 ? 1 : -1,
      }));
  };

  // Prepare data for the "Gaze Direction" chart
  const faceData = [
    {
      name: "Face Data",
      data: getFilteredFaceData().map((item) => item.data), // Filtered data
    },
  ];

  const summaryData = props?.viewerdata?.SummaryData[0];

  // Initialize an array to store results
  const emotionExtremes = [];

  // Iterate over the emotions in summaryData
  for (const [emotion, values] of Object.entries(summaryData)) {
    if (Array.isArray(values)) {
      let max = Number.NEGATIVE_INFINITY;
      let min = Number.POSITIVE_INFINITY;
      const maxIndices = [];
      const minIndices = [];

      // Loop through the array to find max, min and their indices
      values.forEach((value, index) => {
        if (value !== null) {
          if (value > max) {
            max = value;
            maxIndices.length = 0; // Clear previous indices
            maxIndices.push(index + 1); // Add current index
          } else if (value === max) {
            maxIndices.push(index + 1); // Add current index
          }

          if (value < min) {
            min = value;
            minIndices.length = 0; // Clear previous indices
            minIndices.push(index + 1); // Add current index
          } else if (value === min) {
            minIndices.push(index + 1); // Add current index
          }
        }
      });

      // Add the result for the current emotion
      emotionExtremes.push({
        emotion,
        max,
        maxIndices,
        min,
        minIndices,
      });
    }
  }

  const Feeling96 = [
    { emotion: "Happy", x: 0.8, y: 0.7 },
    { emotion: "Joyful", x: 0.9, y: 0.8 },
    { emotion: "Excited", x: 0.7, y: 0.9 },
    { emotion: "Content", x: 0.6, y: 0.4 },
    { emotion: "Proud", x: 0.7, y: 0.6 },
    { emotion: "Grateful", x: 0.6, y: 0.5 },
    { emotion: "Surprise", x: 0.5, y: 0.7 },
    { emotion: "Love", x: 0.9, y: 0.6 },
    { emotion: "Amused", x: 0.7, y: 0.6 },
    { emotion: "Elated", x: 0.8, y: 0.8 },
    { emotion: "Optimistic", x: 0.7, y: 0.5 },
    { emotion: "Hopeful", x: 0.7, y: 0.4 },
    { emotion: "Cheerful", x: 0.7, y: 0.6 },
    { emotion: "Confident", x: 0.6, y: 0.5 },
    { emotion: "Affectionate", x: 0.8, y: 0.5 },
    { emotion: "Calm", x: 0.5, y: 0.2 },
    { emotion: "Peaceful", x: 0.6, y: 0.3 },
    { emotion: "Relaxed", x: 0.7, y: 0.3 },
    { emotion: "Satisfied", x: 0.6, y: 0.4 },
    { emotion: "Tender", x: 0.6, y: 0.3 },
    { emotion: "Contented", x: 0.7, y: 0.3 },
    { emotion: "Pleased", x: 0.6, y: 0.3 },
    { emotion: "Quiet", x: 0.5, y: 0.2 },
    { emotion: "Neutral", x: 0.5, y: 0.5 },
    { emotion: "Bored", x: -0.2, y: 0.3 },
    { emotion: "Anxious", x: -0.5, y: 0.7 },
    { emotion: "Sad", x: -0.7, y: 0.4 },
    { emotion: "Disappointed", x: -0.6, y: 0.5 },
    { emotion: "Irritated", x: -0.6, y: 0.6 },
    { emotion: "Lonely", x: -0.6, y: 0.5 },
    { emotion: "Tired", x: -0.2, y: 0.3 },
    { emotion: "Confused", x: -0.4, y: 0.5 },
    { emotion: "Nervous", x: -0.4, y: 0.6 },
    { emotion: "Frustrated", x: -0.6, y: 0.7 },
    { emotion: "Guilty", x: -0.7, y: 0.5 },
    { emotion: "Ashamed", x: -0.8, y: 0.5 },
    { emotion: "Hopeless", x: -0.8, y: 0.6 },
    { emotion: "Fear", x: -0.7, y: 0.8 },
    { emotion: "Horrified", x: -0.9, y: 0.9 },
    { emotion: "Disgust", x: -0.8, y: 0.7 },
    { emotion: "Embarrassed", x: -0.7, y: 0.6 },
    { emotion: "Angry", x: -0.8, y: 0.8 },
    { emotion: "Enraged", x: -0.9, y: 0.9 },
    { emotion: "Vengeful", x: -0.9, y: 0.8 },
    { emotion: "Miserable", x: -0.9, y: 0.7 },
    { emotion: "Resentful", x: -0.8, y: 0.7 },
    { emotion: "Depressed", x: -0.9, y: 0.5 },
    { emotion: "Terrified", x: -0.9, y: 0.9 },
    // { emotion: "Horrified", x: -0.9, y: 0.8 },
    { emotion: "HelpLess", x: -0.8, y: 0.5 },
    { emotion: "Worried", x: -0.6, y: 0.6 },
    { emotion: "Grief-stricken", x: -0.8, y: 0.5 },
    { emotion: "Shocked", x: -0.5, y: 0.7 },
    // { emotion: "Shocked", x: -0.6, y: 0.7 },
    { emotion: "Regretful", x: -0.6, y: 0.5 },
    { emotion: "Frightened", x: -0.7, y: 0.7 },
    { emotion: "Insecure", x: -0.5, y: 0.4 },
    //  {emotion: "Hopeless",x: -0.7,y: 0.6},
    // { emotion: "Depressed", x: -0.8, y: 0.5 },
    { emotion: "Uncertain", x: -0.4, y: 0.5 },
    // { emotion: "Jealous", x: -0.6, y: 0.6 },
    { emotion: "Uncomfortable", x: -0.5, y: 0.6 },
    { emotion: "Unhappy", x: -0.7, y: 0.6 },
    { emotion: "Agitated", x: -0.7, y: 0.8 },
    { emotion: "Displeased", x: -0.5, y: 0.5 },
    { emotion: "Vulnerable", x: -0.6, y: 0.5 },
    { emotion: "Pessimistic", x: -0.6, y: 0.4 },
    { emotion: "Horrible", x: -0.9, y: 0.8 },
    { emotion: "Bewildered", x: -0.6, y: 0.6 },
    // { emotion: "Angry", x: -0.8, y: 0.9 },
    // { emotion: "Disappointed", x: -0.6, y: 0.6 },
    { emotion: "Defeated", x: -0.7, y: 0.5 },
    { emotion: "Indifferent", x: -0.4, y: 0.4 },
    { emotion: "Hurt", x: -0.7, y: 0.5 },
    { emotion: "Afraid", x: -0.7, y: 0.6 },
    { emotion: "Apprehensive", x: -0.5, y: 0.5 },
    { emotion: "Grief", x: -0.8, y: 0.7 },
    { emotion: "Overwhelmed", x: -0.7, y: 0.8 },
    { emotion: "Tense", x: -0.5, y: 0.6 },
    { emotion: "Resentment", x: -0.7, y: 0.6 },
    // Lonely -0.6 0.5
    { emotion: "Rejected", x: -0.7, y: 0.6 },
    // Hopeless -0.9 0.7
    { emotion: "Shameful", x: -0.8, y: 0.6 },
    { emotion: "Disheartened", x: -0.7, y: 0.5 },
    { emotion: "Deflated", x: -0.6, y: 0.5 },
    { emotion: "Stress", x: -0.7, y: 0.8 },
    { emotion: "Worry", x: -0.6, y: 0.7 },
    { emotion: "Concern", x: -0.2, y: 0.5 },
    { emotion: "Anxiety", x: -0.8, y: 0.9 },
    { emotion: "Upset", x: -0.7, y: 0.7 },
    // { emotion: "Agitated", x: -0.6, y: 0.8 },
    { emotion: "Humiliated", x: -0.9, y: 0.7 },
    { emotion: "Overconfident", x: 0.5, y: 0.6 },
    // { emotion: "Depressed", x: -0.9, y: 0.3 },
    { emotion: "Bothered", x: -0.5, y: 0.6 },
    // { emotion: "Jealous", x: -0.7, y: 0.7 },
    { emotion: "Timid", x: -0.4, y: 0.4 },
    { emotion: "Chilled", x: 0.6, y: 0.2 },
    // { emotion: "Excited", x: 0.8, y: 0.9 },
  ];

  const getXValue = (emotionName) => {
    const found = Feeling96.find((feeling) => feeling.emotion === emotionName);
    return found ? found.x : null; // Return null if emotion is not found
  };
  const getYValue = (emotionName) => {
    const found = Feeling96.find((feeling) => feeling.emotion === emotionName);
    return found ? found.y : null; // Return null if emotion is not found
  };
  const calculateValenceArousal = (data) => {
    console.log(data);
    return props?.data.Tag === "Camera"
      ? data.map((entry, index) => {
          const { emotion_confidences } = entry;
          console.log(emotion_confidences);
          const valence =
            emotion_confidences &&
            Object.keys(emotion_confidences).reduce((sum, emotion) => {
              console.log(
                sum,
                emotion_confidences[emotion],
                emotion,
                Feeling96[emotion]?.x,
              );
              return (
                sum + emotion_confidences[emotion] * (getXValue(emotion) || 0)
              );
            }, 0);
          console.log(valence);
          const arousal =
            emotion_confidences &&
            Object.keys(emotion_confidences).reduce((sum, emotion) => {
              return (
                sum + emotion_confidences[emotion] * (getYValue(emotion) || 0)
              );
            }, 0);

          return {
            ...entry,
            valence: valence ? valence : 0,
            arousal: arousal ? arousal : 0,
          };
        })
      : data.slice(0, -1).map((entry, index) => {
          const { emotion_confidences } = entry;
          console.log(emotion_confidences);
          const valence =
            emotion_confidences &&
            Object.keys(emotion_confidences).reduce((sum, emotion) => {
              console.log(
                sum,
                emotion_confidences[emotion],
                emotion,
                Feeling96[emotion]?.x,
              );
              return (
                sum + emotion_confidences[emotion] * (getXValue(emotion) || 0)
              );
            }, 0);
          console.log(valence);
          const arousal =
            emotion_confidences &&
            Object.keys(emotion_confidences).reduce((sum, emotion) => {
              return (
                sum + emotion_confidences[emotion] * (getYValue(emotion) || 0)
              );
            }, 0);

          return {
            ...entry,
            valence: valence ? valence : 0,
            arousal: arousal ? arousal : 0,
          };
        });
  };
  const [closeEmotion, setCloseEmotion] = useState([]);

  useEffect(() => {
    const result =
      props?.viewerdata?.attentionData[0] &&
      calculateValenceArousal(props?.viewerdata?.attentionData[0]);
    const getEmotion = (V_computed, A_computed, oldEmotion, faceData) => {
      const distances = Feeling96.map(({ emotion, x, y }) => {
        const distance = Math.sqrt(
          Math.pow(V_computed - x, 2) + Math.pow(A_computed - y, 2),
        );
        return {
          emotion,
          distance,
          oldEmotion,
          faceData,
          V_computed,
          A_computed,
        };
      });

      console.log("Distances:", distances);

      // Example: Find the closest emotion
      const closestEmotion = distances.reduce((prev, curr) =>
        prev.distance < curr.distance ? prev : curr,
      );

      console.log("Closest Emotion:", closestEmotion);

      setCloseEmotion((prev) => [...prev, closestEmotion]);
      // return closestEmotion;
    };
    console.log(result);
    result?.map((item, index) =>
      // Emotion: {item?.emotion}, Valence: {item?.valence?.toFixed(3)},
      // Arousal: {item?.arousal?.toFixed(3)}
      // Close Emotion:{" "}
      getEmotion(
        item?.valence?.toFixed(3),
        item?.arousal?.toFixed(3),
        item?.emotion,
        item?.num_faces,
      ),
    );
  }, [props]);

  const quadrantCounts = {
    "High Power/Control": 0,
    Obstructive: 0,
    Conductive: 0,
    "Low Power/Control": 0,
  };
  closeEmotion?.length > 0 &&
    closeEmotion?.forEach(
      ({ V_computed: valence, A_computed: arousal, faceData }) => {
        if (faceData === 0) {
          return;
        }

        if (
          (parseFloat(valence) > 0 && parseFloat(arousal) > 0) ||
          parseFloat(valence) === 0.0 ||
          parseFloat(arousal) === 0.0
        )
          quadrantCounts["High Power/Control"]++;
        else if (parseFloat(valence) > 0 && parseFloat(arousal) < 0)
          quadrantCounts["Obstructive"]++;
        else if (parseFloat(valence) < 0 && parseFloat(arousal) > 0)
          quadrantCounts["Conductive"]++;
        else if (parseFloat(valence) < 0 && parseFloat(arousal) < 0)
          quadrantCounts["Low Power/Control"]++;
      },
    );

  // Prepare chart data
  const series3 = Object.values(quadrantCounts); // [++, +-, -+, --]
  const labels = Object.keys(quadrantCounts); // ["++", "+-", "-+", "--"]

  // Chart options
  const options3 = {
    chart: {
      type: "pie",
    },
    labels: labels,
    legend: {
      position: "bottom",
      labels: {
        colors: ["#000000", "#000000", "#000000", "#000000"], // Label color
        useSeriesColors: false,
        fontSize: "20px",
        fontWeight: "bold", // Make legend labels bold
      },
    },
    // colors: ["#FFA07A", "#87CEFA", "#A9DFBF", "#FFC107"],
    colors: ["#D1EFB8", "#A4D8D8", "#F7DFC2", "#EBCCFF"],
    // title: {
    //   text: "Emotion Quadrants Distribution",
    //   align: "center",
    // },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        const name = opts.w.globals.labels[opts.seriesIndex]; // Quadrant name
        const percentage = val.toFixed(1); // Percentage
        return `${percentage}%`;
      },
      style: {
        fontSize: "14px",
        justifyContent: "center",
        alignItems: "center",
        // fontWeight: "bold",
        colors: ["#000000"], // Adjust text color if needed
      },
    },

    tooltip: {
      enabled: true,
      style: {
        fontSize: "12px",
        fontWeight: "normal", // Tooltip font weight
        color: "#000000", // Tooltip text color
      },
      theme: "light", // Tooltip background theme (optional)
      y: {
        formatter: function (val) {
          return `${val}`; // Customize tooltip value format
        },
      },
    },
  };

  const rowsPerColumn = 10; // Number of rows per column
  const splitDataIntoChunks = (data, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < data.length; i += chunkSize) {
      chunks.push(data.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const formattedData = closeEmotion.map((emotion, index) => {
    return {
      emotion,
      formattedEmotion:
        emotion.faceData === 0
          ? `${index + 1}-No Face`
          : `${index + 1}-${emotion.emotion}`,
    };
  });
  // Split data into groups of 10
  const chunks = splitDataIntoChunks(formattedData, rowsPerColumn);

  // Split chunks into groups of 3 for new tables
  const tables = splitDataIntoChunks(chunks, 3);
  console.log(tables);

  const getColor = (data) => {
    if (data?.emotion?.faceData === 0) {
      return "#cccccc";
    }
    if (
      (parseFloat(data?.emotion?.V_computed) > 0 &&
        parseFloat(data?.emotion?.A_computed) > 0) ||
      parseFloat(data?.emotion?.V_computed) === 0.0 ||
      parseFloat(data?.emotion?.A_computed) === 0.0
    ) {
      return "#D1EFB8";
      //  "#FFA07A";
    } else if (
      parseFloat(data?.emotion?.V_computed) > 0 &&
      parseFloat(data?.emotion?.A_computed) < 0
    ) {
      return "#A4D8D8";
      // "#87CEFA";
    } else if (
      parseFloat(data?.emotion?.V_computed) < 0 &&
      parseFloat(data?.emotion?.A_computed) > 0
    ) {
      return "#F7DFC2";
      //  "#A9DFBF";
    } else if (
      parseFloat(data?.emotion?.V_computed) < 0 &&
      parseFloat(data?.emotion?.A_computed) < 0
    ) {
      return "#EBCCFF";
      // "#FFC107";
    } else {
      return "#F3F5F8";
    }
  };

  const processEmotionData = (data) => {
    const result = {
      "High Power/Control": {},
      Obstructive: {},
      Conductive: {},
      "Low Power/Control": {},
    };

    data.forEach((item) => {
      const v = parseFloat(item.V_computed);
      const a = parseFloat(item.A_computed);
      // const emotion = item.emotion || "Unknown";
      const emotion =
        item.faceData === 0 ? `No Face` : item.emotion || "Unknown";
      if (item.faceData === 0) {
        return;
      }
      if ((v > 0 && a > 0) || v === 0.0 || a === 0.0) {
        result["High Power/Control"][emotion] =
          (result["High Power/Control"][emotion] || 0) + 1;
      } else if (v > 0 && a < 0) {
        result["Obstructive"][emotion] =
          (result["Obstructive"][emotion] || 0) + 1;
      } else if (v < 0 && a > 0) {
        result["Conductive"][emotion] =
          (result["Conductive"][emotion] || 0) + 1;
      } else if (v < 0 && a < 0) {
        result["Low Power/Control"][emotion] =
          (result["Low Power/Control"][emotion] || 0) + 1;
      }
    });

    return result;
  };

  const processData = processEmotionData(closeEmotion);
  console.log(processData);
  const generateTableData = (quadrantData, row) => {
    console.log(quadrantData);
    const emotions = Object.entries(quadrantData); // Convert object to array: [["Concern", 16], ["Neutral", 4], ...]
    const columns = [];
    let columnData = [];

    emotions.forEach(([emotion, count], index) => {
      columnData.push({ emotion, count });
      if ((index + 1) % row === 0 || index === emotions.length - 1) {
        columns.push(columnData);
        columnData = [];
      }
    });

    return columns;
  };

  const [pdfDownload, setPdfDownload] = useState(false);
  const handlePrintSingle = async (data, index) => {
    setPdfDownload(true);
    const pdf = new jsPDF("p", "mm", "a4"); // Create a new PDF document

    const imgWidth = 210; // Width of A4 page in mm
    const pageHeight = 297; // Height of A4 page in mm
    const tableElement4 = document.createElement("div");
    document.body.appendChild(tableElement4);

    // Render the table into its own div
    ReactDOM.render(
      <UserReportSummaryIndivisual
        user={props?.user}
        setIsDisplayViewersData={props?.setIsDisplayViewersData}
        data={props?.data}
        viewerdata={props?.viewerdata}
      />,
      tableElement4,
    );

    // Wait for the table to render
    await new Promise((resolve) => setTimeout(resolve, 3000));

    // Capture the table content into a canvas
    const tableCanvas4 = await html2canvas(tableElement4, { scale: 2 });

    // Get table image data
    const tableImgData4 = tableCanvas4.toDataURL("image/jpeg");

    // Add the table to the next page
    pdf.addImage(
      tableImgData4,
      "JPEG",
      0,
      0,
      imgWidth,
      (tableCanvas4.height * imgWidth) / tableCanvas4.width,
    );

    pdf.addPage();

    const element = document.createElement("div");
    document.body.appendChild(element);

    // Render the component into the newly created element
    ReactDOM.render(
      <PrintIndivisualReport
        user={props?.user}
        setIsDisplayViewersData={props?.setIsDisplayViewersData}
        data={props?.data}
        viewerdata={props?.viewerdata}
      />,
      element,
    );
    await new Promise((resolve) => setTimeout(resolve, 3000));
    // Use html2canvas to capture the rendered content
    const canvas = await html2canvas(element, {
      scale: 2, // Higher scale for better quality
    });

    const imgData = canvas.toDataURL("image/jpeg");

    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    let position = 0;

    // Split the content into multiple pages if necessary
    // while (position < imgHeight) {
    pdf.addImage(
      imgData,
      "JPEG",
      0,
      0,
      // -position,
      imgWidth,
      (canvas.height * imgWidth) / canvas.width,
    );

    // position += pageHeight;

    // Add a new page if there is more content to render
    // if (position < imgHeight) {
    //   pdf.addPage();
    // }
    // }

    pdf.addPage();

    // Now render the table into a separate virtual div
    const tableElement = document.createElement("div");
    document.body.appendChild(tableElement);

    // Render the table into its own div
    ReactDOM.render(
      <PrintIndivisualReport2
        user={props?.user}
        setIsDisplayViewersData={props?.setIsDisplayViewersData}
        data={props?.data}
        viewerdata={props?.viewerdata}
      />,
      tableElement,
    );

    // Wait for the table to render
    await new Promise((resolve) => setTimeout(resolve, 3000));

    // Capture the table content into a canvas
    const tableCanvas = await html2canvas(tableElement, { scale: 2 });

    // Get table image data
    const tableImgData = tableCanvas.toDataURL("image/jpeg");

    // Add the table to the next page
    pdf.addImage(
      tableImgData,
      "JPEG",
      0,
      0,
      imgWidth,
      (tableCanvas.height * imgWidth) / tableCanvas.width,
    );

    pdf.addPage();

    // Now render the table into a separate virtual div
    const tableElement2 = document.createElement("div");
    document.body.appendChild(tableElement2);

    // Render the table into its own div
    ReactDOM.render(
      <PrintIndivisualReport3
        user={props?.user}
        setIsDisplayViewersData={props?.setIsDisplayViewersData}
        data={props?.data}
        viewerdata={props?.viewerdata}
      />,
      tableElement2,
    );

    // Wait for the table to render
    await new Promise((resolve) => setTimeout(resolve, 3000));

    // Capture the table content into a canvas
    const tableCanvas2 = await html2canvas(tableElement2, { scale: 2 });

    // Get table image data
    const tableImgData2 = tableCanvas2.toDataURL("image/jpeg");
    let position2 = 0;
    const imgHeight2 = (tableCanvas2.height * imgWidth) / tableCanvas2.width;
    // Split the content into multiple pages if necessary
    while (position2 < imgHeight2) {
      pdf.addImage(
        tableImgData2,
        "JPEG",
        0,
        // 0,
        -position2,
        imgWidth,
        (tableCanvas2.height * imgWidth) / tableCanvas2.width,
      );

      position2 += pageHeight;

      // Add a new page if there is more content to render
      if (position2 < imgHeight2) {
        pdf.addPage();
      }
    }
    // Add the table to the next page
    // pdf.addImage(
    //   tableImgData2,
    //   "JPEG",
    //   0,
    //   0,
    //   imgWidth,
    //   (tableCanvas2.height * imgWidth) / tableCanvas2.width,
    // );

    pdf.addPage();

    // Now render the table into a separate virtual div
    const tableElement3 = document.createElement("div");
    document.body.appendChild(tableElement3);

    // Render the table into its own div
    ReactDOM.render(
      <PrintIndivisualReport4
        user={props?.user}
        setIsDisplayViewersData={props?.setIsDisplayViewersData}
        data={props?.data}
        viewerdata={props?.viewerdata}
      />,
      tableElement3,
    );

    // Wait for the table to render
    await new Promise((resolve) => setTimeout(resolve, 3000));

    // Capture the table content into a canvas
    const tableCanvas3 = await html2canvas(tableElement3, { scale: 2 });

    // Get table image data
    const tableImgData3 = tableCanvas3.toDataURL("image/jpeg");

    let position3 = 0;
    const imgHeight3 = (tableCanvas3.height * imgWidth) / tableCanvas3.width;
    // Split the content into multiple pages if necessary
    while (position3 < imgHeight3) {
      pdf.addImage(
        tableImgData3,
        "JPEG",
        0,
        // 0,
        -position3,
        imgWidth,
        (tableCanvas3.height * imgWidth) / tableCanvas3.width,
      );

      position3 += pageHeight;

      // Add a new page if there is more content to render
      if (position3 < imgHeight3) {
        pdf.addPage();
      }
    }

    pdf.addPage();

    // Now render the table into a separate virtual div
    const tableElement5 = document.createElement("div");
    document.body.appendChild(tableElement5);

    // Render the table into its own div
    ReactDOM.render(<FirstPage />, tableElement5);

    // Capture the table content into a canvas
    const tableCanvas5 = await html2canvas(tableElement5, { scale: 2 });

    // Get table image data
    const tableImgData5 = tableCanvas5.toDataURL("image/jpeg");

    let position5 = 0;
    const imgHeight5 = (tableCanvas5.height * imgWidth) / tableCanvas5.width;
    // Split the content into multiple pages if necessary
    while (position5 < imgHeight5) {
      pdf.addImage(
        tableImgData5,
        "JPEG",
        0,
        // 0,
        -position5,
        imgWidth,
        (tableCanvas5.height * imgWidth) / tableCanvas5.width,
      );

      position5 += pageHeight;

      // Add a new page if there is more content to render
      if (position5 < imgHeight5) {
        pdf.addPage();
      }
    }

    pdf.addPage();

    // Now render the table into a separate virtual div
    const tableElement6 = document.createElement("div");
    document.body.appendChild(tableElement6);

    // Render the table into its own div
    ReactDOM.render(<SecondPage />, tableElement6);

    // Capture the table content into a canvas
    const tableCanvas6 = await html2canvas(tableElement6, { scale: 2 });

    // Get table image data
    const tableImgData6 = tableCanvas6.toDataURL("image/jpeg");

    let position6 = 0;
    const imgHeight6 = (tableCanvas5.height * imgWidth) / tableCanvas6.width;
    // Split the content into multiple pages if necessary
    while (position6 < imgHeight6) {
      pdf.addImage(
        tableImgData6,
        "JPEG",
        0,
        // 0,
        -position6,
        imgWidth,
        (tableCanvas6.height * imgWidth) / tableCanvas6.width,
      );

      position6 += pageHeight;

      // Add a new page if there is more content to render
      if (position6 < imgHeight6) {
        pdf.addPage();
      }
    }

    // pdf.addPage();

    // // Now render the table into a separate virtual div
    // const tableElement7 = document.createElement("div");
    // document.body.appendChild(tableElement7);

    // // Render the table into its own div
    // ReactDOM.render(<ThirdPage />, tableElement7);

    // // Capture the table content into a canvas
    // const tableCanvas7 = await html2canvas(tableElement7, { scale: 2 });

    // // Get table image data
    // const tableImgData7 = tableCanvas7.toDataURL("image/jpeg");

    // let position7 = 0;
    // const imgHeight7 = (tableCanvas7.height * imgWidth) / tableCanvas7.width;
    // // Split the content into multiple pages if necessary
    // while (position7 < imgHeight7) {
    //   pdf.addImage(
    //     tableImgData7,
    //     "JPEG",
    //     0,
    //     // 0,
    //     -position7,
    //     imgWidth,
    //     (tableCanvas7.height * imgWidth) / tableCanvas7.width,
    //   );

    //   position7 += pageHeight;

    //   // Add a new page if there is more content to render
    //   if (position7 < imgHeight7) {
    //     pdf.addPage();
    //   }
    // }

    document.body.removeChild(element);
    document.body.removeChild(tableElement);
    document.body.removeChild(tableElement2);
    document.body.removeChild(tableElement3);
    document.body.removeChild(tableElement4);
    document.body.removeChild(tableElement5);
    document.body.removeChild(tableElement6);
    // document.body.removeChild(tableElement7);
    // Save the PDF
    pdf.save(`${props?.user?.CompanyName}.pdf`);
    setPdfDownload(false);
  };

  const getLabel = (quadrant) => {
    if (quadrant === "High Power/Control") {
      return "(dominant but resistant individuals)";
    } else if (quadrant === "Obstructive") {
      return "(leaders,proactive decision-makers)";
    } else if (quadrant === "Conductive") {
      return "(collaborative, adaptable users)";
    } else if (quadrant === "Low Power/Control") {
      return "(hesitant, disengaged individuals)";
    }
  };

  return (
    <div className="flex flex-col justify-center items-center p-5">
      <button
        style={{
          display: "flex",
          justifyContent: "center",
          padding: 10,
          color: "white",
          backgroundColor: pdfDownload ? "gray" : "#04226d",
          borderRadius: 14,
          margin: 10,
        }}
        // className={`flex flex-col justify-center rounded-xl items-center p-5 text-white ${pdfDownload ? "bg-gray-300" : "bg-primary"}`}
        onClick={() => {
          if (pdfDownload) {
            return;
          }
          handlePrintSingle();
        }}
      >
        {pdfDownload ? "Downloading..." : "Download Report"}
      </button>
      <video
        src={props?.data?.videoLink}
        controls
        ref={videoRef}
        onTimeUpdate={handleTimeUpdate}
        className="w-1/2 h-96"
      ></video>
      <div
        className="w-3/4  overflow-y-auto mt-10"
        style={{ height: "500px" }}
        id="certificate"
      >
        <div className="w-full flex flex-col ">
          {/* <Line data={lineChartData} style={{ height: "400px" }} /> */}
          <h3 className="text-sm md:text-2xl font-bold text-center">
            Per Second Emotion Tracking{" "}
            <span className="text-sm md:text-2xl ">(Behavior Analysis)</span>
          </h3>
          {/* <Line data={lineChartData} style={{ height: "400px" }} /> */}
          <ReactApexChart
            options={chartOptions}
            series={chartdata}
            type="line"
            height={350}
          />
        </div>

        <div className="w-full flex flex-col  mt-10 ">
          <h3 className="text-sm md:text-2xl font-bold text-center">
            Attention and Distraction tracking
          </h3>
          {/* <Line data={lineChartData} style={{ height: "400px" }} /> */}
          <ReactApexChart
            options={options}
            series={series}
            type="line"
            height={350}
          />
        </div>

        <div className="w-full flex flex-col  mt-10 ">
          <h3 className="text-sm md:text-2xl font-bold text-center">
            Multiple Faces Identification
          </h3>
          {/* <Line data={lineChartData} style={{ height: "400px" }} /> */}
          <ReactApexChart
            options={options2}
            series={faceData}
            type="line"
            height={350}
          />
        </div>
        <div className="mt-16 font-bold flex-col justify-center text-center flex items-center text-black text-sm md:text-[20px] ">
          {/* Max/Min Values of emotions */}
          Traking Max/Min value of emotions<br></br>
          <span className="text-sm md:text-[20px]  mt-2">
            for focused engagements
          </span>
        </div>
        {/* <XLSXDownload data={emotionExtremes} name="emotionExtremes" /> */}
        <div className="mt-10 flex justify-center items-center ">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table sx={{ border: "none", tableLayout: "fixed" }}>
                <TableHead sx={{ backgroundColor: "#04226d" }}>
                  <TableRow>
                    <TableCell>
                      <h1 className="font-bold text-white text-center text-sm ">
                        Emotion
                      </h1>
                    </TableCell>
                    <TableCell>
                      <h1 className="font-bold text-white text-center text-sm ">
                        Max Value
                      </h1>
                    </TableCell>
                    <TableCell>
                      <h1 className="font-bold text-white text-center text-sm ">
                        Peaks of Max Value
                      </h1>
                    </TableCell>
                    <TableCell>
                      <h1 className="font-bold text-white text-center text-sm ">
                        Min Value
                      </h1>
                    </TableCell>

                    <TableCell>
                      <h1 className="font-bold text-white text-center text-sm ">
                        Peaks of Min Value
                      </h1>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ backgroundColor: "#F3F5F8" }}>
                  {emotionExtremes?.length > 0 &&
                    emotionExtremes?.map((item, index) => (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={item.emotion}
                        // onClick={() => handleRowClick(row._id)}
                      >
                        <TableCell sx={{ border: "none", textAlign: "center" }}>
                          {item.emotion}
                        </TableCell>
                        <TableCell sx={{ border: "none", textAlign: "center" }}>
                          {item.max}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "none",
                            textAlign: "center",
                            width: "150px", // Set a fixed width
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                          }}
                        >
                          {item.maxIndices.join(",")}
                        </TableCell>
                        <TableCell sx={{ border: "none", textAlign: "center" }}>
                          {item.min}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "none",
                            textAlign: "center",
                            width: "100px", // Set a fixed width
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                          }}
                        >
                          {item.minIndices.join(",")}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
        <div className="justify-center flex-col gap-3 flex items-center mt-16 text-xl font-bold">
          <div className="text-sm gap-2 flex flex-col md:text-[20px]  font-bold text-center">
            <h2 className=" text-sm md:text-[20px]  ">4 Emotion Quadrants</h2>{" "}
            <h2 className=" text-sm md:text-[20px]  ">
              (for optimisation and Decision making)
            </h2>
          </div>
          <ReactApexChart
            options={options3}
            series={series3}
            type="pie"
            height={350}
            // width={750}
          />
        </div>
        <div className="mt-16 font-bold justify-center flex items-center text-black text-center text-xm md:text-xl ">
          {/* Sentiments per second */} Tracking indivisual sentiments<br></br>
          (for emotional shifts)
        </div>
        <div className="mt-10 p-5">
          {tables?.length > 0 &&
            tables.map((tableChunks, tableIndex) => {
              const maxRows = Math.max(...tableChunks.map((col) => col.length));
              return (
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer
                    component={Paper}
                    key={tableIndex}
                    // sx={{ width: "100%", overflow: "hidden" }}
                    // sx={{ maxHeight: 440 }}
                    style={{ marginBottom: "20px" }}
                  >
                    <Table sx={{ border: "none" }}>
                      <TableHead sx={{ backgroundColor: "#04226d" }}>
                        <TableRow>
                          {tableChunks.map((_, colIndex) => (
                            <TableCell key={colIndex} align="center">
                              <h1 className="font-bold text-white text-center text-sm ">
                                {/* Column {colIndex + 1}(secs-sentiment) */}
                                Sentiments per second
                              </h1>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody sx={{ backgroundColor: "#F3F5F8" }}>
                        {Array.from({ length: maxRows }).map((_, rowIndex) => (
                          <TableRow key={rowIndex}>
                            {tableChunks.map((chunk, colIndex) => (
                              <TableCell
                                key={colIndex}
                                align="center "
                                sx={{
                                  backgroundColor: getColor(chunk[rowIndex]),
                                }}
                              >
                                <h1 className=" text-black text-center text-sm ">
                                  {chunk[rowIndex]?.formattedEmotion || ""}
                                </h1>
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              );
            })}
        </div>

        <div className="mt-10">
          {Object.entries(processData).map(([quadrant, quadrantData]) => {
            // <EmotionTable key={quadrant} title={`Quadrant: ${quadrant}`} data={quadrantData} />
            const totalEmotions = Object.values(quadrantData).reduce(
              (sum, count) => sum + count,
              0,
            ); // Calculate total count
            // Helper to split table data into chunks
            const splitIntoChunks = (array, chunkSize) =>
              array.reduce((result, item, index) => {
                const chunkIndex = Math.floor(index / chunkSize);
                if (!result[chunkIndex]) {
                  result[chunkIndex] = []; // Start a new chunk
                }
                result[chunkIndex].push(item);
                return result;
              }, []);
            console.log(generateTableData(quadrantData, 5));
            // Generate table data for each chunk
            const tableChunks = splitIntoChunks(
              generateTableData(quadrantData, 5),
              3, // Max 3 columns per table
            );
            // let tableData = generateTableData(quadrantData, 5);
            // let maxRows = Math.max(...tableData.map((col) => col.length));
            // if (maxRows < 10) {
            //   maxRows = 5;
            //   tableData = generateTableData(quadrantData, 5);
            // }
            const isHighest =
              totalEmotions ===
              Math.max(
                ...Object.values(processData).map((qData) =>
                  Object.values(qData).reduce((sum, count) => sum + count, 0),
                ),
              );
            return (
              <Paper
                key={quadrant}
                sx={{ width: "100%", overflow: "hidden" }}
                className="border-2 border-black m-2"
              >
                {/* <TableContainer component={Paper} sx={{ marginBottom: 4 }}> */}
                <Typography align="center" sx={{ padding: 2 }}>
                  <h1 className="font-bold text-sm md:text-2xl">
                    {`${quadrant} (${totalEmotions})`}{" "}
                    <span className="text-sm md:text-xl">
                      {getLabel(quadrant)}
                    </span>
                  </h1>
                </Typography>
                {tableChunks.map((tableData, tableIndex) => {
                  const maxRows = Math.max(
                    ...tableData.map((col) => col.length),
                  );
                  return (
                    <TableContainer
                      component={Paper}
                      sx={{ marginBottom: 4 }}
                      key={`${quadrant}-${tableIndex}`}
                    >
                      <Table sx={{ border: "none" }}>
                        <TableHead sx={{ backgroundColor: "#04226d" }}>
                          <TableRow>
                            {tableData.map((_, index) => (
                              <TableCell key={index} align="center">
                                <h1 className="font-bold text-white text-center text-sm ">
                                  {/* Column {index + 1} */}
                                  (sentiment-count)
                                </h1>
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody
                          sx={{
                            backgroundColor: isHighest ? "#A4D8D8" : "#F3F5F8",
                          }}
                        >
                          {Array.from({ length: maxRows }).map(
                            (_, rowIndex) => (
                              <TableRow key={rowIndex}>
                                {tableData.map((column, colIndex) => (
                                  <TableCell key={colIndex} align="center">
                                    {column[rowIndex]
                                      ? `${column[rowIndex].emotion} (${column[rowIndex].count})`
                                      : ""}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ),
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  );
                })}
              </Paper>
            );
          })}
        </div>
      </div>
      <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
        <Button
          variant="contained"
          onClick={() => props.setIsDisplayViewersData(false)}
        >
          Cancel
        </Button>
      </div>
      {/* <UserReportSummaryIndivisual
        user={props?.user}
        setIsDisplayViewersData={props?.setIsDisplayViewersData}
        data={props?.data}
        viewerdata={props?.viewerdata}
      />
      <PrintIndivisualReport
        user={props?.user}
        setIsDisplayViewersData={props?.setIsDisplayViewersData}
        data={props?.data}
        viewerdata={props?.viewerdata}
      />
      <PrintIndivisualReport2
        user={props?.user}
        setIsDisplayViewersData={props?.setIsDisplayViewersData}
        data={props?.data}
        viewerdata={props?.viewerdata}
      />{" "}
      <PrintIndivisualReport3
        user={props?.user}
        setIsDisplayViewersData={props?.setIsDisplayViewersData}
        data={props?.data}
        viewerdata={props?.viewerdata}
      />{" "}
      <PrintIndivisualReport4
        user={props?.user}
        setIsDisplayViewersData={props?.setIsDisplayViewersData}
        data={props?.data}
        viewerdata={props?.viewerdata}
      />
      <FirstPage />
      <SecondPage /> */}
    </div>
  );
};

export default ViewerDataDisplay;
