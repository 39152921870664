import { Button, FormControl, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { toast } from "react-toastify";
import { GetData } from "../../../api/GeneralFile";

const ViewEQScoreChart = (props) => {
  const [totalData, setTotalData] = useState("");
  const [totalGroupData, setTotalGroupData] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [chartType, setChartType] = useState("bar");
  const [loading, setLoading] = useState(false);

  const validateDateRange = (start, end) => {
    if (!start || !end) return true; // No dates to compare
    const startDate = new Date(start);
    const endDate = new Date(end);
    const diffTime = Math.abs(endDate - startDate);
    const diffMonths = diffTime / (1000 * 60 * 60 * 24 * 30); // Difference in months
    return diffMonths <= 2;
  };

  const getAllIndiVisualData = () => {
    // if (!validateDateRange(startDate, endDate)) {
    //   toast.error(
    //     "The gap between start date and end date should not exceed two months.",
    //   );
    //   return;
    // }
    setLoading(true);
    GetData(
      `/downloads/indivisual/data?category=${props.category}&userid=${props?.user?.participant?._id}&startDate=${startDate}&endDate=${endDate}`,
    )
      .then((response) => {
        console.log(response);
        setTotalData(response.data?.structuredQuizzes);
        // setTotalPage(response.data.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err, "error");
        toast(err.response.data?.message);
        setLoading(false);
      });
  };

  //   useEffect(() => {
  //     getAllIndiVisualData();
  //   }, [props]);

  const getAllIndiVisualGroupData = () => {
    // if (!validateDateRange(startDate, endDate)) {
    //   toast.error(
    //     "The gap between start date and end date should not exceed two months.",
    //   );
    //   return;
    // }
    setLoading(true);
    GetData(
      `/content-management/eqquestions/indivisual/category/data?category=${props.category}&userid=${props?.user?.participant?._id}&startDate=${startDate}&endDate=${endDate}`,
    )
      .then((response) => {
        console.log(response);
        setTotalGroupData(response.data?.structuredQuizzes);
        // setTotalPage(response.data.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err, "error");
        toast(err.response.data?.message);
        setLoading(false);
      });
  };

  const formatDate = (dateStr) => {
    const options = { month: "short", day: "numeric" };
    return new Date(dateStr).toLocaleDateString("en-US", options);
  };

  // Extract dates and emotion counts
  const dates = totalData && Object.keys(totalData).map(formatDate);
  console.log(totalData, typeof totalData);

  const dates2 = totalGroupData && Object.keys(totalGroupData).map(formatDate);

  const series =
    totalData !== ""
      ? [
          {
            name: "Average EQ Score",
            data: Object.values(totalData).map((day) => day.averagePoints),
          },
        ]
      : [
          {
            name: "Average EQ Score",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
        ];
  //   emotions.map((emotion) => ({
  //     // name: emotion,
  //     // data: Object.values(totalData).map((day) => day[emotion]),
  //     // data: dates.map((date) => combinedDailyCounts[date][emotion]),
  //   }));

  const options = {
    chart: {
      type: "bar",
      height: 350,
      //   stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        // borderRadius: 10,
      },
    },
    xaxis: {
      categories: dates,
      title: {
        text: "Date",
        style: {
          color: "#04266D",
          fontSize: "20px",
        },
      },
      axisBorder: {
        show: true,
        color: "#04266D",
      },
      axisTicks: {
        show: true,
        color: "#04266D",
      },
      labels: {
        style: {
          colors: "#04266D",
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      title: {
        text: "Average EQ Score",
        style: {
          color: "#04266D",
          fontSize: "20px",
        },
      },
      min: 0,
      labels: {
        style: {
          colors: "#04266D",
        },
      },
      axisBorder: {
        show: true,
        color: "#04266D",
      },
      axisTicks: {
        show: true,
        color: "#04266D",
      },
    },
    grid: {
      show: false,
    },
    colors: ["#04226d"],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
    },
    tooltip: {
      enabled: true,
    },
  };

  console.log(series);

  const series3 = [
    {
      name: "Category A",
      data: [40, 60, 80],
    },
    {
      name: "Category B",
      data: [30, 40, 50],
    },
    {
      name: "Category C",
      data: [20, 30, 40],
    },
    {
      name: "Category D",
      data: [20, 30, 40],
    },
  ];
  console.log(totalGroupData);

  const categoryData = {};

  // Loop through each date in the totalGroupData
  Object.values(totalGroupData).forEach((dayData) => {
    // Loop through combinedGroups for each day
    dayData.combinedGroups.forEach((group) => {
      // If the category doesn't exist in categoryData, initialize it
      if (!categoryData[group.tag]) {
        categoryData[group.tag] = [];
      }
      // Push the averageScore for that category
      categoryData[group.tag].push(group.averageScore);
    });
  });

  // Now format the data into the structure needed for the chart
  const series2 = Object.keys(categoryData).map((tag) => ({
    name: tag, // The category (tag)
    data: categoryData[tag], // The array of averageScores for that category
  }));

  const options2 = {
    chart: {
      type: "bar",
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%", // Controls the width of individual bars
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: dates2, // Labels for each group
      title: {
        text: "Dates",
      },
    },
    yaxis: {
      title: {
        text: "Average EQ Score",
      },
    },
    legend: {
      position: "top",
    },
    fill: {
      opacity: 1,
    },
    colors: [
      "#8FBC8F", // Dark Sea Green
      "#4682B4", // Steel Blue
      "#FFB6C1", // Light Pink
      "#FFD700", // Gold
      "#DDA0DD",
    ], // Custom bar colors
    tooltip: {
      shared: true,
      intersect: false,
    },
  };
  console.log(series2, series3);
  return (
    <>
      {loading ? "Loading......." : "cfvgbhn"}
      <div>
        <FormControl>
          <TextField
            type="date"
            value={startDate}
            onChange={(event) => {
              setStartDate(event.target.value);
            }}
          />
        </FormControl>

        <FormControl>
          <TextField
            type="date"
            value={endDate}
            onChange={(event) => {
              setEndDate(event.target.value);
            }}
          />
        </FormControl>
        <Button
          variant="contained"
          onClick={() => {
            getAllIndiVisualData();
            getAllIndiVisualGroupData();
          }}
          style={{ margin: 10 }}
        >
          Filter
        </Button>
      </div>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />

      <div>
        <ReactApexChart
          options={options2}
          series={series2}
          type="bar"
          height={350}
        />
      </div>
      <div>
        <Button
          variant="contained"
          sx={{ marginTop: "15px" }}
          onClick={() => props.setDisplayViewChart(false)}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default ViewEQScoreChart;
